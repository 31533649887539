import React from 'react';

const PlannerGraphic = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 917 813"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <path
        d="M357.389 658.221C356.695 655.787 356 653.005 356 650.224C356.695 645.356 360.168 641.531 363.988 638.75C367.808 635.968 371.975 633.534 375.101 630.057C383.783 620.321 381.352 605.023 385.866 592.853C387.95 601.198 389.687 609.543 389.687 617.887C389.687 626.232 387.95 634.925 383.088 642.227C376.837 651.614 369.197 658.568 364.682 668.999C360.168 667.261 358.778 662.393 357.389 658.221Z"
        fill="#113555"
      />
      <path
        d="M328.565 667.609C321.62 663.784 313.632 663.436 305.992 664.827C298.352 666.218 291.059 669.347 284.113 672.477C291.406 672.824 298.699 673.52 304.95 676.997C310.507 680.126 314.327 684.994 319.189 688.819C325.44 693.339 350.791 700.64 355.653 690.209C358.779 683.951 334.122 670.39 328.565 667.609Z"
        fill="#113555"
      />
      <path
        d="M376.143 664.479C379.268 656.83 385.867 650.919 391.771 645.355C390.034 657.873 389.687 671.085 390.729 683.603C391.076 688.47 391.771 693.338 390.381 697.858C385.867 713.505 378.227 701.335 375.796 692.99C373.365 683.603 372.323 673.519 376.143 664.479Z"
        fill="#113555"
      />
      <path
        d="M354.264 703.074C350.097 703.422 346.277 704.813 342.109 706.551C333.427 710.028 324.745 713.505 316.411 717.33C327.176 718.025 337.942 717.33 348.361 714.896C350.792 714.201 353.57 713.853 356.001 714.201C360.863 714.896 364.336 719.068 367.808 722.545C375.449 729.152 394.896 728.109 385.172 715.939C379.616 708.637 362.946 702.379 354.264 703.074Z"
        fill="#113555"
      />
      <path
        d="M315.021 643.27C310.507 638.75 306.339 633.882 304.256 627.971C301.825 622.06 301.477 615.454 303.908 609.891C304.95 615.454 305.992 621.365 310.159 625.19C312.938 627.971 316.758 629.362 320.578 631.101C330.302 635.968 341.068 646.399 342.457 657.874C332.733 658.221 321.62 649.529 315.021 643.27Z"
        fill="#113555"
      />
      <path
        d="M337.595 600.503C338.636 594.94 341.415 590.072 344.193 584.857C345.929 581.727 348.013 578.25 351.486 576.86C350.791 584.509 350.097 592.158 349.055 600.156C348.708 605.023 348.013 609.891 348.708 615.107C349.402 621.018 350.097 639.098 340.026 628.319C335.511 622.756 336.553 606.762 337.595 600.503Z"
        fill="#113555"
      />
      <path
        d="M311.896 594.94C310.506 587.986 310.506 580.684 307.381 574.078C302.866 563.994 292.1 558.083 281.682 554.259C278.557 552.868 274.389 551.477 273 548C281.335 549.391 290.017 550.782 298.004 553.911C305.992 557.04 313.632 561.908 318.147 569.21C324.05 578.25 326.829 593.549 324.745 604.328C321.619 619.974 313.632 603.632 311.896 594.94Z"
        fill="#113555"
      />
      <path
        d="M315.368 583.118C317.452 589.724 319.189 596.331 320.578 602.937C321.619 607.805 322.661 613.02 324.745 617.54C327.523 624.147 332.038 629.71 336.553 635.621C345.929 648.486 354.264 662.394 360.168 677.345C362.599 682.56 364.335 688.124 367.808 692.991C374.754 703.77 386.214 710.376 393.507 720.807C399.411 729.5 402.536 739.931 403.231 750.362"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M342.804 600.156C338.637 613.021 342.457 627.276 340.026 640.837"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M376.143 638.75C374.059 640.837 371.281 641.88 369.544 643.966C368.502 645.357 368.155 646.748 367.461 648.138C366.071 652.311 364.335 656.831 364.335 661.351C364.335 664.48 364.682 667.957 364.682 671.087C364.682 674.216 363.988 678.041 361.904 680.127"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M383.089 709.681C384.13 704.118 384.13 698.207 383.089 692.296C381.699 685.342 378.921 678.388 379.269 671.434C379.269 669.347 379.963 666.914 381.352 665.175"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.147 674.91C325.092 681.169 334.122 683.95 343.151 686.036C346.277 686.732 349.055 687.427 352.18 687.427C354.264 687.427 356 686.732 358.084 687.079C360.515 687.079 362.946 688.123 364.682 689.513"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M363.64 710.376C369.197 711.072 374.753 712.81 379.268 716.287C381.699 718.026 384.13 720.46 386.908 721.85C387.95 722.546 388.992 722.893 390.034 722.893C391.076 722.893 392.465 722.546 392.812 721.503"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M317.452 637.359C324.05 641.879 330.649 647.095 336.552 652.31C338.289 654.049 340.025 655.788 342.109 656.831C344.193 657.874 346.971 658.221 349.055 656.831"
        stroke="#55A8E1"
        strokeWidth="1.37613"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M824.955 232.117C825.712 229.465 826.469 226.434 826.469 223.402C825.712 218.097 821.927 213.929 817.764 210.898C813.601 207.867 809.059 205.214 805.653 201.425C796.192 190.815 798.841 174.143 793.921 160.88C791.65 169.974 789.758 179.069 789.758 188.163C789.758 197.257 791.65 206.73 796.949 214.687C803.761 224.918 812.087 232.496 817.007 243.864C821.927 241.969 823.441 236.665 824.955 232.117Z"
        fill="#113555"
      />
      <path
        d="M804.518 210.899C806.788 213.172 809.816 214.309 811.708 216.583C812.844 218.098 813.222 219.614 813.979 221.13C815.493 225.677 817.385 230.603 817.385 235.529C817.385 238.939 817.007 242.728 817.007 246.139C817.007 249.549 817.764 253.717 820.035 255.991"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M856.367 242.349C863.937 238.18 872.641 237.802 880.968 239.317C889.294 240.833 897.242 244.243 904.811 247.653C896.863 248.032 888.915 248.79 882.103 252.579C876.047 255.99 871.884 261.295 866.586 265.463C859.774 270.389 832.146 278.346 826.847 266.978C823.441 260.158 850.312 245.38 856.367 242.349Z"
        fill="#113555"
      />
      <path
        d="M804.517 238.938C801.111 230.602 793.92 224.16 787.486 218.097C789.379 231.739 789.757 246.138 788.622 259.779C788.243 265.084 787.486 270.388 789 275.314C793.92 292.366 802.247 279.104 804.896 270.01C807.545 259.779 808.68 248.79 804.517 238.938Z"
        fill="#113555"
      />
      <path
        d="M828.361 280.999C832.902 281.378 837.065 282.893 841.607 284.788C851.069 288.577 860.53 292.366 869.613 296.534C857.881 297.292 846.148 296.534 834.795 293.882C832.145 293.124 829.118 292.745 826.468 293.124C821.17 293.882 817.385 298.429 813.601 302.218C805.274 309.418 784.08 308.281 794.677 295.019C800.733 287.061 818.899 280.241 828.361 280.999Z"
        fill="#113555"
      />
      <path
        d="M871.127 215.824C876.047 210.898 880.589 205.593 882.86 199.152C885.509 192.71 885.887 185.511 883.238 179.448C882.103 185.511 880.967 191.952 876.426 196.12C873.398 199.152 869.235 200.667 865.072 202.562C854.475 207.867 842.742 219.235 841.229 231.739C851.826 232.118 863.936 222.645 871.127 215.824Z"
        fill="#113555"
      />
      <path
        d="M846.527 169.218C845.392 163.155 842.364 157.851 839.336 152.167C837.444 148.756 835.173 144.967 831.389 143.452C832.146 151.788 832.903 160.124 834.038 168.839C834.416 174.144 835.173 179.449 834.416 185.133C833.659 191.575 832.903 211.278 843.878 199.532C848.798 193.469 847.663 176.039 846.527 169.218Z"
        fill="#113555"
      />
      <path
        d="M874.534 163.154C876.048 155.576 876.048 147.619 879.454 140.419C884.374 129.43 896.106 122.989 907.46 118.821C910.866 117.305 915.408 115.789 916.922 112C907.839 113.516 898.377 115.031 889.672 118.442C880.968 121.852 872.642 127.157 867.722 135.114C861.288 144.966 858.26 161.639 860.531 173.385C863.937 190.437 872.642 172.627 874.534 163.154Z"
        fill="#113555"
      />
      <path
        d="M870.749 150.272C868.478 157.471 866.586 164.671 865.072 171.87C863.937 177.175 862.802 182.859 860.531 187.785C857.503 194.984 852.583 201.047 847.663 207.489C837.444 221.509 828.361 236.666 821.927 252.959C819.278 258.643 817.386 264.706 813.601 270.011C806.032 281.757 793.543 288.957 785.595 300.324C779.161 309.797 775.755 321.165 774.998 332.533"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M840.85 168.839C845.391 182.859 841.228 198.395 843.877 213.172"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M796.948 288.198C795.813 282.135 795.813 275.694 796.948 269.252C798.462 261.674 801.49 254.095 801.111 246.517C801.111 244.243 800.354 241.591 798.84 239.696"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M867.721 250.306C860.152 257.126 850.312 260.158 840.472 262.431C837.066 263.189 834.038 263.947 830.632 263.947C828.361 263.947 826.469 263.189 824.198 263.568C821.549 263.568 818.899 264.705 817.007 266.22"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M818.143 288.956C812.087 289.714 806.032 291.608 801.112 295.397C798.463 297.292 795.813 299.945 792.786 301.46C791.65 302.218 790.515 302.597 789.38 302.597C788.244 302.597 786.73 302.218 786.352 301.081"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M868.479 209.383C861.288 214.309 854.097 219.993 847.663 225.677C845.771 227.571 843.878 229.466 841.608 230.603C839.337 231.739 836.309 232.118 834.038 230.603"
        stroke="#55A8E1"
        strokeWidth="1.67642"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="380.486"
        y="48.4859"
        width="436.028"
        height="731.028"
        fill="url(#pattern0)"
      />
      <rect
        x="380.486"
        y="48.4859"
        width="436.028"
        height="731.028"
        fill="black"
        fillOpacity="0.1"
      />
      <rect
        x="380.486"
        y="48.4859"
        width="436.028"
        height="731.028"
        stroke="#FFC50A"
        strokeWidth="6.97171"
      />
      <g clipPath="url(#clip0_7762_202563)">
        <path
          d="M776.861 454.963C769.912 454.375 759.43 490.822 756.603 500.58C756.603 500.58 763.67 509.045 775.565 508.104C787.46 507.164 780.158 455.198 776.861 454.963Z"
          fill="#FFA70B"
        />
        <path
          d="M779.923 402.174C772.268 402.409 770.501 414.049 770.501 423.807C770.501 433.565 767.321 447.791 768.735 451.906C768.735 451.906 772.975 452.024 778.274 451.083C778.157 449.437 778.039 447.556 778.51 446.616C778.51 446.616 779.099 449.672 780.512 450.73C788.05 449.202 796.53 445.675 797.472 437.68C799.356 422.867 779.923 402.174 779.923 402.174Z"
          fill="#012A66"
        />
        <path
          d="M772.385 504.224C763.788 504.107 760.372 500.462 757.663 496.935C757.31 498.346 756.957 499.522 756.603 500.462C756.603 500.462 761.903 506.693 770.854 507.869C774.505 505.87 776.154 504.342 772.385 504.224Z"
          fill="#FCF6E6"
        />
        <path
          d="M766.143 501.873L754.719 603.806C754.719 603.806 760.254 606.157 761.668 603.453C763.552 599.573 782.278 535.733 782.043 526.093C781.572 516.569 766.143 501.873 766.143 501.873Z"
          fill="#C97777"
        />
        <path
          d="M787.579 435.447C785.459 430.626 779.334 423.22 775.919 419.222C775.919 423.69 775.919 429.216 775.919 434.389C775.919 437.445 775.33 441.325 774.741 444.264C774.623 445.087 774.506 445.91 774.506 446.733C774.388 448.144 774.506 449.673 774.859 451.083C774.859 451.319 774.977 451.436 774.977 451.671C776.037 451.554 777.214 451.436 778.51 451.201C778.392 449.555 778.274 447.674 778.746 446.733C778.746 446.733 779.334 449.79 780.748 450.848C784.045 450.143 787.461 449.085 790.405 447.556C790.17 443.206 789.11 439.209 787.579 435.447Z"
          fill="#0C1E34"
        />
        <path
          d="M789.463 527.503L822.44 786.038H835.866C835.866 786.038 827.74 602.63 827.033 582.761C826.326 562.774 827.74 544.433 817.14 527.621L789.463 527.503Z"
          fill="#C97777"
        />
        <path
          d="M817.14 527.503L762.61 786.626H750.244L766.497 527.503H817.14Z"
          fill="#C97777"
        />
        <path
          d="M817.14 527.503L769.441 768.403H751.186L766.497 527.503H817.14Z"
          fill="#012A66"
        />
        <path
          d="M806.658 580.174L769.441 768.403H768.97L792.761 552.546L806.658 580.174Z"
          fill="#061F36"
        />
        <path
          d="M781.925 527.503L818.435 770.989L836.455 771.225C836.455 771.225 833.275 602.63 832.568 582.643C831.862 562.657 827.622 544.316 817.022 527.503H781.925Z"
          fill="#012A66"
        />
        <path
          d="M808.543 543.846C810.074 546.08 812.783 552.193 819.849 555.485"
          stroke="#061F36"
          strokeWidth="0.956115"
          strokeMiterlimit="10"
        />
        <path
          d="M836.455 543.493L758.252 544.551C758.252 544.551 763.081 490.704 764.494 487.295L770.383 465.779C772.032 458.843 774.387 454.493 780.629 453.67C781.218 453.552 781.689 453.552 782.278 453.552H794.527C794.527 453.552 812.664 451.318 817.846 455.198C823.028 459.078 828.564 462.252 827.975 467.308L836.455 543.493Z"
          fill="#FFC50A"
        />
        <path
          d="M807.364 456.139C808.66 454.846 807.364 453.2 806.776 451.554C805.598 448.379 803.831 441.678 805.951 430.862C808.542 416.636 805.127 404.056 792.642 400.764C776.154 396.414 771.09 418.869 775.329 432.155C776.154 434.624 778.038 436.622 780.276 437.681L782.043 438.504C783.927 439.444 785.105 440.973 785.223 442.854C785.458 446.146 785.576 451.201 783.809 453.67C781.218 457.55 775.447 460.724 779.687 467.19C783.809 473.422 793.82 469.895 807.364 456.139Z"
          fill="#C97777"
        />
        <path
          d="M775.565 421.221C776.086 421.221 776.508 420.537 776.508 419.692C776.508 418.848 776.086 418.164 775.565 418.164C775.045 418.164 774.623 418.848 774.623 419.692C774.623 420.537 775.045 421.221 775.565 421.221Z"
          fill="#0C1E34"
        />
        <path
          d="M785.458 422.279C785.979 422.279 786.401 421.595 786.401 420.751C786.401 419.906 785.979 419.222 785.458 419.222C784.938 419.222 784.516 419.906 784.516 420.751C784.516 421.595 784.938 422.279 785.458 422.279Z"
          fill="#0C1E34"
        />
        <path
          d="M778.981 420.75C778.981 421.691 778.981 422.632 778.51 423.572C778.392 423.925 778.039 424.278 777.921 424.63C777.567 425.218 777.567 425.924 777.685 426.629"
          stroke="#774D4D"
          strokeWidth="0.478057"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M836.455 771.225L835.984 760.996H813.724L818.435 770.99L836.455 771.225Z"
          fill="#024391"
        />
        <path
          d="M769.441 768.403L774.034 757.586H751.186V768.403H769.441Z"
          fill="#024391"
        />
        <path
          d="M812.782 466.602L827.975 533.617C827.975 533.617 847.173 537.732 846.701 533.382C846.23 529.032 833.628 479.535 829.624 468.248C824.324 453.67 809.72 448.262 812.782 466.602Z"
          fill="#C97777"
        />
        <path
          d="M827.74 532.559C827.74 532.559 820.556 605.687 819.496 609.449C818.436 613.446 824.913 613.329 826.327 610.625C828.211 606.745 846.937 542.905 846.702 533.264C846.466 523.623 827.74 532.559 827.74 532.559Z"
          fill="#C97777"
        />
        <path
          d="M819.614 609.449C819.614 609.449 810.191 622.852 812.547 622.499C814.902 622.146 816.669 617.679 817.729 620.265C820.673 627.554 821.616 633.668 822.911 632.727C822.911 632.727 823.029 632.022 822.911 630.494C822.911 630.494 823.265 631.904 824.442 632.727C825.62 633.55 824.56 628.142 824.56 628.142C824.56 628.142 825.149 631.317 826.091 631.081C826.327 630.376 826.091 628.495 826.091 628.495C826.091 628.495 826.327 629.318 826.916 629.2C827.387 629.2 825.149 622.264 825.149 620.148C825.149 618.031 825.856 611.565 826.444 610.507C826.916 609.566 819.614 609.449 819.614 609.449Z"
          fill="#C97777"
        />
        <path
          d="M835.867 785.921L838.693 796.149H805.716C805.716 796.149 803.478 795.091 811.016 792.152C818.554 789.213 823.147 788.037 822.44 785.921H835.867Z"
          fill="#C97777"
        />
        <path
          d="M762.728 785.921L762.256 796.149H731.046C731.046 796.149 728.808 795.091 736.346 792.152C743.883 789.213 750.126 787.92 750.243 785.921"
          fill="#C97777"
        />
        <path
          d="M824.678 792.504L834.336 788.154H836.573L838.811 796.267H805.716C805.716 796.267 803.478 795.208 811.016 792.269C815.727 790.506 819.26 789.33 821.145 788.154H824.678V792.504Z"
          fill="white"
        />
        <path
          d="M751.068 792.504L760.726 788.154H762.963L763.906 796.267H730.693C730.693 796.267 728.455 795.208 735.993 792.269C740.704 790.506 745.768 789.33 747.653 788.154H751.186V792.504H751.068Z"
          fill="white"
        />
        <path
          d="M760.962 600.867C760.962 600.867 768.028 616.621 765.673 615.916C763.435 615.21 761.079 610.037 760.962 612.859C760.726 616.621 760.726 626.262 759.666 625.086C759.666 625.086 756.84 619.795 756.722 617.679C756.251 613.094 754.484 607.568 754.602 603.806C754.72 602.63 760.962 600.867 760.962 600.867Z"
          fill="#C97777"
        />
        <path
          d="M778.863 436.857C779.334 437.21 779.805 437.445 780.276 437.68L782.043 438.503C783.927 439.444 785.105 440.972 785.223 442.853C785.458 446.145 785.576 451.201 783.809 453.67C783.692 453.905 783.456 454.14 783.338 454.375C786.165 452.612 797.471 435.447 797.471 435.447C788.991 439.091 782.278 438.974 778.863 436.857Z"
          fill="#A55D5D"
        />
        <path
          d="M782.279 423.454C781.926 423.69 781.572 423.925 781.572 424.277C781.572 424.748 782.043 425.1 782.514 425.218C784.046 425.806 785.812 425.923 787.461 425.688C787.697 425.688 787.932 425.571 788.168 425.453C788.403 425.336 788.521 425.1 788.521 424.748C788.521 424.395 788.285 424.16 787.932 423.925C787.579 423.69 787.225 423.454 786.872 423.337C786.519 423.219 786.166 422.984 785.694 422.984C785.459 422.984 785.341 422.867 785.106 422.867C784.988 422.867 784.87 422.749 784.634 422.749C784.163 422.749 783.81 422.867 783.339 422.867C782.986 422.984 782.514 423.102 782.161 423.337C782.397 423.454 782.279 423.454 782.279 423.454Z"
          fill="#D68B8B"
        />
        <path
          d="M780.865 403.233C778.745 407.23 779.099 411.463 784.516 413.344C789.934 415.225 794.409 416.4 794.527 418.164C794.645 419.928 798.414 413.579 798.414 413.579L794.292 406.407L780.865 403.233Z"
          fill="#012A66"
        />
        <path
          d="M776.861 430.861C777.685 430.861 778.392 430.979 779.216 430.743C779.923 430.508 780.629 430.038 780.865 429.215"
          stroke="#774D4D"
          strokeWidth="0.478057"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M813.842 423.69C807.718 416.988 808.071 410.874 797.589 402.057C797.589 402.057 784.516 392.886 776.861 406.172L794.88 411.462L794.998 420.515C794.762 424.63 788.991 446.38 792.525 454.14C792.525 454.14 795.94 454.022 800.533 453.199C799.945 451.553 799.591 449.085 799.591 449.085C799.591 449.085 800.298 451.553 802.182 452.847C808.307 451.436 815.844 448.614 820.791 442.618C820.791 442.618 824.442 435.211 813.842 423.69Z"
          fill="#012A66"
        />
        <path
          d="M792.997 419.34C794.881 417.106 797.825 418.164 797.59 420.868C797.354 423.572 791.937 426.629 791.937 426.629C791.937 426.629 790.405 422.397 792.997 419.34Z"
          fill="#C97777"
        />
        <path
          d="M794.292 419.34C795.587 419.105 796.411 419.693 796.411 420.398C796.176 421.927 794.88 422.985 794.88 422.985"
          stroke="#774D4D"
          strokeWidth="0.478057"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M780.63 406.289C779.57 408.758 777.45 413.109 774.388 414.049C774.388 414.049 774.505 409.464 776.979 406.054C779.452 402.645 780.63 406.289 780.63 406.289Z"
          fill="#012A66"
        />
        <path
          d="M794.763 426.629C795.413 426.629 795.94 426.103 795.94 425.453C795.94 424.804 795.413 424.278 794.763 424.278C794.112 424.278 793.585 424.804 793.585 425.453C793.585 426.103 794.112 426.629 794.763 426.629Z"
          fill="white"
        />
        <path
          d="M774.035 425.218C774.27 425.218 774.624 425.218 774.859 425.101C775.33 424.983 775.801 424.866 776.037 424.395C776.272 423.925 776.155 423.337 775.801 422.985C775.448 422.632 774.977 422.397 774.388 422.397C774.152 422.397 774.035 422.397 773.799 422.397C773.917 423.337 773.917 424.278 774.035 425.218Z"
          fill="#D68B8B"
        />
        <path
          d="M814.314 448.144C816.669 446.733 818.907 444.852 820.791 442.619C820.791 442.619 824.443 435.212 813.843 423.69C807.718 416.988 808.072 410.875 797.59 402.057C797.59 402.057 792.761 398.765 787.343 399C787.579 399 787.697 399 787.932 399.118C791.112 399.823 794.174 401.234 796.176 403.703C801.005 409.346 799.474 418.164 803.125 424.63C804.774 427.452 807.247 429.686 809.249 432.272C809.367 432.39 809.485 432.625 809.603 432.743C810.192 433.448 810.663 434.271 811.016 434.977C811.487 435.799 811.841 436.622 812.194 437.445C812.547 438.268 812.901 439.091 813.136 440.032C813.372 440.855 813.607 441.796 813.725 442.619C813.843 443.559 813.961 444.382 814.078 445.323C814.314 446.263 814.314 447.204 814.314 448.144Z"
          fill="#093B85"
        />
        <path
          d="M783.574 415.695C782.75 415.93 782.396 417.694 783.692 417.694C784.163 417.694 786.401 417.694 788.521 418.517C790.641 419.34 790.641 418.282 788.756 417.341C787.461 416.636 784.516 415.46 783.574 415.695Z"
          fill="#012A66"
        />
        <path
          d="M777.685 415.343C778.51 415.695 777.803 417.106 777.096 417.106C776.507 417.106 774.505 416.989 773.916 417.459C773.21 417.929 773.328 416.754 774.27 416.283C775.094 415.813 776.743 414.99 777.685 415.343Z"
          fill="#012A66"
        />
        <path
          d="M815.02 453.905C823.971 455.551 827.151 460.489 833.04 473.187C839.046 485.884 846.702 502.462 846.702 502.462C846.702 502.462 834.218 515.394 819.496 512.22C819.496 512.22 804.774 452.024 815.02 453.905Z"
          fill="#FFC50A"
        />
        <path
          d="M818.671 508.81C819.142 510.926 819.495 512.219 819.495 512.219C834.217 515.394 846.701 502.461 846.701 502.461C846.701 502.461 846.113 501.05 845.053 498.816C834.571 510.338 824.442 509.868 818.671 508.81Z"
          fill="white"
        />
        <path
          d="M819.495 512.22C816.433 497.876 814.313 488.236 814.313 488.236"
          stroke="white"
          strokeWidth="0.956115"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M836.098 721.319C836.098 719.862 835.726 714.764 837.836 714.642C840.319 714.521 841.064 719.134 842.43 720.348"
        fill="#FFAE0B"
      />
      <path
        d="M831.505 751.912C831.132 753.126 831.008 754.461 831.132 755.675C832.622 766.722 835.353 777.648 838.084 788.453C838.829 791.609 839.946 794.766 841.436 797.558C841.933 798.408 843.174 798.529 843.671 797.679C844.416 796.587 844.54 794.887 844.664 793.552C845.657 781.897 846.774 770.122 845.905 758.467C845.657 754.704 844.664 750.576 840.94 748.877C837.712 747.299 832.746 748.27 831.505 751.912Z"
        fill="#FFAE0B"
      />
      <path
        d="M860.554 751.912C860.182 753.126 860.058 754.461 860.182 755.675C861.672 766.722 864.403 777.648 867.134 788.453C867.879 791.609 868.996 794.766 870.486 797.558C870.982 798.408 872.224 798.529 872.72 797.679C873.465 796.587 873.589 794.887 873.714 793.552C874.707 781.897 875.824 770.122 874.955 758.467C874.707 754.704 873.714 750.576 869.989 748.877C866.637 747.299 861.672 748.27 860.554 751.912Z"
        fill="#FFAE0B"
      />
      <path
        d="M870.238 751.912C869.865 753.126 869.741 754.461 869.865 755.675C871.355 766.722 874.086 777.648 876.817 788.453C877.562 791.609 878.679 794.766 880.169 797.558C880.666 798.408 881.907 798.529 882.404 797.679C883.148 796.587 883.273 794.887 883.397 793.552C884.39 781.897 885.507 770.122 884.638 758.467C884.39 754.704 883.397 750.576 879.672 748.877C876.445 747.299 871.479 748.27 870.238 751.912Z"
        fill="#FFC50A"
      />
      <path
        d="M880.914 741.35C878.058 738.315 871.975 738.8 868.127 739.286C863.534 739.893 859.437 742.078 855.092 743.049C851.74 743.778 848.388 741.957 847.023 738.922C845.533 735.401 844.664 730.91 846.154 727.025C846.899 724.84 834.733 730.303 834.608 733.944C834.981 738.558 832.126 743.535 831.008 747.905C829.519 753.732 830.263 760.531 832.87 765.994C835.353 771.093 840.816 776.798 847.023 776.92C850.623 777.405 854.347 777.041 857.327 776.191C862.044 774.856 865.644 771.7 869.617 768.907C872.596 766.722 875.948 765.508 878.555 762.959C882.279 759.317 885.135 756.646 884.886 751.426C884.762 748.148 883.272 743.778 880.914 741.35Z"
        fill="#FFC50A"
      />
      <path
        d="M830.264 754.461C832.126 748.148 834.36 741.593 840.319 738.558C842.181 737.587 844.168 736.616 846.03 735.402C845.409 732.731 845.285 729.696 846.278 727.025C846.278 726.904 846.278 726.782 846.278 726.782C843.547 727.146 841.188 727.996 839.078 729.332C838.581 729.574 838.085 729.939 837.712 730.303C836.098 731.517 834.857 732.852 834.733 734.066C835.105 738.679 832.25 743.657 831.133 748.027C830.512 749.969 830.264 752.155 830.264 754.461Z"
        fill="#FFAE0B"
      />
      <path
        d="M842.802 720.833C842.554 723.383 845.657 725.689 847.768 723.99C849.257 722.897 849.381 720.226 849.63 718.527C849.878 715.977 849.754 715.006 847.271 716.099C844.664 717.313 842.926 718.77 842.802 720.833Z"
        fill="#FFC50A"
      />
      <path
        d="M862.044 774.249C860.927 772.428 858.94 773.035 856.83 773.521C850.747 775.099 844.416 775.463 840.443 769.514C835.477 761.988 838.705 753.975 836.47 746.084C835.726 743.292 834.236 742.2 832.746 742.443C832.001 744.263 831.381 746.084 830.884 747.784C829.518 753.733 830.263 760.531 832.87 765.994C835.353 771.093 840.815 776.798 847.023 776.92C850.623 777.405 854.347 777.041 857.326 776.191C859.064 775.827 860.678 775.099 862.044 774.249Z"
        fill="white"
      />
      <path
        d="M850.251 726.661C849.878 724.233 847.644 718.527 843.547 717.556C841.933 717.434 840.195 717.677 838.581 718.163C836.222 718.77 834.981 719.619 834.112 721.198C833.367 722.533 832.374 723.747 831.381 724.84C830.884 725.325 830.388 725.932 830.264 726.661C829.767 728.846 830.884 732.245 832.498 733.823C833.367 734.673 834.733 735.158 835.974 735.28C840.319 735.887 844.788 735.401 848.14 732.366C850.002 730.667 850.623 729.088 850.251 726.661Z"
        fill="#FFC50A"
      />
      <path
        d="M841.313 751.912C840.94 753.126 840.816 754.461 840.94 755.675C842.43 766.722 845.161 777.648 847.892 788.453C848.637 791.609 849.754 794.766 851.244 797.558C851.741 798.408 852.982 798.529 853.479 797.679C854.223 796.587 854.348 794.887 854.472 793.552C855.465 781.897 856.582 770.122 855.713 758.467C855.465 754.704 854.472 750.576 850.747 748.877C847.52 747.299 842.554 748.27 841.313 751.912Z"
        fill="#FFC50A"
      />
      <path
        d="M873.714 746.692C887.493 745.114 880.045 709.18 881.783 701.046C882.776 696.797 885.011 688.542 890.721 689.635C892.335 689.877 894.321 690.484 894.445 692.427C894.694 694.733 892.707 694.733 891.466 696.311C888.983 699.711 888.735 706.145 888.487 710.151C888.114 716.828 888.859 723.505 888.859 730.182C888.859 734.188 889.356 738.558 888.114 742.564C886.873 746.571 884.017 748.634 881.659 751.912"
        fill="#FFC50A"
      />
      {/* <path
        d="M848.637 717.192C848.388 717.799 844.788 720.713 847.271 721.32C849.009 721.805 849.009 718.527 848.885 717.435C848.388 717.435 848.761 717.313 848.761 717.071"
        fill="#FFAE0B"
      /> */}
      <path
        d="M840.94 790.152C840.319 790.152 839.45 790.395 838.705 790.638C839.45 793.066 840.319 795.373 841.561 797.558C842.057 798.408 843.299 798.529 843.795 797.679C844.54 796.587 844.664 794.887 844.788 793.552C844.788 792.945 844.912 792.459 844.912 791.852C843.795 790.881 842.305 790.031 840.94 790.152Z"
        fill="#FFF2CC"
      />
      <path
        d="M877.438 786.51C877.066 786.753 876.693 786.996 876.445 787.36C876.569 787.724 876.693 788.21 876.693 788.574C877.438 791.73 878.555 794.887 880.045 797.679C880.542 798.529 881.783 798.65 882.28 797.8C883.024 796.708 883.149 795.008 883.273 793.673C883.521 791.609 883.645 789.424 883.769 787.36C882.031 785.782 879.797 785.175 877.438 786.51Z"
        fill="white"
      />
      <path
        d="M872.721 797.679C873.466 796.586 873.59 794.887 873.714 793.551C873.838 792.216 873.962 791.002 874.086 789.666C873.59 789.181 872.969 788.938 872.224 788.695C870.114 787.967 868.5 788.452 867.383 789.545C868.127 792.337 869.121 795.129 870.486 797.679C870.859 798.407 872.224 798.529 872.721 797.679Z"
        fill="#FFF2CC"
      />
      <path
        d="M854.719 790.153C852.609 789.06 850.126 788.817 848.264 790.031C849.009 792.581 849.878 795.251 851.243 797.558C851.74 798.408 852.981 798.529 853.478 797.679C854.223 796.587 854.347 794.887 854.471 793.552C854.471 792.338 854.595 791.245 854.719 790.153Z"
        fill="white"
      />
      <path
        d="M894.446 692.426C894.321 690.484 892.211 689.877 890.721 689.634C888.363 689.148 886.625 690.362 885.259 692.062C886.252 694.247 888.859 696.19 891.094 696.918C891.218 696.797 891.342 696.554 891.466 696.311C892.708 694.733 894.694 694.733 894.446 692.426Z"
        fill="white"
      />
      <path
        d="M836.098 721.319C834.733 723.869 838.333 725.811 839.078 728.118C840.071 731.153 837.216 732.367 834.609 732.245C832.374 732.124 830.139 731.031 829.643 728.846C829.146 726.661 830.139 725.326 832.002 724.354C833.243 723.505 833.615 722.169 834.609 721.198C835.105 720.834 836.098 720.227 836.098 721.319Z"
        fill="white"
      />
      {/* <path
        d="M832.126 727.268C831.877 727.025 830.76 726.175 831.132 725.69C831.381 725.204 832.746 725.568 832.995 725.69C834.236 726.296 833.119 727.146 832.126 727.268Z"
        fill="#FFAE0B"
      /> */}
      <path
        d="M830.512 750.576C830.512 750.94 830.388 751.426 830.388 751.79C830.388 752.154 830.388 752.397 830.264 752.761C830.264 753.368 830.264 753.854 830.264 754.461C831.381 750.454 832.747 746.448 834.981 743.292C834.36 742.685 833.616 742.442 832.871 742.564C832.622 743.171 832.374 743.778 832.126 744.385C832.126 744.506 832.126 744.506 832.002 744.627C832.002 744.992 831.878 745.356 831.629 745.841C831.629 745.963 831.505 746.084 831.505 746.327C831.381 746.813 831.133 747.419 831.009 747.905C830.884 748.512 830.76 749.24 830.636 749.847C830.636 750.09 830.636 750.333 830.512 750.576Z"
        fill="#FFF2CC"
      />
      <g clipPath="url(#clip1_7762_202563)">
        <path
          d="M824.957 232.117C825.714 229.465 826.471 226.434 826.471 223.402C825.714 218.097 821.929 213.929 817.766 210.898C813.603 207.867 809.061 205.214 805.655 201.425C796.194 190.815 798.843 174.143 793.923 160.88C791.652 169.974 789.76 179.069 789.76 188.163C789.76 197.257 791.652 206.73 796.95 214.687C803.763 224.918 812.089 232.496 817.009 243.864C821.929 241.969 823.443 236.665 824.957 232.117Z"
          fill="#113555"
        />
        <path
          d="M804.52 210.899C806.791 213.172 809.819 214.309 811.711 216.583C812.846 218.098 813.225 219.614 813.982 221.13C815.496 225.677 817.388 230.603 817.388 235.529C817.388 238.939 817.009 242.728 817.009 246.139C817.009 249.549 817.766 253.717 820.037 255.991"
          stroke="#55A8E1"
          strokeWidth="1.67642"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M856.369 242.349C863.939 238.18 872.643 237.802 880.969 239.317C889.296 240.833 897.243 244.243 904.813 247.653C896.865 248.032 888.917 248.79 882.105 252.579C876.049 255.99 871.886 261.295 866.588 265.463C859.775 270.389 832.148 278.346 826.849 266.978C823.443 260.158 850.314 245.38 856.369 242.349Z"
          fill="#113555"
        />
        <path
          d="M871.13 215.824C876.05 210.898 880.592 205.593 882.863 199.152C885.512 192.71 885.89 185.51 883.241 179.448C882.106 185.51 880.97 191.952 876.429 196.12C873.401 199.152 869.238 200.667 865.075 202.562C854.478 207.867 842.745 219.234 841.231 231.739C851.828 232.118 863.939 222.645 871.13 215.824Z"
          fill="#113555"
        />
        <path
          d="M846.528 169.218C845.393 163.156 842.365 157.851 839.338 152.167C837.445 148.757 835.175 144.967 831.39 143.452C832.147 151.788 832.904 160.124 834.039 168.839C834.418 174.144 835.175 179.449 834.418 185.133C833.661 191.575 832.904 211.279 843.879 199.532C848.799 193.469 847.664 176.039 846.528 169.218Z"
          fill="#113555"
        />
        <path
          d="M874.536 163.154C876.05 155.576 876.05 147.619 879.456 140.419C884.376 129.43 896.108 122.989 907.462 118.821C910.868 117.305 915.41 115.789 916.924 112C907.841 113.516 898.379 115.031 889.674 118.442C880.97 121.852 872.644 127.157 867.723 135.114C861.29 144.966 858.262 161.639 860.533 173.385C863.939 190.437 872.644 172.627 874.536 163.154Z"
          fill="#113555"
        />
        <path
          d="M870.752 150.272C868.481 157.471 866.589 164.671 865.075 171.87C863.94 177.175 862.804 182.859 860.533 187.785C857.506 194.985 852.586 201.047 847.666 207.489C837.447 221.509 828.364 236.666 821.93 252.96C819.281 258.643 817.388 264.706 813.604 270.011C806.035 281.758 793.545 288.957 785.597 300.325C779.164 309.798 775.757 321.165 775 332.533"
          stroke="#55A8E1"
          strokeWidth="1.67642"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M840.851 168.839C845.393 182.859 841.23 198.395 843.879 213.173"
          stroke="#55A8E1"
          strokeWidth="1.67642"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M867.723 250.306C860.153 257.126 850.313 260.158 840.473 262.431C837.067 263.189 834.039 263.947 830.633 263.947C828.362 263.947 826.47 263.189 824.199 263.568C821.55 263.568 818.901 264.705 817.008 266.22"
          stroke="#55A8E1"
          strokeWidth="1.67642"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M868.48 209.383C861.29 214.309 854.099 219.993 847.665 225.677C845.773 227.571 843.88 229.466 841.61 230.603C839.339 231.739 836.311 232.118 834.04 230.603"
          stroke="#55A8E1"
          strokeWidth="1.67642"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M816.5 379C832.24 379 845 366.24 845 350.5C845 334.76 832.24 322 816.5 322C800.76 322 788 334.76 788 350.5C788 366.24 800.76 379 816.5 379Z"
        fill="#EF4B5D"
      />
      <path
        d="M827.363 354.575L816.5 364.461L805.637 354.575C802.534 351.755 802.225 346.866 804.937 343.645C807.64 340.425 812.345 340.099 815.44 342.919L816.5 343.879L817.551 342.919C820.647 340.099 825.352 340.425 828.064 343.645C830.775 346.866 830.466 351.755 827.363 354.575Z"
        fill="white"
      />
      <circle
        cx="266.877"
        cy="313.372"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="266.877" cy="313.372" r="50.422" fill="#E2F7FF" />
      <circle cx="266.877" cy="256.744" r="11.6359" fill="#FFC50A" />
      <path
        d="M265.375 254.899L266.463 252.694C266.606 252.406 267.017 252.406 267.159 252.694L268.247 254.899L270.679 255.252C270.998 255.298 271.125 255.689 270.895 255.914L269.135 257.63L269.55 260.053C269.604 260.37 269.272 260.612 268.987 260.462L266.811 259.317L264.635 260.462C264.35 260.612 264.018 260.37 264.072 260.053L264.488 257.63L262.728 255.914C262.497 255.689 262.625 255.298 262.943 255.252L265.375 254.899Z"
        fill="white"
      />
      <g clipPath="url(#clip2_7762_202563)">
        <path
          d="M235.2 340.696C234.921 339.016 235.119 337.292 235.773 335.717C236.433 334.076 237.531 332.642 238.949 331.569C240.367 330.496 242.052 329.823 243.825 329.622C246.141 329.388 248.465 329.983 250.375 331.3C252.286 332.616 253.657 334.567 254.24 336.798C254.978 336.521 255.759 336.377 256.548 336.373C257.802 336.376 259.025 336.749 260.064 337.443C261.103 338.137 261.91 339.121 262.382 340.271C262.425 340.385 262.44 340.509 262.424 340.63C262.409 340.752 262.365 340.868 262.294 340.969C262.224 341.07 262.13 341.152 262.021 341.21C261.912 341.267 261.79 341.297 261.666 341.298H235.934C235.76 341.297 235.592 341.237 235.458 341.127C235.324 341.017 235.233 340.865 235.2 340.696Z"
          fill="#007E86"
        />
        <path
          d="M270.165 300.566H261.003V340.731H270.165V300.566Z"
          fill="#352269"
        />
        <path
          d="M278.776 317.194L275.663 314.112L265.591 324.084L268.704 327.166L278.776 317.194Z"
          fill="#352269"
        />
        <path
          d="M299.297 340.412C299.673 338.018 299.382 335.568 298.456 333.325C297.523 330.966 295.962 328.9 293.94 327.346C291.917 325.793 289.508 324.81 286.968 324.502C283.636 324.164 280.292 325.021 277.544 326.916C274.795 328.811 272.825 331.62 271.99 334.831C270.973 334.446 269.893 334.248 268.805 334.247C267.006 334.233 265.244 334.756 263.75 335.749C262.256 336.741 261.099 338.156 260.43 339.81C260.367 339.975 260.344 340.152 260.365 340.328C260.386 340.503 260.449 340.671 260.549 340.817C260.649 340.963 260.783 341.083 260.94 341.166C261.097 341.25 261.272 341.295 261.45 341.298H298.223C298.479 341.298 298.726 341.21 298.922 341.048C299.118 340.886 299.251 340.661 299.297 340.412Z"
          fill="#00B0AC"
        />
        <path
          d="M289.402 311.285C291.125 311.309 292.788 310.664 294.036 309.489C295.283 308.313 296.016 306.7 296.077 304.995C296.077 302.312 295 299.739 293.084 297.842C291.168 295.945 288.569 294.879 285.859 294.879H285.734C285.18 289.971 282.819 285.438 279.102 282.145C275.385 278.853 270.572 277.033 265.584 277.033C260.597 277.033 255.784 278.853 252.067 282.145C248.35 285.438 245.989 289.971 245.435 294.879H245.31C242.614 294.879 240.026 295.932 238.11 297.809C236.193 299.687 235.102 302.237 235.074 304.907V304.907C235.112 306.63 235.837 308.268 237.091 309.463C238.345 310.659 240.026 311.314 241.767 311.285H289.402Z"
          fill="#007E86"
        />
        <path
          d="M277.448 317.699C278.693 317.699 279.703 316.699 279.703 315.466C279.703 314.233 278.693 313.234 277.448 313.234C276.203 313.234 275.193 314.233 275.193 315.466C275.193 316.699 276.203 317.699 277.448 317.699Z"
          fill="#8D83BE"
        />
        <path
          d="M296.078 304.995C296.078 302.312 295.002 299.739 293.085 297.842C291.169 295.944 288.57 294.879 285.86 294.879H285.735C285.332 291.167 283.896 287.64 281.586 284.689C279.276 281.739 276.184 279.483 272.654 278.171C269.131 279.47 266.042 281.711 263.729 284.645C261.416 287.579 259.971 291.091 259.555 294.79H259.412C256.7 294.79 254.1 295.855 252.181 297.752C250.262 299.649 249.181 302.222 249.176 304.907C249.242 306.613 249.978 308.226 251.229 309.401C252.479 310.576 254.145 311.221 255.869 311.196H289.404C291.111 311.221 292.762 310.589 294.007 309.432C295.252 308.275 295.995 306.684 296.078 304.995Z"
          fill="#00B0AC"
        />
      </g>
      <circle
        cx="167.084"
        cy="424.736"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="167.083" cy="424.736" r="50.422" fill="#E2F7FF" />
      <circle cx="167.084" cy="368.108" r="11.6359" fill="#FFC50A" />
      <path
        d="M165.581 366.262L166.67 364.058C166.812 363.769 167.223 363.769 167.365 364.058L168.453 366.262L170.886 366.615C171.204 366.662 171.331 367.053 171.101 367.277L169.341 368.993L169.756 371.417C169.811 371.734 169.478 371.975 169.193 371.825L167.017 370.681L164.841 371.825C164.557 371.975 164.224 371.734 164.278 371.417L164.694 368.993L162.934 367.277C162.704 367.053 162.831 366.662 163.149 366.615L165.581 366.262Z"
        fill="white"
      />
      <g clipPath="url(#clip3_7762_202563)">
        <path
          d="M205.435 448.738C205.653 447.412 205.553 445.986 204.949 444.493C203.808 441.674 201.19 439.61 198.17 439.308C193.975 438.888 190.368 441.623 189.394 445.432C188.807 445.214 188.186 445.08 187.515 445.08C185.3 445.08 183.387 446.439 182.599 448.385C182.431 448.805 182.75 449.258 183.186 449.258H204.798C205.1 449.258 205.368 449.04 205.419 448.738H205.435Z"
          fill="#007E86"
        />
        <path
          d="M176.075 396.034H155.538V424.794H176.075V396.034Z"
          fill="#969696"
        />
        <path
          d="M176.075 449.241H134.999V410.682L155.537 396.034L176.075 410.682V449.241Z"
          fill="#003381"
        />
        <path
          d="M196.596 449.241H155.538V410.682L176.075 396.034L196.596 410.682V449.241Z"
          fill="#0075C9"
        />
        <path
          d="M181.978 429.257H171.39V449.241H181.978V429.257Z"
          fill="#003381"
        />
        <path
          d="M183.103 411.622C181.493 410.464 179.244 410.8 177.835 412.209L176.677 413.367L175.519 412.209C174.127 410.817 171.861 410.464 170.251 411.622C168.203 413.099 168.036 415.968 169.747 417.679L172.532 420.465L175.939 423.871C176.341 424.274 176.996 424.274 177.398 423.871L180.805 420.465L183.59 417.679C185.302 415.968 185.134 413.099 183.087 411.622H183.103Z"
          fill="#FFC50A"
        />
        <path
          d="M149.679 423.753H141.625V437.026H149.679V423.753Z"
          fill="#0075C9"
        />
        <path
          d="M167.045 423.753H158.99V437.026H167.045V423.753Z"
          fill="#003381"
        />
        <path
          d="M194.044 423.753H185.99V437.026H194.044V423.753Z"
          fill="#003381"
        />
        <path
          d="M151.962 448.738C152.18 447.412 152.079 445.986 151.475 444.493C150.334 441.674 147.717 439.61 144.696 439.308C140.502 438.888 136.894 441.623 135.921 445.432C135.334 445.214 134.713 445.08 134.042 445.08C131.827 445.08 129.914 446.439 129.125 448.385C128.958 448.805 129.276 449.258 129.713 449.258H151.324C151.626 449.258 151.895 449.04 151.945 448.738H151.962Z"
          fill="#00B0AC"
        />
        <path
          d="M155.534 410.682H132.613V413.401H155.534V410.682Z"
          fill="#0075C9"
        />
        <path
          d="M132.613 410.682L153.302 396.034H176.071L155.534 410.682H132.613Z"
          fill="#003381"
        />
      </g>
      <circle
        cx="340.633"
        cy="184.655"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="340.633" cy="184.655" r="50.422" fill="#E2F7FF" />
      <circle cx="340.633" cy="128.027" r="11.6359" fill="#FFC50A" />
      <path
        d="M339.131 126.181L340.219 123.976C340.362 123.688 340.773 123.688 340.915 123.976L342.003 126.181L344.436 126.534C344.754 126.58 344.881 126.971 344.651 127.196L342.891 128.912L343.306 131.335C343.36 131.652 343.028 131.894 342.743 131.744L340.567 130.599L338.391 131.744C338.107 131.894 337.774 131.652 337.828 131.335L338.244 128.912L336.484 127.196C336.254 126.971 336.381 126.58 336.699 126.534L339.131 126.181Z"
        fill="white"
      />
      <g clipPath="url(#clip4_7762_202563)">
        <path
          d="M338.976 186.275V160.822C338.976 160.379 338.612 160 338.154 160.016C322.594 160.474 310.336 174.102 312.105 190.149C313.48 202.559 323.495 212.583 335.895 213.958C351.929 215.729 365.546 203.46 366.004 187.888C366.004 187.445 365.641 187.066 365.198 187.066H339.765C339.323 187.066 338.976 186.718 338.976 186.275Z"
          fill="#352269"
        />
        <path
          d="M370.111 182.181C369.685 167.889 358.185 156.379 343.889 155.953C343.446 155.953 343.067 156.316 343.067 156.759V182.212C343.067 182.655 343.415 183.003 343.857 183.003H369.29C369.732 183.003 370.111 182.639 370.095 182.181H370.111Z"
          fill="#00B0AC"
        />
      </g>
      <circle
        cx="210.473"
        cy="110.901"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="210.473" cy="110.901" r="50.422" fill="#E2F7FF" />
      <circle cx="210.473" cy="54.2726" r="11.6359" fill="#FFC50A" />
      <path
        d="M208.971 52.4267L210.059 50.222C210.201 49.9337 210.612 49.9337 210.754 50.222L211.842 52.4267L214.275 52.7799C214.593 52.8261 214.72 53.217 214.49 53.4414L212.73 55.1577L213.145 57.5811C213.2 57.898 212.867 58.1396 212.583 57.9899L210.407 56.8453L208.23 57.9899C207.946 58.1396 207.613 57.898 207.668 57.5811L208.083 55.1577L206.323 53.4414C206.093 53.217 206.22 52.8261 206.538 52.7799L208.971 52.4267Z"
        fill="white"
      />
      <g clipPath="url(#clip5_7762_202563)">
        <path
          d="M209.581 143.481C226.652 143.481 240.491 129.642 240.491 112.571C240.491 95.5 226.652 81.6611 209.581 81.6611C192.51 81.6611 178.671 95.5 178.671 112.571C178.671 129.642 192.51 143.481 209.581 143.481Z"
          fill="#352269"
        />
        <path
          d="M212.148 140.916C229.219 140.916 243.058 127.077 243.058 110.006C243.058 92.9347 229.219 79.0958 212.148 79.0958C195.077 79.0958 181.238 92.9347 181.238 110.006C181.238 127.077 195.077 140.916 212.148 140.916Z"
          fill="#0075C9"
        />
        <path
          d="M212.148 133.78C225.278 133.78 235.922 123.136 235.922 110.006C235.922 96.8755 225.278 86.2314 212.148 86.2314C199.018 86.2314 188.374 96.8755 188.374 110.006C188.374 123.136 199.018 133.78 212.148 133.78Z"
          fill="#FFC50A"
        />
        <path
          d="M222.21 97.9923L207.668 104.839C207.36 104.984 207.108 105.237 206.963 105.544L200.116 120.086C199.538 121.333 200.821 122.615 202.067 122.037L216.61 115.191C216.917 115.046 217.17 114.793 217.314 114.486L224.161 99.9434C224.739 98.6969 223.457 97.4142 222.21 97.9923Z"
          fill="#352269"
        />
        <path
          d="M212.147 112.571C213.564 112.571 214.713 111.422 214.713 110.006C214.713 108.589 213.564 107.44 212.147 107.44C210.731 107.44 209.582 108.589 209.582 110.006C209.582 111.422 210.731 112.571 212.147 112.571Z"
          fill="#8D83BE"
        />
        <path
          d="M212.67 89.4473H212.652C211.904 89.4473 211.297 90.0539 211.297 90.8022V95.0656C211.297 95.8139 211.904 96.4205 212.652 96.4205H212.67C213.418 96.4205 214.025 95.8139 214.025 95.0656V90.8022C214.025 90.0539 213.418 89.4473 212.67 89.4473Z"
          fill="#352269"
        />
        <path
          d="M232.705 110.53V110.512C232.705 109.763 232.099 109.157 231.35 109.157H227.087C226.339 109.157 225.732 109.763 225.732 110.512V110.53C225.732 111.278 226.339 111.884 227.087 111.884H231.35C232.099 111.884 232.705 111.278 232.705 110.53Z"
          fill="#352269"
        />
        <path
          d="M211.621 130.564H211.639C212.388 130.564 212.994 129.957 212.994 129.209V124.946C212.994 124.197 212.388 123.591 211.639 123.591H211.621C210.873 123.591 210.266 124.197 210.266 124.946V129.209C210.266 129.957 210.873 130.564 211.621 130.564Z"
          fill="#352269"
        />
        <path
          d="M191.589 109.482V109.5C191.589 110.248 192.195 110.855 192.944 110.855H197.207C197.955 110.855 198.562 110.248 198.562 109.5V109.482C198.562 108.733 197.955 108.127 197.207 108.127H192.944C192.195 108.127 191.589 108.733 191.589 109.482Z"
          fill="#352269"
        />
      </g>
      <circle
        cx="62.9524"
        cy="68.9519"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="62.9511" cy="68.9522" r="50.422" fill="#E2F7FF" />
      <circle cx="62.951" cy="12.3258" r="11.6359" fill="#FFC50A" />
      <path
        d="M61.4489 10.479L62.537 8.2742C62.6793 7.98591 63.0903 7.98591 63.2326 8.2742L64.3207 10.479L66.7532 10.8321C67.0713 10.8783 67.1984 11.2692 66.9683 11.4936L65.2083 13.21L65.6237 15.6333C65.6781 15.9502 65.3454 16.1918 65.0609 16.0421L62.8848 14.8975L60.7087 16.0421C60.4242 16.1918 60.0915 15.9502 60.1459 15.6333L60.5613 13.21L58.8013 11.4936C58.5712 11.2692 58.6983 10.8783 59.0164 10.8321L61.4489 10.479Z"
        fill="white"
      />
      <g clipPath="url(#clip6_7762_202563)">
        <path
          d="M32.2612 86.3105H48.2336V98.9082H37.6512C34.6688 98.9082 32.2612 96.4828 32.2612 93.4782V86.3105Z"
          fill="#003381"
        />
        <path
          d="M48.2317 52.1394H32.2593V44.9718C32.2593 41.9671 34.6669 39.5417 37.6493 39.5417H42.8417C45.8242 39.5417 48.2317 41.9671 48.2317 44.9718V52.1394Z"
          fill="#003381"
        />
        <path
          d="M83.7725 39.54H37.7778C38.6402 39.6124 43.1678 41.9655 43.1678 44.9701V93.4783C43.1678 96.483 40.7603 98.9084 37.7778 98.9084H83.7725C86.755 98.9084 89.1625 96.483 89.1625 93.4783V44.9701C89.1625 41.9655 86.755 39.54 83.7725 39.54Z"
          fill="#0075C9"
        />
        <path
          d="M74.8979 86.997L68.6814 87.5038L69.1845 81.223L89.6305 60.6251L95.3439 66.3991L74.8979 86.997Z"
          fill="#003381"
        />
        <path
          d="M96.612 56.1347L99.8008 59.3472C100.5 60.0511 100.5 61.203 99.8008 61.9069L95.3416 66.3993L89.6119 60.6271L94.0711 56.1347C94.7699 55.4308 95.9132 55.4308 96.612 56.1347Z"
          fill="#0075C9"
        />
        <path
          d="M74.3927 54.0204L75.2551 54.3643C75.5066 54.4729 75.5066 54.8349 75.2551 54.9254L74.3927 55.2693C73.4225 55.6494 72.6679 56.4278 72.2906 57.3871L71.9492 58.2559C71.8414 58.5093 71.4821 58.5093 71.3922 58.2559L71.0509 57.3871C70.6736 56.4097 69.901 55.6494 68.9488 55.2693L68.0864 54.9254C67.8348 54.8168 67.8348 54.4548 68.0864 54.3643L68.9488 54.0204C69.919 53.6403 70.6736 52.862 71.0509 51.9027L71.3922 51.0339C71.5 50.7805 71.8594 50.7805 71.9492 51.0339L72.2906 51.9027C72.6679 52.8801 73.4404 53.6403 74.3927 54.0204Z"
          fill="#FFC50A"
        />
        <path
          d="M69.2541 63.5234L71.2305 64.3017C71.8054 64.537 71.8054 65.3696 71.2305 65.5868L69.2541 66.3651C67.0622 67.2339 65.3194 68.9896 64.457 71.1978L63.6845 73.1889C63.4509 73.7681 62.6244 73.7681 62.4088 73.1889L61.6363 71.1978C60.7739 68.9896 59.0311 67.2339 56.8392 66.3651L54.8628 65.5868C54.2879 65.3515 54.2879 64.5189 54.8628 64.3017L56.8392 63.5234C59.0311 62.6546 60.7739 60.8989 61.6363 58.6907L62.4088 56.6997C62.6424 56.1205 63.4689 56.1205 63.6845 56.6997L64.457 58.6907C65.3194 60.8989 67.0622 62.6546 69.2541 63.5234Z"
          fill="#FFC50A"
        />
      </g>
      <circle
        cx="284.234"
        cy="515.851"
        r="59.7307"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="4.65434"
      />
      <circle cx="284.236" cy="515.851" r="50.422" fill="#E2F7FF" />
      <circle cx="284.235" cy="459.222" r="11.6359" fill="#FFC50A" />
      <path
        d="M282.733 457.374L283.821 455.17C283.963 454.881 284.374 454.881 284.516 455.17L285.604 457.374L288.037 457.728C288.355 457.774 288.482 458.165 288.252 458.389L286.492 460.105L286.907 462.529C286.962 462.846 286.629 463.087 286.345 462.938L284.168 461.793L281.992 462.938C281.708 463.087 281.375 462.846 281.43 462.529L281.845 460.105L280.085 458.389C279.855 458.165 279.982 457.774 280.3 457.728L282.733 457.374Z"
        fill="white"
      />
      <path
        d="M309.537 495.81C302.911 491.096 293.592 492.484 287.815 498.177L283.052 502.871L278.289 498.177C272.512 492.484 263.193 491.096 256.567 495.81C248.118 501.81 247.414 513.503 254.455 520.441L265.947 531.767L280.029 545.644C281.685 547.276 284.377 547.276 286.055 545.644L300.136 531.767L311.629 520.441C318.67 513.503 317.966 501.81 309.517 495.81H309.537Z"
        fill="#0075C9"
      />
      <path
        d="M319.129 490.889L320.343 491.356C320.695 491.495 320.695 491.997 320.343 492.135L319.129 492.602C317.773 493.121 316.7 494.177 316.172 495.509L315.697 496.703C315.556 497.049 315.045 497.049 314.905 496.703L314.429 495.509C313.901 494.177 312.828 493.121 311.473 492.602L310.258 492.135C309.906 491.997 309.906 491.495 310.258 491.356L311.473 490.889C312.828 490.37 313.901 489.314 314.429 487.982L314.905 486.788C315.045 486.442 315.556 486.442 315.697 486.788L316.172 487.982C316.7 489.314 317.773 490.37 319.129 490.889Z"
        fill="#FFC50A"
      />
      <path
        d="M268.874 523.824L271.637 524.897C272.447 525.208 272.447 526.35 271.637 526.662L268.874 527.735C265.794 528.929 263.348 531.334 262.133 534.362L261.042 537.079C260.725 537.875 259.564 537.875 259.247 537.079L258.156 534.362C256.941 531.334 254.495 528.929 251.415 527.735L248.651 526.662C247.842 526.35 247.842 525.208 248.651 524.897L251.415 523.824C254.495 522.63 256.941 520.225 258.156 517.196L259.247 514.48C259.564 513.684 260.725 513.684 261.042 514.48L262.133 517.196C263.348 520.225 265.794 522.63 268.874 523.824Z"
        fill="#FFC50A"
      />
      <path
        d="M123.138 61.0719C147.822 61.0719 125.224 106.539 150.456 103.796M270.207 103.796C282.275 133.417 339.697 101.054 336.954 119.704M342.778 246.675C319.192 272.455 358.363 311.139 329.292 311.139M164.169 358.312C158.684 329.24 206.954 347.89 206.954 311.139M227.178 422.002C227.178 463.69 280.456 419.198 280.456 450.464M348.052 514.946C378.41 526.078 336.954 444.112 378.41 444.112"
        stroke="#FFC50A"
        strokeWidth="3.48313"
        strokeLinecap="round"
        strokeDasharray="6.97 6.97"
      />

      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_7762_202563"
            transform="matrix(0.000406717 0 0 0.000244141 -0.0553725 0)"
          />
        </pattern>
        <clipPath id="clip0_7762_202563">
          <rect
            width="116.362"
            height="397.149"
            fill="white"
            transform="translate(730.339 399)"
          />
        </clipPath>
        <clipPath id="clip1_7762_202563">
          <rect
            width="142"
            height="144"
            fill="white"
            transform="translate(775 112)"
          />
        </clipPath>
        <clipPath id="clip2_7762_202563">
          <rect
            width="64.3851"
            height="64.3851"
            fill="white"
            transform="translate(235.072 276.913)"
          />
        </clipPath>
        <clipPath id="clip3_7762_202563">
          <rect
            width="76.4631"
            height="53.207"
            fill="white"
            transform="translate(129.074 396.034)"
          />
        </clipPath>
        <clipPath id="clip4_7762_202563">
          <rect
            width="58.1793"
            height="58.1793"
            fill="white"
            transform="translate(311.933 155.953)"
          />
        </clipPath>
        <clipPath id="clip5_7762_202563">
          <rect
            width="64.3851"
            height="64.3851"
            fill="white"
            transform="translate(178.669 79.0958)"
          />
        </clipPath>
        <clipPath id="clip6_7762_202563">
          <rect
            width="68.0757"
            height="59.3683"
            fill="white"
            transform="translate(32.2595 39.5428)"
          />
        </clipPath>
        <image
          id="image0_7762_202563"
          width="2731"
          height="4096"
          xlinkHref={require('./PlannerImageBox.jpg')}
        />
      </defs>
    </svg>
  );
};

export default PlannerGraphic;
