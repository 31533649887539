import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import XLCTA from '../Shared/XLCTA/XLCTA';
import ImageGallery from './ImageGallery';
import StyledWelcomeHomeGallery from './WelcomeHomeGallery.styled';
import WelcomeHomeHeader from './WelcomeHomeHeader';
import WelcomeHomeStructuredData from './WelcomeHomeStructuredData';

export const WelcomeHomeGallery = ({
  dealer,
  homes,
  isLoading,
  googleReviewUrl,
}) => {
  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <StyledWelcomeHomeGallery>
        <Helmet>
          <title>Welcome Home | {formatDealerName(dealer?.dealerName)}</title>
          <meta
            name="description"
            content={`Learn more about our customer service team at ${formatDealerName(
              dealer?.dealerName
            )}.`}
          />
          <meta
            name="og:description"
            content={`Learn more about our customer service team at ${formatDealerName(
              dealer?.dealerName
            )}.`}
          />
        </Helmet>
        <WelcomeHomeStructuredData dealer={dealer} />
        <WelcomeHomeHeader
          welcomeHomeHeadline="Meet Our Homeowners"
          welcomeHomeContent="Everything we do starts and ends with you. From your first visit to the day you get the keys to your new home we’ll be right there beside you. Welcome home."
          buttonText="LEAVE A REVIEW"
          buttonURL={googleReviewUrl && googleReviewUrl}
          gaClass="ga-homeowners-cta-review-click"
        />
        <ImageGallery dealer={dealer} />
        <XLCTA
          largeText="Find Your Perfect Home"
          dealer={dealer}
          homes={homes}
          retailEventLabelSchedule={
            'homeowners_form_submit_schedule_appointment'
          }
          retailEventLabelInfo={'homeowners_form_submit_request_info'}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-our-homeowners-get-more-information',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-our-homeowners-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </StyledWelcomeHomeGallery>
    </App>
  );
};

WelcomeHomeGallery.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  let googleReviewUrl;
  const { dealer, homes } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });

  googleReviewUrl = dealer?.uris?.find((x) => x.urlTypeId === 5)?.urlValue;

  return { dealer, homes, googleReviewUrl };
};

export default WelcomeHomeGallery;
