import styled from 'styled-components';

export const PositionContainer = styled.div`
   background: #525152;
   height: calc(100vh - 90px);
   position: fixed;
   width: 100%;
   z-index: 8;

   @media (max-width: 760px) {
      height: calc(100vh - 55px);
   }
`;

export const MediaContainer = styled.div`
   height: 100%;
   left: 0;
   position: relative;
   width: 100%;
   z-index: 1;

   @media (max-width: 760px) {
      height: 40%;
   }

   @media (max-width: 345px) {
      height: 35%;
   }
`;