import styled from 'styled-components';
import Colors from '../../colors';

const StyledBrochure = styled.section`
  position: relative;
  max-width: 1056px;
  width: 1056px;
  margin: 0 auto;
  padding: 4rem 20px;

  @media screen and (max-width: 1024px) {
    width: 95%;
    text-align: center;
  }
  .print-button {
    background: ${Colors.primary.claytonBlue.standard};
    color: white;
    border-radius: 2px;
    padding: 17px 17px;
    font: 600 12px/100% 'source-sans-pro', sans-serif;
    margin: 0 auto 35px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    display: inline-flex;
    align-self: center;
    border: none;
    &:hover {
      background: rgb(23, 158, 255);
      cursor: pointer;
    }
  }

  .brochure-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .logo {
      max-width: 250px;
      @media screen and (max-width: 1024px) {
        max-width: 175px;
      }
    }
    .header-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      @media screen and (max-width: 1024px) {
        align-items: flex-start;
        margin-top: 20px;
        text-align: left;
      }
      h1 {
        text-transform: capitalize;
        font-size: 32px;
        margin-bottom: 10px;
        font-family: 'acumin-pro';
        font-weight: 700;
        line-height: 38px;
        @media screen and (max-width: 1024px) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      ul {
        position: relative;
        list-style: none;
        text-align: right;
        @media screen and (max-width: 1024px) {
          text-align: left;
        }
        li {
          font-size: 26px;
          line-height: 1.2;
          font-style: normal;
          font-family: 'acumin-pro';
          color: black;
          font-weight: 400;
          @media screen and (max-width: 1024px) {
            font-size: 24px;
          }
        }
      }
    }
  }
  .brochure-main {
    position: relative;
    .home-images {
      position: relative;
      display: flex;
      margin: 30px -10px 0px;

      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }
      .image-item {
        width: 33.33%;
        padding: 10px;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        .image-box {
          height: 200px;
          overflow: hidden;
          background-size: cover;
          background-position: center center;
        }
      }
    }
    .floorplan-wrap {
      position: relative;
      border: 1px solid black;
      padding: 10px;
      margin: 10px auto;
    }
    .floorplan-image {
      max-width: 100%;
      max-height: 590px;
      margin: 10px auto;
      display: flex;
      @media screen and (max-width: 1024px) {
        height: auto;
        display: block;
      }
    }
  }

  .brochure-footer {
    background: ${Colors.accent.grey5.standard};
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    .footer-left {
      position: relative;
      @media screen and (max-width: 1024px) {
        width: 100%;
        text-align: left;
      }
      .phone {
        text-decoration: none;
        color: inherit;
      }
      .email {
        text-decoration: none;
        color: ${Colors.primary.claytonBlue.standard};
      }
      .weekend-hours {
        display: flex;
        position: relative;
        p {
          margin-right: 10px;
        }
      }
    }
    .footer-right {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        width: 100%;
        text-align: left;
        justify-content: left;
        align-items: flex-start;
        margin-top: 15px;
      }
      h4 {
        margin-bottom: 5px;
        font-weight: 700;
      }
      address {
        font-style: normal;
        font-weight: 400;
        text-align: right;
        @media screen and (max-width: 1024px) {
          text-align: left;
        }
      }
    }
  }
  .footer-info {
    line-height: 1.4;
    font-size: 14px;
    margin-top: 20px;
  }

  /*PRINT ONLY STYLES*/
  @media print {
    margin: 0 auto;
    width: 90%;
    padding: 30px 20px;

    &.chrome {
      width: 100%;
    }
    &.safari {
      width: 80% !important;
      .image-box {
        height: 100px !important;
      }
      .logo {
        max-width: 120px !important;
      }
      .home-images {
        margin-top: 20px !important;
      }
      .header-info {
        h1 {
          font-size: 24px !important;
        }
        ul {
          li {
            font-size: 18px !important;
          }
        }
      }
      .floorplan-image {
        max-height: 275px !important;
      }
      .brochure-footer .footer-right h4 {
        font-size: 18px;
      }
      .brochure-footer .footer-right address {
        font-size: 14px;
      }
      .footer-info {
        font-size: 13px;
      }
    }
    * {
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
      color-adjust: exact !important; /* Firefox 48 – 96 */
      print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
    }
    @page {
      margin: 0;
      size: A4 portrait;
    }
    body {
      margin: 0.5cm;
    }
    html,
    body {
      size: A4 portrait !important;
    }
    .print-button {
      display: none;
    }
    .brochure-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        max-width: 180px;
      }
      .image-box {
        height: 180px !important;
      }
      .header-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        h1 {
          text-transform: capitalize;
          font-size: 28px;
          margin-bottom: 10px;
          font-family: 'acumin-pro';
          font-weight: 700;
          line-height: 34px;
        }
        ul {
          position: relative;
          list-style: none;
          text-align: right;
          li {
            font-size: 24px;
            line-height: 1.2;
            font-style: normal;
            font-family: 'acumin-pro';
            color: black;
            font-weight: 400;
          }
        }
      }
    }
    .brochure-main {
      .floorplan-image {
        max-height: 450px !important;
      }
    }
  }
`;

export default StyledBrochure;
