import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const ReviewStyles = styled.div`
  position: relative;
  padding: 65px 0px 50px;
  @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
    padding: 0px;
  }
`;

export default ReviewStyles;
