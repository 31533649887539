import React from 'react';
import {
   ContentContainerWrapper,
   ContentContainerItem,
   Header,
   Copy,
   Label,
   TouchNav,
   Prev,
   Next
 } from './ContentContainer.styled';

class ContentContainer extends React.Component {
   render() {
      const data = this.props.data;
      if (!data ) return null;

      return (
         <ContentContainerWrapper id="unbuilt-content">
            {data.map((item, index) => {
               if (item.label === "Intro" || item.label === "Outro") {
                  return null;
               } else {
                  return (
                     <ContentContainerItem
                        key={index}
                        active={this.props.activeIndex === index}
                        showVideo={this.props.showVideo}
                        style={this.props.skipDelay ? null : {'--delay': item.textDelay + 'ms'}}
                     >

                        <TouchNav>
                           <Prev onClick={this.props.navPrev}>prev</Prev>
                           <Label>{item.label || ""}</Label>
                           <Next onClick={this.props.navNext}>next</Next>
                        </TouchNav>

                        <Header>
                           <h3 className="blurb-header">{item.label || ""}</h3>
                        </Header>

                        <Copy>
                           <p className="blurb-paragraph">{item.blurb || ""}</p>
                        </Copy>

                     </ContentContainerItem>
                  )
               }
            })}
         </ContentContainerWrapper>
      )
   }
}

export default ContentContainer;
