import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import App from '../../App';
import ChevronDown from '../../images/svg/icons/ChevronDown';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import HeaderWithoutImage from '../Shared/HeaderWithoutImage/HeaderWithoutImage';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import FAQStyles from './FAQ.styled';
import FAQStructuredData from './FAQStructuredData';

const FAQ = ({ dealer, homes, isLoading }) => {
  useEffect(() => {
    if (location?.state?.activeFAQ) {
      let faqId = location.state.activeFAQ;

      if (faqId == 7) {
        setTimeout(() => {
          smoothScroll('#question7', -10);
        }, 200);
        setTimeout(() => {
          setQuestion7(true);
          history.replace({ state: { dealer, homes } });
        }, 1200);
      } else if (faqId == 12) {
        setTimeout(() => {
          smoothScroll('#question12', -10);
        }, 200);
        setTimeout(() => {
          setQuestion12(true);
          history.replace({ state: { dealer, homes } });
        }, 1200);
      } else if (faqId == 13) {
        setTimeout(() => {
          smoothScroll('#question13', -10);
        }, 200);
        setTimeout(() => {
          setQuestion13(true);
          history.replace({ state: { dealer, homes } });
        }, 1200);
      } else {
        history.replace({ state: { dealer, homes } });
      }
    }
  }, []);

  const [question7, setQuestion7] = useState(false);
  const [question12, setQuestion12] = useState(false);
  const [question13, setQuestion13] = useState(false);
  const transitionSpeed = 200;
  const genTrig = (title) => {
    return (
      <>
        <p className="h4">{title}</p>
        <ChevronDown />
      </>
    );
  };

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <FAQStyles>
        <Helmet>
          <title>
            Frequently Asked Questions | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Find answers to your most frequently asked questions about manufactured homes.`}
          />
        </Helmet>
        <FAQStructuredData dealer={dealer} />
        <HeaderWithoutImage largeHeadline="Frequently Asked Questions" />
        <div className="faq-wrapper">
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig('How much do your homes cost?')}
            tabIndex={0}
          >
            <p>
              Pricing varies based on square footage, features and{' '}
              <span
                onClick={() => {
                  smoothScroll('#question12', -10);
                  setTimeout(() => {
                    setQuestion12(true);
                  }, 1000);
                }}
              >
                home type
              </span>
              . The home site and site improvements also affect the total
              package price. Visit our home center to ask a home consultant
              about pricing and see what homes are available within your budget.
            </p>
          </Collapsible>
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig('How does financing work?')}
            tabIndex={0}
          >
            <p>
              Our home centers work with many lenders. At the home center you
              will find a Lender Board with information from national and
              regional lenders that can help you decide which lender best fits
              your needs, or you can choose to use another lender that you may
              already have a relationship with. Choose a lender you are
              comfortable with, and the lender will be able to answer any of
              your financing related questions.
            </p>
          </Collapsible>
          {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
            <>
              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig('Do you accept trade-ins?')}
                tabIndex={0}
              >
                <p>
                  Yes, in many cases we do accept trade-ins.{' '}
                  <Link
                    tabIndex="-1"
                    to={{ pathname: '/contact-us', state: { dealer, homes } }}
                  >
                    Contact us
                  </Link>{' '}
                  for more information about our trade-in requirements and to
                  schedule your free trade evaluation.
                </p>
              </Collapsible>

              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig('What is the value of my current home?')}
                tabIndex={0}
              >
                <p>
                  Home prices and values are driven by many factors. Call us
                  today to schedule a free trade evaluation. You can also get an
                  estimate of what your home’s current value may be by{' '}
                  <a
                    tabIndex="-1"
                    rel="noopener"
                    href="https://www.nadaguides.com/Manufactured-Homes/Value-Reports/Online"
                    target="_blank"
                  >
                    purchasing a report from NADA.
                  </a>{' '}
                  This report either costs $30 or $50, depending on if you order
                  a basic report or a professional report. The values indicated
                  on reports generated by NADA are intended to be used as
                  guidelines only.
                </p>
              </Collapsible>
              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig('Can you help me find land for my new home?')}
                tabIndex={0}
              >
                <p>
                  Each home center is different, but in most cases we can help
                  you work with local real estate agents to find land for your
                  new home.
                </p>
              </Collapsible>
              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig(
                  'I live more than 150 miles away from the home center. Can you service my area?'
                )}
                tabIndex={0}
              >
                <p>
                  In many cases we can work with you to help you find a new home
                  if you are more than 150 miles away from our home center. Give
                  us a call so we can get some more information from you and
                  help you start your home buying journey.
                </p>
              </Collapsible>
            </>
          )}
          {!['NoSpecialOffersNoEnergySmart', 'BuilderModel'].includes(
            dealer?.websiteTemplateDescription
          ) && (
            <Collapsible
              transitionTime={transitionSpeed}
              trigger={genTrig(
                'What energy efficient features do you offer in your homes?'
              )}
              open={question7}
              containerElementProps={{ id: 'question7' }}
              tabIndex={0}
            >
              <p>
                Our EnergySmart Zero™ ready homes include many features designed
                to give you year-round comfort and savings. This includes argon
                gas Low-E windows, a high-performance heat pump water heater and
                a smart thermostat, just to name a few. Check out our{' '}
                <a tabIndex="-1" href="/energysmart" className="underline">
                  EnergySmart Zero page
                </a>{' '}
                to learn more.
              </p>
            </Collapsible>
          )}
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig(
              'Where can I find the floor plans for your homes?'
            )}
            tabIndex={0}
          >
            <p>
              Click{' '}
              <Link
                tabIndex="-1"
                to={{ pathname: '/homes', state: { dealer, homes } }}
              >
                View Homes
              </Link>{' '}
              at the top of the page to see floor plans, pictures and home
              information about the models we offer.
            </p>
          </Collapsible>
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig(
              'What models are available for viewing at your home center? Can I order a home that isn’t at your home center?'
            )}
            tabIndex={0}
          >
            <p>
              You can see homes available for purchase and homes in stock on the{' '}
              <Link
                tabIndex="-1"
                to={{ pathname: '/homes', state: { dealer, homes } }}
              >
                View Homes
              </Link>{' '}
              page of the website. If you see a home on our website that you
              love but is not in stock, we can still order that home for you.
              Stop by the home center or{' '}
              <Link
                tabIndex="-1"
                to={{ pathname: '/contact-us', state: { dealer, homes } }}
              >
                contact us
              </Link>{' '}
              to learn more about this process.
            </p>
          </Collapsible>
          {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
            <>
              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig('Do you have pre-owned homes available?')}
                tabIndex={0}
              >
                <p>
                  We sometimes have pre-owned homes available for purchase at
                  our home centers.{' '}
                  <Link
                    tabIndex="-1"
                    to={{ pathname: '/contact-us', state: { dealer, homes } }}
                  >
                    Contact us
                  </Link>{' '}
                  or stop by to learn more about pre-owned homes we may have for
                  sale.
                </p>
              </Collapsible>
              <Collapsible
                transitionTime={transitionSpeed}
                trigger={genTrig('Can your homes be placed on a basement?')}
                tabIndex={0}
              >
                <p>
                  With the proper home site prep and foundation many of our
                  homes can be placed on a basement. Stop by our home center or
                  give us a call to learn more about homes that can be placed on
                  basement foundations.
                </p>
              </Collapsible>
            </>
          )}
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig(
              'What’s the difference between a manufactured home and a modular home?'
            )}
            open={question12}
            containerElementProps={{ id: 'question12' }}
            tabIndex={0}
          >
            <p>
              Manufactured homes are built entirely inside our
              climate-controlled home building facilities to federal HUD Code
              regulations and come in three sizes: single wide, double wide and
              triple wide. They can be placed on a permanent foundation and some
              models can be placed on a basement, but can be relocated with the
              help of an experienced contractor if not placed on a permanent
              foundation. Modular homes are also built inside climate-controlled
              home building facilities. However, they are built according to all
              state, local and/or regional codes for where the home will be
              located. They are typically built in two sections but can be built
              in up to five sections depending on the size and are constructed
              on a permanent foundation.
            </p>
          </Collapsible>
          {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
            <Collapsible
              transitionTime={transitionSpeed}
              trigger={genTrig(
                'Can I make changes to the floor plan I choose?'
              )}
              open={question13}
              containerElementProps={{ id: 'question13' }}
              tabIndex={0}
            >
              <p>
                Many of our homes can be altered and upgraded to create your
                ideal dream home. Some homes have more customizable and
                upgradable options than others, but with countless floor plans
                available, we are sure we can help you find the perfect floor
                plan to fit your budget and lifestyle. Let us know which floor
                plan you’re interested in, and we’ll let you know what is
                customizable.
              </p>
            </Collapsible>
          )}
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig('How long does it take to get in a new home?')}
            tabIndex={0}
          >
            <p>
              Our home builders complete homes efficiently and in a timely
              manner after your home order is placed, but there are other
              factors that could affect how long it takes to get to move-in day.
              The timeline can be affected by the type of house you are buying
              as larger homes will take longer to build than smaller homes, the
              volume of home orders the building facility has received, home
              inspections, land preparation and more. A home consultant at our
              home center will be able to give you more information on how long
              it will take to get into your new home after your home order is
              placed and will keep you updated on what stage your home is at
              throughout your buying journey.
            </p>
          </Collapsible>
          <Collapsible
            transitionTime={transitionSpeed}
            trigger={genTrig(
              'What’s the down payment amount for a home purchase?'
            )}
            tabIndex={0}
          >
            <p>
              Every home purchase is unique, so the amount of down payment
              required by a lender to finance a home purchase will vary based on
              several different factors. The lender you choose to finance your
              home purchase with will be able to give you specific details about
              your down payment amount.
            </p>
          </Collapsible>
        </div>
      </FAQStyles>
    </App>
  );
};

FAQ.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService).catch(
    (e) => {
      console.error(e);
      return { redirectTo: '/error' };
    }
  );
};

export default FAQ;
