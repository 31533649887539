import styled from 'styled-components';

export const MediaContainer = styled.div`
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
`;

export const Video = styled.video`
   height: 100vh;
   object-fit: cover;
   object-position: center center;
   position: relative;
   left: 0;
   top: 0;
   transition: all 1100ms ease-in-out;
   width: 100%;
   @media (max-width: 760px) {
      height: 35vh;
   }
`;