import styled from 'styled-components';

const NewsEventsPostStyles = styled.div`
  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spacer {
    margin-bottom: 140px;
    @media only screen and (max-width: 775px) {
      margin-bottom: 48px;
    }
  }
`;

export default NewsEventsPostStyles;
