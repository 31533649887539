import styled from 'styled-components';
import Banner from '../../images/promo-images/promo-banner.jpg';
import TabletBanner from '../../images/promo-images/promo-banner-tablet.jpg';
import MobileBanner from '../../images/promo-images/promo-banner-mobile.jpg';
import colors from 'pubweb-smokey/dist/colors';

export const StyledThankYou = styled.div`
  background-color: ${colors.primary.white.standard};
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }

  .offer-text {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (min-width: 320px) {
      text-align: center;
      min-height: 235px;
      background-image: ${`url(${MobileBanner})`};
    }

    @media only screen and (min-width: 768px) {
      background-image: ${`url(${TabletBanner})`};
    }

    @media only screen and (min-width: 1025px) {
      width: 50%;
      min-height: 800px;
      background-image: ${`url(${Banner})`};
      background-size: cover;
    }

    @media only screen and (min-width: 1800px) {
      min-height: 1100px;
    }

    .promo-headline {
      color: ${colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;

      @media only screen and (min-width: 320px) {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.35px;
        text-align: center;
        margin: 0 auto 15px;
        padding-top: 29px;
        max-width: 325px;
        width: 87%;
      }

      @media only screen and (min-width: 768px) {
        max-width: 555px;
        width: 72%;
      }

      @media only screen and (min-width: 1025px) {
        padding-top: 0;
        font-size: 60px;
        line-height: 68px;
        letter-spacing: -0.52px;
        margin: 121px auto 15px;
        width: 90%;
      }

      @media only screen and (min-width: 1400px) {
        width: 77%;
      }

      @media screen and (min-width: 1800px) {
        margin: 273px auto 15px;
      }
    }

    .sub-headline {
      color: ${colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      text-align: center;

      @media only screen and (min-width: 320px) {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: -0.3px;
        margin: 0 auto;
        width: 69%;
        max-width: 258px;
      }

      @media only screen and (min-width: 768px) {
        width: 76%;
        max-width: 512px;
      }

      @media only screen and (min-width: 1025px) {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.35px;
        max-width: none;
        width: 76%;
        max-width: 544px;
      }
    }

    .divider {
      border-top: 2px solid ${colors.primary.white.standard};
      width: 63px;
      margin: 0 auto 15px;
    }
  }

  .message-container {
    width: 100%;
    margin: auto;
    padding: 70px 0;
    text-align: center;

    @media screen and (min-width: 1025px) {
      width: 50%;
      margin: unset;
      padding: 145px 0;
    }

    @media screen and (min-width: 1800px) {
      padding: 273px 0;
    }

    h3 {
      font-size: 25px;
      line-height: 30px;
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      color: ${colors.primary.black.standard};
      letter-spacing: -0.35px;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto 70px;

      @media only screen and (min-width: 768px) {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.52px;
      }
    }

    p {
      font: 400 14px/22px 'source-sans-pro', sans-serif;
      color: ${colors.accent.grey1.standard};
      margin: 16px auto;
      width: 87%;

      @media only screen and (min-width: 768px) {
        width: auto;
      }

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .button {
      font: 600 14px/16px 'source-sans-pro', sans-serif;
      color: ${colors.primary.white.standard};
      text-align: center;
      width: 87%;
      border-radius: 2px;
      background: ${colors.primary.claytonBlue.standard};
      display: block;
      padding: 17px 0;
      margin: 0 auto;
      text-decoration: none;
      text-transform: uppercase;

      @media only screen and (min-width: 768px) {
        width: 469px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;
