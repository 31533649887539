import React from 'react';
import { Helmet } from 'react-helmet';
import { detect } from 'detect-browser';
import { PositionContainer, MediaContainer } from './Unbuilt.styled';
import Wrapper from './Wrapper';
import CtaButton from './CtaButton';
import ProgressNav from './ProgressNav';
import Curtain from './Curtain';
import Intro from './Intro';
import Outro from './Outro';
import VideoControllerFull from './VideoControllerFull';
import VideoControllerSplit from './VideoControllerSplit';
import VideoControllerStatic from './VideoControllerStatic';
import ContentContainer from './ContentContainer';
import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';

import data from './Unbuilt.json';
import App from '../../App';

import { parseInitialPropsContext } from '../../utils';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';

let touchStart = null;
let touchCount = 0;
let navTarget = null;
let videos = null;
let browser = null;

class Unbuilt extends React.Component {
  static async getInitialProps(ctx) {
    const data = await parseInitialPropsContext(
      ctx,
      dealerService,
      homesService
    ).catch((e) => {
      console.error(e);
      return { redirectTo: '/error' };
    });
    return { homes: data.homes, dealer: data.dealer };
  }

  constructor() {
    super();

    this.state = {
      activeIndex: 0,
      playing: false, // primarily used to prevent scroll pooling during nav events
      showCurtain: false,
    };

    this.navClick = this.navClick.bind(this);
    this.navPrev = this.navPrev.bind(this);
    this.navNext = this.navNext.bind(this);
    this.hideCurtain = this.hideCurtain.bind(this);
    this.videoEnded = this.videoEnded.bind(this);
    this.reset = this.reset.bind(this);
  }

  onWheel(e) {
    // removed the scroll count check to not count to 5 so that the user doesn't have to spin the mouse wheel several times to go to the next slide

    if (e.deltaY > 0 && this.state.activeIndex < data.length - 1) {
      this.navNext();
    } else if (e.deltaY < 0 && this.state.activeIndex > 0) {
      this.navPrev();
    }
  }

  onKeyDown(e) {
    const { activeIndex } = this.state;

    if (e.keyCode === 40 && activeIndex < data.length - 1) {
      this.navNext();
    }

    if (e.keyCode === 38 && activeIndex > 0) {
      this.navPrev();
    }
  }

  onTouchStart(e) {
    touchStart = e.touches[0].clientY;
  }

  onTouchMove(e) {
    touchCount++;
    if (touchCount < 5) return;
    touchCount = 0;

    if (
      e.touches[0].clientY < touchStart &&
      this.state.activeIndex < data.length - 1
    ) {
      this.navNext();
    } else if (
      e.touches[0].clientY > touchStart &&
      this.state.activeIndex > 0
    ) {
      this.navPrev();
    }
  }

  navClick(item, index) {
    navTarget = index;

    this.setState({
      showCurtain: true,
      playing: true,
      showVideo: false,
    });

    window.dataLayer.push({
      page: 'unbuilt',
      event: 'nav-click',
      index: index,
    });

    const curtain = document.getElementById('unbuilt-curtain');
    curtain.addEventListener('transitionend', this.hideCurtain);
  }

  navPrev() {
    navTarget = this.state.activeIndex - 1;

    this.setState({
      showCurtain: true,
      playing: true,
      showVideo: false,
    });

    window.dataLayer.push({
      page: 'unbuilt',
      event: 'nav-prev',
      index: navTarget,
    });

    const curtain = document.getElementById('unbuilt-curtain');
    curtain.addEventListener('transitionend', this.hideCurtain);
  }

  navNext() {
    this.setState({
      activeIndex: this.state.activeIndex + 1,
      showCurtain: false,
      playing: true,
      showVideo: true,
    });

    window.dataLayer.push({
      page: 'unbuilt',
      event: 'nav-next',
      index: this.state.activeIndex + 1,
    });
  }

  hideCurtain(event) {
    const curtain = document.getElementById('unbuilt-curtain');
    curtain.removeEventListener('transitionend', this.hideCurtain);

    setTimeout(() => {
      this.setState({
        activeIndex: navTarget,
        showCurtain: false,
        playing: false,
        showVideo: false,
      });

      // rewinds videos after any secondary nav performed
      for (const video in videos) {
        if (videos.hasOwnProperty(video)) {
          videos[video].load();
        }
      }

      navTarget = null;
    }, event.elapsedTime * 1000);
  }

  videoEnded() {
    setTimeout(() => {
      this.setState({
        playing: false,
        showVideo: false,
      });
    }, 500); // extra buffer to prevent accidental double scroll around short videos
  }

  reset() {
    window.dataLayer.push({
      page: 'unbuilt',
      event: 'button-click',
      label: 'restart',
    });

    // safari has playback issues after being reset, forcing refresh results in better overall experience
    if (
      (browser && browser.name === 'safari') ||
      (browser && browser.name === 'ios')
    ) {
      window.location.reload();
      return;
    } else {
      this.setState({
        activeIndex: 0,
        playing: false,
        showCurtain: false,
      });
    }

    for (const video in videos) {
      if (videos.hasOwnProperty(video)) {
        videos[video].load();
      }
    }
  }

  componentWillMount() {
    browser = detect();
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    videos = document.getElementsByClassName('unbuilt-video');
    document.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
    document.removeEventListener('keydown', this.onKeyDown.bind(this));
  }

  render() {
    let media;
    let skipDelay = false;

    // originally this checked for chrome ONLY if it's on Android OS - this prevented the video from playing on Chrome for Windows....
    if (
      (browser.name === 'chrome' && browser.os !== 'Mac OS') ||
      browser.name === 'firefox' ||
      browser.name === 'ie' ||
      browser.name === 'edge'
    ) {
      media = (
        <VideoControllerFull
          data={data}
          activeIndex={this.state.activeIndex}
          videoEnded={this.videoEnded}
          showVideo={this.state.showVideo}
        />
      );
    } else if (
      (browser.name === 'chrome' && browser.os === 'Mac OS') ||
      browser.name === 'safari' ||
      browser.name === 'ios'
    ) {
      media = (
        <VideoControllerSplit
          data={data}
          activeIndex={this.state.activeIndex}
          videoEnded={this.videoEnded}
          showVideo={this.state.showVideo}
        />
      );
    } else {
      skipDelay = true;

      media = (
        <VideoControllerStatic
          data={data}
          activeIndex={this.state.activeIndex}
          videoEnded={this.videoEnded}
          showVideo={this.state.showVideo}
        />
      );
    }

    return (
      <App dealer={this.props.dealer} homes={this.props.homes}>
        <Wrapper>
          <Helmet
            meta={[
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no',
              },
              { name: 'referrer', content: 'always' },
              { charset: 'UTF-8' },
              { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
            ]}
          >
            <title>
              Unbuilt | {formatDealerName(this.props.dealer?.dealerName)}
            </title>
          </Helmet>

          <PositionContainer
            id="unbuilt-positioncontainer"
            onWheel={this.state.playing ? undefined : (e) => this.onWheel(e)}
            onTouchStart={
              this.state.playing ? undefined : (e) => this.onTouchStart(e)
            }
            onTouchMove={
              this.state.playing ? undefined : (e) => this.onTouchMove(e)
            }
          >
            <Curtain show={this.state.showCurtain} />

            <CtaButton
              dealer={this.props.dealer}
              homes={this.props.homes}
              pristine={this.state.activeIndex === 1}
              visible={
                this.state.activeIndex > 0 && this.state.activeIndex < 13
                  ? true
                  : false
              }
              onClick={() => {}}
            />

            <ProgressNav
              data={data}
              navClick={this.navClick}
              activeIndex={this.state.activeIndex}
              pristine={this.state.activeIndex === 1}
              visible={
                this.state.activeIndex > 0 && this.state.activeIndex < 13
                  ? true
                  : false
              }
            />

            <MediaContainer id="unbuilt-mediacontainer">{media}</MediaContainer>

            <Intro data={data[0]} visible={this.state.activeIndex === 0} />

            <Outro
              data={data[13]}
              visible={this.state.activeIndex === 13}
              playing={this.state.playing}
              reset={this.reset}
              dealer={this.props.dealer}
              homes={this.props.homes}
            />

            <ContentContainer
              data={data}
              activeIndex={this.state.activeIndex}
              showVideo={this.state.showVideo}
              navPrev={this.navPrev}
              navNext={this.navNext}
              skipDelay={skipDelay}
            />
          </PositionContainer>
        </Wrapper>
      </App>
    );
  }
}

export default Unbuilt;
