import styled from 'styled-components';

const PortalLandingStyles = styled.section`
  position: relative;
  background: #0075c9;
  @media screen and (max-width: 768px) {
    background: white;
  }
  .container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: flex-start;
    padding: 70px 15px 50px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .content {
    position: relative;
    background: white;
    flex: 1 1 500px;
    padding: 50px;
    max-width: 500px;
    min-width: 300px;
    h1 {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.35px;
      color: #0075c9;
      margin-bottom: 10px;
    }
    .divider {
      display: flex;
      background: #0075c9;
      height: 1px;
      width: 100%;
      margin: 12px auto 20px;
    }
    h2 {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;
      color: #0075c9;
    }
    p {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
      color: #4e5255;
      margin-top: 10px;
    }
  }
  .button-wrap {
    display: flex;
    gap: 15px;
  }
  .cta-button {
    background: #0075c9;
    color: white;
    border-radius: 2px;
    padding: 17px 27px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    cursor: pointer;
    color: white !important;
    &:hover {
      background: rgb(23, 158, 255);
    }
  }
  .cta-button-outline {
    background: white;
    color: #0075c9;
    border: 1px solid #0075c9;
    border-radius: 2px;
    padding: 17px 27px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 30px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-flex;
    cursor: pointer;
  }
  .graphic {
    flex: 1 1 auto;
    margin: -37px 0px -95px -100px;
    position: relative;
    z-index: 9;
    min-width: 63%;
    pointer-events: none;
    svg {
      width: 100%;
    }
  }
  .mobile-container {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding: 40px 0px;
      margin: 0 auto;
    }
    .mobile-bottom {
      position: relative;
    }
    .mobile-graphic {
      position: relative;
      .mobile-graphic-container {
        max-width: 400px;
        padding: 0px;
        margin: 0 auto;
        svg {
          z-index: 2;
          position: relative;
          max-width: 100%;
          margin-left: -3%;
        }
      }
      .bluebar {
        position: absolute;
        height: 200px;
        width: 100%;
        background: #0075c9;
        top: 68px;
        z-index: 1;
      }
    }
    .mobile-top {
      position: relative;
      max-width: 400px;
      padding: 0px 15px;
      margin: 0 auto;
    }
    .mobile-content {
      position: relative;
      z-index: 4;
      max-width: 330px;
      padding: 0px 15px;
      margin: -330px auto 0px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      p {
        max-width: 65%;
        width: 194px;
        margin-bottom: 20px;
      }
    }
    h1 {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.35px;
      color: #0075c9;
      margin-bottom: 10px;
    }
    h2 {
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;
      color: #0075c9;
    }
  }
`;

export default PortalLandingStyles;
