import styled from 'styled-components';

export const MediaContainer = styled.div`
   background: url(${props => props.bgImg});
   background-size: cover;
   background-position: center center;
   height: 100%;
   left: 0;
   position: absolute;
   top: ${props => props.active ? '0' : '5000px'};
   width: 100%;
`;

export const Video = styled.video`
   display: ${props => props.showVideo ? 'block' : 'none'};
   height: 100%;
   left: 0;
   object-fit: cover;
   object-position: center center;
   position: absolute;
   top: ${props => props.showVideo ? '0' : '5000px'};
   width: 100%;
`;