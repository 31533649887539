import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getNewsAndEventsPost } from '../../Api.js';
import App from '../../App';
import facebookIcon from '../../images/svg/social/facebook.svg';
import instagramIcon from '../../images/svg/social/instagram.svg';
import pinterestIcon from '../../images/svg/social/pinterest.svg';
import twitterIcon from '../../images/svg/social/twitter.svg';
import youtubeIcon from '../../images/svg/social/youtube.svg';
import LongFormCtaBackground from '../../images/webp/long-form-cta-background.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import LongFormCTA from '../Shared/LongFormCTA/LongFormCTA';
import PostDetails from '../Shared/PostDetails/PostDetails';
import XLCTA from '../Shared/XLCTA/XLCTA';
import NewsEventsPostStyles from './NewsEventsPost.styled';

const NewsEventsPost = ({ dealer, homes, postSlug, hostname, postContent }) => {
  const socialLinks = [
    {
      title: 'Facebook',
      icon: facebookIcon,
      link: `https://facebook.com/sharer.php?u=https://${hostname}/news/${postSlug}`,
    },
    {
      title: 'Instagram',
      icon: instagramIcon,
      link: `https://www.instagram.com/clayton/`,
    },
    {
      title: 'Pinterest',
      icon: pinterestIcon,
      link:
        'https://www.pinterest.com/pin/create/button/?url=' +
        encodeURI(`https://${hostname}/news/${postSlug}`) +
        '&media=' +
        encodeURI(postContent?.mainImage) +
        '&description=' +
        encodeURI(postContent?.content),
    },
    {
      title: 'Twitter',
      icon: twitterIcon,
      link: `https://twitter.com/intent/tweet?url=https://${hostname}/news/${postSlug}&text=${postContent?.title}`,
    },
    {
      title: 'Youtube',
      icon: youtubeIcon,
      link: `https://www.youtube.com/user/claytonhomes`,
    },
  ];

  return (
    <App dealer={dealer} homes={homes}>
      <NewsEventsPostStyles>
        {postContent && (
          <>
            <Helmet>
              <title>{formatDealerName(dealer?.dealerName)}</title>
              <meta
                name="description"
                content={`Find out about news and events related to Clayton Homes and Clayton retailers. `}
              />
              <meta property="og:image" content={postContent?.mainImage} />
              <meta
                property="og:secure_image"
                content={postContent?.mainImage}
              />
            </Helmet>

            <PostDetails
              label={
                postContent.homeCenterWebsiteContentType == 1 ? 'Event' : 'News'
              }
              title={postContent.title}
              subtitle={postContent.subtitle}
              summary={postContent.summary}
              content={postContent.content}
              image={postContent.mainImage}
              disclaimer={postContent.disclaimer}
              socialLinks={socialLinks}
              linkText={postContent.linkText}
              linkUrl={postContent.linkUrl}
            />
            {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
              <LongFormCTA
                largeText="We accept trades!"
                smallText="Find a new home you love? Contact us for more information about our trade-in requirements and to schedule your free trade evaluation."
                buttonText="Learn More"
                buttonUrl="/special-offers/trade-in"
                gaClass="ga-news-detail-learn-trades"
                retailEventLabel="news_events_trade"
                backgroundImage={LongFormCtaBackground}
              />
            )}

            <div className="spacer"></div>
            <XLCTA
              largeText="Find Your Perfect Home"
              dealer={dealer}
              homes={homes}
              buttons={[
                {
                  buttonText: 'Get More Information',
                  buttonURL: '/contact-us',
                  gaClass: 'ga-news-detail-get-more-information',
                  openForm: 'requestInfo',
                  retailEventLabel: 'footer_dual_cta_request',
                },
                {
                  buttonText: 'Schedule a Visit',
                  buttonURL: '/contact-us',
                  gaClass: 'ga-news-detail-schedule-a-visit',
                  openForm: 'scheduleVisit',
                  retailEventLabel: 'footer_dual_cta_schedule',
                },
              ]}
            />
          </>
        )}
      </NewsEventsPostStyles>
    </App>
  );
};

NewsEventsPost.getInitialProps = async (ctx) => {
  let postContent;
  let postSlug;

  postSlug = ctx.match?.params?.slug;

  const { dealer, homes, hostname } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  const location = ctx.match.url;
  const path = location?.toLowerCase().split('/')[1];

  try {
    const result = await getNewsAndEventsPost(dealer.dealerId, postSlug);

    if (
      (result.homeCenterWebsiteContentType == 1 && path == 'event') ||
      (result.homeCenterWebsiteContentType == 2 && path == 'news')
    ) {
      postContent = result;
    } else {
      return { redirectTo: '/404' };
    }
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, hostname, postContent, postSlug };
};

export default NewsEventsPost;
