import styled from 'styled-components';

const FAQStyles = styled.section`
  .faq-wrapper {
    position: relative;
    max-width: 970px;
    margin: 0 auto;
    padding: 100px 25px;
    @media only screen and (max-width: 775px) {
      padding: 50px 25px;
      max-width: 561px;
    }
  }
  .Collapsible {
    position: relative;
    margin-bottom: 20px;
    .Collapsible__trigger {
      display: flex;
      width: 100%;
      padding: 30px 20px 15px 0px;
      cursor: pointer;
      color: #25292d;
      border-bottom: 1px solid #a1a3a6;
      justify-content: space-between;
      align-items: center;
      .h4 {
        max-width: 808px;
        font-size: 20px !important;
      }
      svg {
        flex-shrink: 0;
        margin-left: 15px;
        transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
      }
      @media only screen and (max-width: 775px) {
        padding: 15px 0px 15px 0px;
      }
    }
    .is-open {
      svg {
        transform: rotate(180deg);
      }
    }
    .Collapsible__contentInner {
      padding-top: 12px;
      max-width: 808px;
      @media only screen and (max-width: 775px) {
        max-width: 452px;
      }
      .underline {
        text-decoration: underline;
      }
      a,
      span {
        color: #0075c9;
        font-weight: inherit;
        font-size: inherit;
        font-style: normal;
        font-family: inherit;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      em {
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
      }
    }
  }
`;

export default FAQStyles;
