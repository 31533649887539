import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const PostDetailsStyles = styled.section`
  position: relative;
  padding: 80px 0px;
  @media screen and (max-width: 768px) {
    padding: 40px 0px;
  }
  .container {
    position: relative;
    max-width: 858px;
    padding: 0px 25px;
    margin: 0 auto;
  }
  .post-label {
    font-family: 'acumin-pro';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.3px;
    color: #777b7e;
    margin-bottom: 10px;
  }
  .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.35px;
    color: #25292d;
    @media screen and (max-width: 768px) {
      font-size: 22px;
      line-height: 27px;
      letter-spacing: -0.35px;
    }
  }
  .subtitle {
    font-family: 'Source Sans Pro' sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: #25292d;
    margin-bottom: 28px;
  }
  .disclaimer {
    font-size: 12px;
    line-height: 17px;
    margin-top: 60px;
  }
  .image-box {
    position: relative;
    background-color: ${Colors.accent.grey5.standard};
    height: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    span {
      display: flex !important;
    }
    span,
    img {
      width: 100%;
    }
    img {
      object-fit: cover;
    }
  }
  .modal-content {
    position: relative;
    padding: 40px;
    h5 {
      font-family: acumin-pro, sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.28;
      letter-spacing: normal;
      text-align: center;
      color: rgb(65, 64, 66);
      padding-bottom: 25px;
    }
    .close-btn {
      color: rgb(109, 110, 113);
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: -0.3px;
      text-decoration: underline;
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
    }
    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      li {
        display: flex;
        margin: 0 4px;
        a {
          cursor: pointer;
          display: flex;
        }
      }
    }
  }
  .link {
    color: ${Colors.primary.claytonBlue.standard};
    text-decoration: underline;
    font-family: 'source-sans-pro', sans-serif;
    font-weight: 400;
    display: flex;
    margin-top: 20px;
  }
  .article-header {
    position: relative;
    .title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
  }
  .show-modal {
    cursor: pointer;
    margin-left: 20px;
  }
`;

export default PostDetailsStyles;
