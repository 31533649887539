import React from 'react';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import { Link } from 'react-router-dom';

import {
  Wrapper,
  Header,
  ButtonWrapper,
  ResetButton,
  LegalFooter,
} from './Outro.styled';

class Outro extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      return true;
    }

    if (this.props.playing !== nextProps.playing) {
      return true;
    }

    return false;
  }

  render() {
    const data = this.props.data;
    if (!data) return null;

    return (
      <div id="unbuilt-outro">
        <Wrapper visible={this.props.visible} playing={this.props.playing}>
          <Header className="outro-header">{data.header}</Header>
          <ButtonWrapper>
            <div className="button-browse">
              <Link
                className="cta-button"
                to={{
                  pathname: '/homes',
                  state: { dealer: this.props.dealer, homes: this.props.homes },
                }}
              >
                See Homes
              </Link>
            </div>
          </ButtonWrapper>
        </Wrapper>

        <ResetButton
          onClick={this.props.reset}
          visible={this.props.visible}
          playing={this.props.playing}
        >
          Back to Start
        </ResetButton>
        <LegalFooter visible={this.props.visible}>
          <span>
            &copy; 1998-{new Date().getFullYear()} Clayton. All rights reserved.{' '}
          </span>
          <span>
            <a href="/Legal-and-Privacy/">Legal</a>
            <span> | </span>
            <a href="/Legal-and-Privacy/">Privacy</a>
          </span>
        </LegalFooter>
      </div>
    );
  }
}

export default Outro;
