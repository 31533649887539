import React from 'react';
import PropTypes from 'prop-types';
import { List, Dot, Label, ListItem } from './ProgressNav.styled';

class ProgressNav extends React.Component {
  render() {
    const data = this.props.data;
    if (!data) return null;

    return (
      <List id="unbuilt-progressnav" visible={this.props.visible} pristine={this.props.pristine} role="tablist">
        {data.map((item, index) => {

          if (item.label === 'Intro' || item.label === 'Outro') return null;

          return (
            <ListItem
              key={index}
              onClick={() => this.props.navClick(item, index)}
              onFocus={() => this.props.navClick(item, index)}
              role="tab"
              tabIndex="0"
            >
              <Label>{item.labelShort}</Label>
              <Dot active={this.props.activeIndex === index ? true : false} />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

ProgressNav.propTypes = {
  data: PropTypes.array,
  visible: PropTypes.bool,
  pristine: PropTypes.bool,
  navClick: PropTypes.func,
  activeIndex: PropTypes.number
};

export default ProgressNav;
