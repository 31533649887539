import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import App from '../../App';
import ClaytonBuiltImg from '../../images/promo-images/ripley-crossmod-kitchendetails-stainless-steel-sink.jpg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import Banner from './Banner';
import HomeOptions from './HomeOptions';
import PromoStyles from './Promo.styled';
import PromoBenefits from './PromoBenefits';
import PromoContent from './PromoContent';
import PromoThankYou from './PromoThankYou';

const Promo = ({ dealer, homes }) => {
  const [showTy, setShowTy] = useState(false);

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes}>
      <PromoStyles ClaytonBuiltImg={ClaytonBuiltImg}>
        <Helmet>
          <title>
            Trade-in and Trade-up | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            property="og:description"
            content={`Contact ${formatDealerName(
              dealer.dealerName
            )} today. We offer beautifully designed homes with long lasting durability at an incredible value. Come visit us today and find out why we’re one of America’s top home builders.`}
          />
        </Helmet>
        {!showTy && (
          <>
            <Banner
              promoHeadline="Out with old and in with bold!"
              promoSubHeadline="Trade in and trade up to a new Energy Smart&reg; home."
              showThankYouCallBack={showThankYou}
              isClayton={isClayton}
              dealer={dealer}
            />
            <PromoContent dealer={dealer} formatDealerName={formatDealerName} />
            <PromoBenefits
              benefitsHeadline="Benefits to trading in your home when you buy a new Clayton
            Built&reg; home include:"
            />
            <HomeOptions
              headline="What Makes Our Homes So Great?"
              disclaimer="Trademarks of companies other than Clayton are the property of those other companies."
            />
          </>
        )}
        {showTy && <PromoThankYou dealer={dealer} />}
      </PromoStyles>
    </App>
  );
};

Promo.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  const { dealer, homes } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  );

  return { dealer, homes };
};

export default Promo;
