import styled from 'styled-components';

import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from 'pubweb-smokey/dist/colors';
import zIndices, {
  setBelow,
  setSameAs,
} from 'pubweb-smokey/dist/components/zIndex';

let zIndicies = {
  entireComponent: setBelow(zIndices.dropDownMenuModal), // sets the z-index to below what dropDownMenuModal's
  navMenuItemsDesktop: setSameAs(), // sets the z-index to be the same as previous one
  navLogoBurgerMobile: setSameAs(), // sets the z-index to be the same as previous one
  navMenuItemsMobile: setBelow(), // sets the z-index to be below the previous one
};

const openPositionsButtonWidth = '170';

export const HeaderStyles = styled.header`
  background-color: ${Colors.primary.white.standard};
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: ${zIndicies.entireComponent};

  @media screen and (max-width: 1100px) {
    position: sticky;
    top: 0;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  a {
    color: #4e5255;
    &:hover {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }

  button:focus {
    outline: none;
  }

  .logo-desktop {
    display: none;
  }

  .logo-desktop {
    align-items: center;
    img {
      width: 91px;
    }
  }

  .logo-mobile {
    display: flex;
    align-items: center;
    img {
      width: 78px;
    }
  }

  .show-for-mobile {
    display: block;
  }

  .hide-for-mobile {
    display: none;
  }

  .nav-container {
    .sr-only {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

  .nav-main {
    .nav-top {
      background-color: ${Colors.primary.white.standard};
      height: 31px;
      display: none;
      justify-content: flex-end;
      align-items: center;
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #4e5255;
      position: relative;
      z-index: ${zIndicies.navLogoBurgerMobile};
      border: 1px solid #e4e5e7;
      padding-right: 20px;
      .nav-top-ul {
        display: flex;
        list-style: none;
        /* gap: 15px; */
        height: 100%;
        align-items: center;
        justify-content: center;
        li {
          height: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
          a {
            font-family: 'source-sans-pro';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.5px;
            color: #4e5255;
            display: flex;
            align-items: center;
            position: relative;
            padding: 3px 15px;
            height: 100%;
            &:hover {
              background: rgba(0, 117, 201, 0.1);
              color: #4e5255;
            }
            img {
              margin-right: 3px;
            }
          }
          .header-phone {
            font-size: 10.5px;
          }
        }
      }
    }

    .nav-account,
    .nav-top-location,
    .nav-top-faq,
    .nav-phone {
      a {
        border: 0px solid transparent;
        border-radius: 2px;
        &:hover {
          background: rgba(0, 117, 201, 0.1);
          color: #4e5255;
        }
      }
    }

    .nav-logo-burger {
      background-color: ${Colors.primary.white.standard};
      border-bottom: 1px solid #e4e5e7;
      display: flex;
      flex-wrap: wrap;
      height: 58px;
      justify-content: space-between;
      position: relative;
      z-index: ${zIndicies.navLogoBurgerMobile};

      .logo {
        display: flex;
        flex-wrap: wrap;
        height: 25px;
        justify-content: space-between;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
      }
      .nav-burger {
        background-color: transparent;
        border: none;
        cursor: pointer;
        justify-content: center;
        width: 50px;
        height: 100%;
        align-items: center;

        @media screen and (max-width: 1099px) {
          display: flex;
        }

        img {
          height: 40px;
          width: 40px;
        }
      }

      .nav-right {
        display: flex;
        align-items: center;

        & > div a {
          font-family: source-sans-pro;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.5px;
          color: #4e5255;
        }

        .nav-phone {
          border-right: 1px solid #e4e5e7;
          height: 100%;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 1099px) {
            display: none;
          }
          a {
            padding: 14px 12px;
            margin-right: 20px;
            background: ${Colors.primary.claytonBlue.standard};
            color: white;
            border-radius: 2px;
            font: 500 12px/100% 'source-sans-pro', sans-serif;
            letter-spacing: 1px;
            text-decoration: none;
            transition: 0.2s;
            text-transform: uppercase;
            font-weight: 700;
            display: inline-flex;
            align-self: center;
            box-shadow: none;
            border: 0px solid transparent;
            cursor: pointer;
            flex-shrink: 0;
            /* min-height: 50px; */
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              background: rgb(23, 158, 255);
            }
          }
          /* a.active {
            color: ${Colors.primary.claytonBlue.standard};
          } */
        }
        .nav-location {
          border-right: 1px solid #e4e5e7;
          font-family: source-sans-pro;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #4e5255;
          @media screen and (max-width: 1099px) {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
          }
          img {
            vertical-align: bottom;
          }
        }
      }
    }

    .nav-menu-items {
      background-color: ${Colors.primary.white.standard};
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
      margin: 0 auto;
      padding: 0px;
      position: absolute;
      transition: margin 600ms ease;
      z-index: ${zIndicies.navMenuItemsMobile};
      display: flex;
      width: 100%;
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      @media screen and (max-width: 1099px) {
        width: 227px;
        height: 100vh;
        right: 0;
      }

      .nav-menu-items-inside {
        background-color: transparent;
        position: relative;

        .divider {
          width: 100%;
          height: 0.5px;
          background: #cacccf;
          display: flex;
        }

        @media screen and (max-width: 1099px) {
          padding: 12px 22px 100px;
          width: 100%;
          background-color: white;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
        }

        .nav-bottom-mobile {
          .mobile-phone-wrap a {
            display: flex;
            align-items: center;
          }
          .phone-icon {
            height: 16px;
            width: 16px;
          }
          @media screen and (max-width: 1099px) {
            border-top: 0.5px solid #cacccf;
            margin-top: 10px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;

            .mobile-phone {
              position: relative;
              display: flex;
              font-family: 'source-sans-pro';
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 30px;
              color: #4e5255;
            }
            .mobile-contact-btn {
              background: ${Colors.primary.claytonBlue.standard};
              color: white;
              border: 1.5px solid ${Colors.primary.claytonBlue.standard};
              border-radius: 2px;
              padding: 10px;
              font: 500 12px/100% 'source-sans-pro', sans-serif;
              margin-top: 10px;
              letter-spacing: 1px;
              text-decoration: none;
              transition: 0.2s;
              text-transform: uppercase;
              font-weight: 700;
              display: inline-flex;
              align-self: center;
              width: 100%;
              justify-content: center;
              &:hover {
                background: rgb(23, 158, 255);
                border: 1.5px solid rgb(23, 158, 255);
              }
            }
          }
        }
      }

      &.closed {
        @media screen and (max-width: 1099px) {
          transform: translateX(227px);
        }
      }

      &.open {
        @media screen and (max-width: 1099px) {
          transform: translateX(0);
        }
      }

      ul {
        list-style-type: none;

        p {
          font-size: 14px;
          color: #a1a3a6;
          text-transform: uppercase;
          font-weight: 600;
          @media screen and (min-width: 1100px) {
            display: none;
          }
        }

        li {
          margin-bottom: 12px;

          a,
          span {
            font-family: source-sans-pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #25292d;
            padding: 6px 8px;
            border-radius: 8px;
            margin-left: -8px;
            cursor: pointer;
            &:hover {
              background: rgba(0, 117, 201, 0.1);
              color: #4e5255;
            }

            /* &:hover {
              color: ${Colors.primary.claytonBlue.standard};
            } */
          }
          /* a.active {
            @media screen and (max-width: 1099px) {
              background: rgba(0, 117, 201, 0.1);
            }
          } */
        }
      }
      .main-menu-links.logged-in {
        @media screen and (min-width: 1100px) {
          margin-left: 10px;
        }
        @media screen and (max-width: 1099px) {
          margin-top: 7px;
          li {
            margin-bottom: 5px;
            a,
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #4e5255;
            }
            a.active {
              color: ${Colors.primary.claytonBlue.standard};
            }
          }
        }
      }
      .account-menu-links {
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        margin-top: 7px;
        a.active {
          color: ${Colors.primary.claytonBlue.standard};
        }
        ul {
          border: none;
          margin-top: 5px;
          margin-left: 10px;
          li {
            margin-bottom: 5px;
            a {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #4e5255;
            }
            a.active {
              color: ${Colors.primary.claytonBlue.standard};
            }
          }
        }
        @media screen and (min-width: 1100px) {
          display: none;
        }
      }
    }
  }

  .menu-dark-overlay {
    display: block;
    background: transparent;
    position: fixed;
    top: 0;
    right: 0;
    height: 0px;
    width: 0px;
    transition: background 0.6s;
    @media screen and (min-width: 1100px) {
      display: none !important;
    }
  }

  .menu-dark-overlay.activated {
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
  }

  .mobile-account-menu {
    .mobile-menu-account-name {
      display: flex;
      margin-bottom: 3px;
      font-family: 'source-sans-pro';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      padding: 6px 8px;
      border-radius: 8px;
      margin-left: -8px;
      cursor: pointer;
      color: ${Colors.primary.claytonBlue.standard};
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        flex: 0 1 22px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .mobile-login-logout {
      display: none !important;
    }
    .mobile-account-menu {
      display: none;
    }
    .nav-main {
      .nav-logo-burger {
        border-bottom: none;

        .logo-desktop {
          margin: 0 0 0 27px;
        }
      }

      .nav-menu-items {
        &.closed {
          margin-top: 0;
        }
      }
    }

    .nav-container {
      margin: 0 auto;
      /* max-width: 1400px; */
      position: relative;
    }

    .show-for-mobile {
      display: none;
    }

    .hide-for-mobile {
      display: block;
    }

    .logo-desktop {
      display: flex;
    }

    .logo-mobile,
    .nav-burger {
      display: none;
    }

    .nav-main {
      .nav-top {
        display: flex;
      }

      .nav-logo-burger {
        border-bottom: none;

        .nav-right {
          & > div a {
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
          }
        }

        .logo-desktop {
          margin: 0 0 0 27px;
        }
      }

      .nav-menu-items {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        padding: 0 !important;
        position: absolute;
        left: 153px;
        top: 47px;
        width: 595px;
        z-index: ${zIndicies.navMenuItemsDesktop};

        /* @media only screen and (min-width: 1380px) {
          width: 710px;
        } */

        .nav-menu-items-inside {
          margin: 0;
          padding-left: 0 !important;
          width: 100%;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          border-bottom: none !important;

          li {
            margin: 0 0px 0 0;

            .superscript {
              font-size: 8px;
              font-weight: 700;
              vertical-align: top;
              position: absolute;
              top: -4px;
            }

            a {
              color: ${Colors.accent.grey1.standard};
              font-family: source-sans-pro;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 27px;
              color: #4e5255;
              padding: 9px 9px;
              margin-right: 4px;
              transition: background-color 0.3s;
              border: 0px solid transparent;
              border-radius: 2px;
              &:hover {
                background: rgba(0, 117, 201, 0.1);
                color: #4e5255;
              }
              @media only screen and (min-width: 1024px) {
                font-size: 16px;
              }
            }
            a.active {
              color: ${Colors.primary.claytonBlue.standard};
            }
          }
        }

        .open-positions-link {
          position: absolute;
          top: 3px;
          right: 10px;

          button {
            width: ${openPositionsButtonWidth}px;
          }
        }
      }
    }

    .nav-top {
      span {
        cursor: pointer !important;
        display: flex;
        align-items: center;
      }
    }

    .nav-account {
      min-width: 100px;
      text-align: center;
      padding: 10px !important;
      cursor: pointer;
      span,
      a {
        font-family: 'source-sans-pro';
        font-weight: normal;
      }
      span {
        color: #4e5255;
      }
    }

    .nav-account.logged-in {
      min-width: 170px;
    }
    .nav-account.logged-out {
      span {
        padding: 10px;
        &:hover {
          background: rgba(0, 117, 201, 0.1);
          color: #4e5255;
        }
      }
    }
  }

  .dropdown-account-menu {
    position: absolute;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 4px 4px;
    transform: translateY(-100%);
    opacity: 0;
    transition: 0.2s ease-in-out;

    @media screen and (max-width: 1099px) {
      display: none !important;
    }

    ul {
      list-style: none;
      position: relative;
      padding: 10px 0px;
      li {
        padding: 2px 5px;
        position: relative;
        width: 100%;
        display: flex;

        a.active {
          color: ${Colors.primary.claytonBlue.standard};
        }

        a,
        span {
          font-family: 'source-sans-pro';
          font-weight: normal;
          font-size: 16px;
          line-height: 27px;
          width: 100%;
          padding: 12px 20px;
          transition: background-color 0.3s;
          border: 0px solid transparent;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background: rgba(0, 117, 201, 0.1);
            color: #4e5255;
          }
          @media screen and (max-width: 1099px) {
            display: none !important;
          }
        }

        /* a.active {
          background: rgba(0, 117, 201, 0.1);
        } */
      }
    }
  }
  .dropdown-account-menu.activated {
    transform: translateY(0);
    opacity: 1;
  }
  .menu-account-name {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transition: transform 0.2s;
    }
    p {
      margin-right: 6px;
      color: ${Colors.primary.claytonBlue.standard};
      user-select: none;
      max-width: 165px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .menu-account-name.activated {
    img {
      transform: rotate(-180deg);
    }
  }

  a.active {
    color: ${Colors.primary.claytonBlue.standard};
  }

  .mobile-nav-link-box {
    position: relative;
    margin-top: 50px;
    min-height: 271px;
    background-size: cover;
    background-position: 50%;
    display: flex;
    justify-content: center;
    padding: 25px 12px;
    border: 0px solid transparent;
    border-radius: 4px;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.275991px;
      color: #ffffff;
      margin-bottom: 8px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #ffffff;
    }
    .container {
      position: absolute;
      bottom: 20px;
    }
    span {
      border: 1.5px solid white;
      border-radius: 2px;
      padding: 8px 10px;
      box-sizing: border-box;
      font: 700 11px/100% 'source-sans-pro', sans-serif;
      display: flex;
      justify-content: center;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
      margin: 15px 5px 0px;
      background: ${Colors.primary.white.standard};
      color: #038acc;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      width: 147px;
    }
  }
  .my-favorites {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .outside-portal {
    margin-left: 15px;
  }
`;
