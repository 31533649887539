import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { contactFormSubmit } from '../../Api';
import { getUTMParametersFromSessionCache } from '../../components/Shared/SmokeyTransitional/utils/utils';
import Loader from '../../images/svg/loader';
import { fireSegmentEvent, formatPhoneNumber } from '../../utils';
import TextField from '../Shared/SmokeyTransitional/components/TextField/TextField';
import { FormContainer } from './Form.Style';
import { scrollIntoViewHelper } from '../../utils/utils';

const Form = ({ isClayton, showThankYouCallBack, dealer }) => {
  let history = useHistory();

  const [firstName, setFirstName] = useState({ value: '', error: null });
  const [lastName, setLastName] = useState({ value: '', error: null });
  const [phone, setPhone] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [zip, setZip] = useState({ value: '', error: null });
  const [isChecked, setIsChecked] = useState({ value: false, error: null });
  const [dealerNumber, setDealerNumber] = useState('');
  const [gaClientId, setGaClientId] = useState('');
  const [canEmail, setCanEmail] = useState(true);
  const [doNotCall, setDoNotCall] = useState(false);
  const [originWebURL, setOriginWebURL] = useState(
    typeof window !== 'undefined' && window?.location?.href
  );
  const [originWebApp, setOriginWebApp] = useState('clayton-retail-website');
  const [originWebPageType, setOriginWebPageType] = useState(
    'retail-trade-landing-page'
  );
  const [originWebFormType, setOriginWebFormType] = useState('lead-form');
  const [originCampaign, setOriginCampaign] = useState('retail-trade');
  const [originWebFormCategory, setOriginWebFormCategory] = useState(
    isClayton() ? 'clayton' : `fob-${dealer?.brandAcronym}`
  );
  const [dataExtension, setDataExtension] = useState('FormActivityRetailPromo');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  useEffect(() => {
    getGAClientId();

    if (dealer) {
      setDealerNumber(dealer.dealerNumber);
    }
  });

  const getGAClientId = () => {
    try {
      const tracker = ga.getAll()[0];
      let clientId = tracker.get('clientId');
      setGaClientId(clientId);
    } catch (e) {
      return null;
    }
  };

  const firstNameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (!firstName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastNameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const zipIsValid = () => {
    let isValid = true;
    if (!zip.value) {
      isValid = false;
      setZip({ ...zip, error: '*Please enter your zip code.' });
    } else if (!zip.value.match(/^\d{5}$/)) {
      isValid = false;
      setZip({
        ...zip,
        error: '*Please enter a valid zip code.',
      });
    } else {
      setZip({ ...zip, error: '' });
    }
    return isValid;
  };

  const checkedIsValid = () => {
    let isValid = true;

    if (!isChecked.value) {
      isValid = false;
      setIsChecked({
        ...isChecked,
        error: '*You must agree to the terms to receive information.',
      });
    } else {
      setIsChecked({ ...isChecked, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorFields = {};

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstNameIsValid() ? true : !(errorFields.firstName = true);

    formIsValid &= lastNameIsValid() ? true : !(errorFields.lastName = true);

    formIsValid &= emailIsValid() ? true : !(errorFields.email = true);

    formIsValid &= phoneIsValid() ? true : !(errorFields.phone = true);

    formIsValid &= zipIsValid() ? true : !(errorFields.zip = true);

    //uncomment if there is a checkbox
    // formIsValid &= checkedIsValid() ? true : !(errorFields.checked = true);

    return [formIsValid, errorFields];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const [formIsValid, errorFields] = validateForm();

    if (!formIsValid) {
      scrollIntoViewHelper(errorFields);
      return;
    }

    let formData = {
      firstname: firstName.value,
      lastname: lastName.value,
      emailAddress: email.value,
      zip: zip.value,
      phoneNumber: phone.value,
      dealerNumber: dealerNumber,
      utmSource: utmParams.utm_source || '',
      utmCampaign: utmParams.utm_campaign || '',
      utmTerm: utmParams.utm_term || '',
      utmContent: utmParams.utm_content || '',
      utmMedium: utmParams.utm_medium || '',
      kenshooId: utmParams.kenshooid || '',
      gaClientId: gaClientId,
      canEmail: canEmail,
      doNotCall: doNotCall,
      originWebURL: originWebURL,
      originWebApp: originWebApp,
      originWebPageType: originWebPageType,
      originWebFormType: originWebFormType,
      originWebFormCategory: originWebFormCategory,
      originCampaign: originCampaign,
      dataExtension: dataExtension,
      googleClickId: utmParams.gclid || '',
      bingClickId: utmParams.msclkid || '',
      facebookClickId: utmParams.fbclid || '',
    };

    setIsSubmitting(true);

    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      history.push('/special-offers/trade-in/thank-you');
      setShowThankYou(true);
      setIsSubmitting(false);
      showThankYouCallBack(showThankYou);
      if (dataLayer) {
        dataLayer.push({ event: 'ga-trade-form-submit' });
      }
      fireSegmentEvent('Generic Contact Form Submitted', {
        formId: 'trade_form_submit',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        comments: '',
        eventContext: 'trade_form_submit',
      });
    }
  };

  return (
    <FormContainer>
      <div className="form-headline">
        <h3>Start your free trade evaluation process now!*</h3>
        <p>Complete this form to receive more information.</p>
      </div>
      <form onSubmit={handleSubmit} method="post" noValidate>
        <div className="group">
          <TextField
            id="firstName"
            className="half-width-input"
            textFieldStyle="primary-background"
            label="First name"
            tabIndex="1"
            placeholder="John"
            value={firstName.value}
            errorMessage={firstName.error}
            required={true}
            onChange={(event) => {
              setFirstName({ value: event.target.value, error: null });
            }}
            onBlur={firstNameIsValid}
            maxLength="40"
          />
          <TextField
            id="lastName"
            className="half-width-input"
            textFieldStyle="primary-background"
            label="Last name"
            tabIndex="2"
            placeholder="Smith"
            value={lastName.value}
            errorMessage={lastName.error}
            required={true}
            onChange={(event) => {
              setLastName({ value: event.target.value, error: null });
            }}
            onBlur={lastNameIsValid}
            maxLength="50"
          />
        </div>
        <div className="group">
          <TextField
            id="email"
            className="full-width-input"
            textFieldStyle="primary-background"
            label="Email"
            tabIndex="3"
            placeholder="john@company.com"
            value={email.value}
            errorMessage={email.error}
            required={true}
            onChange={(event) => {
              setEmail({ value: event.target.value, error: null });
            }}
            onBlur={emailIsValid}
            maxLength="80"
          />
        </div>
        <div className="group">
          <TextField
            id="phone"
            className="half-width-input"
            textFieldStyle="primary-background"
            label="Phone"
            tabIndex="4"
            placeholder="john@company.com"
            value={formatPhoneNumber(phone.value)}
            errorMessage={phone.error}
            required={true}
            onChange={(event) => {
              setPhone({ value: event.target.value, error: null });
            }}
            onBlur={phoneIsValid}
            minLength="10"
            maxLength="10"
          />
          <TextField
            id="zip"
            className="half-width-input"
            textFieldStyle="primary-background"
            label="Zip Code"
            tabIndex="5"
            placeholder="37804"
            value={zip.value}
            errorMessage={zip.error}
            required={true}
            onChange={(event) => {
              setZip({ value: event.target.value, error: null });
            }}
            onBlur={zipIsValid}
            minLength="5"
            maxLength="5"
          />
        </div>
        <Button
          text={isSubmitting ? <Loader /> : 'Request Info'}
          onClick={isSubmitting ? () => {} : handleSubmit}
          disabled={isSubmitting ? isSubmitting : ''}
          className="submit-btn"
          tabIndex="6"
          automationId="formBtn"
        />
        <p className="agree-terms">
          By submitting my information, I agree that Clayton Homes may contact
          me by telephone, provide me with marketing communications about
          Clayton Homes products and services, and share my contact information
          as described in the{' '}
          <a href="https://www.claytonhomes.com/privacy/" target="_blank">
            Website Terms
          </a>
          .
        </p>
      </form>
    </FormContainer>
  );
};

export default Form;
