import React from 'react';
import { ButtonContainer } from './CtaButton.styled.js';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import { Link } from 'react-router-dom';

class CtaButton extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.visible !== nextProps.visible ||
      this.props.pristine !== nextProps.pristine
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <ButtonContainer
        visible={this.props.visible}
        pristine={this.props.pristine}
      >
        <Link
          className="cta-button"
          to={{
            pathname: '/homes',
            state: { dealer: this.props.dealer, homes: this.props.homes },
          }}
        >
          See Homes
        </Link>
      </ButtonContainer>
    );
  }
}

export default CtaButton;
