import React from 'react';
import { StyledBanner } from './Banner.style';
import Form from './Form';

const Banner = ({
  promoHeadline,
  promoSubHeadline,
  showThankYouCallBack,
  isClayton,
  dealer,
}) => {
  return (
    <StyledBanner>
      <div className="offer-text">
        <h1 className="promo-headline">{promoHeadline}</h1>
        <div className="divider"></div>
        <h2 className="sub-headline">{promoSubHeadline}</h2>
      </div>
      <Form
        showThankYouCallBack={showThankYouCallBack}
        isClayton={isClayton}
        dealer={dealer}
      />
    </StyledBanner>
  );
};

export default Banner;
