import React from 'react';
import WrapperStyles from './Wrapper.styled.js';

class Wrapper extends React.Component {
   render() {
      return (
         <WrapperStyles className="wrapper">
            {this.props.children}
         </WrapperStyles>
      )
   }
}

export default Wrapper;
