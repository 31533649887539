import styled from 'styled-components';

export const CurtainEl = styled.div`
   background: #333;
   height: 100%;
   left: 0;
   opacity: ${props => props.show ? '1' : '0'};
   pointer-events: none;
   position: absolute;
   top: 0;
   transition: 500ms ease-in-out;
   width: 100%;
   z-index: 4;

   @media (max-width: 760px) {
      background: #fff;
   }
`;