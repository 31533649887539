import React from 'react';
import { StyledPromoBenefits } from './PromoBenefits.style';

const PromoBenefits = ({ benefitsHeadline }) => {
  return (
    <StyledPromoBenefits>
      <div className="promo-benefits-container">
        <div className="promo-benefits-content">
          <h2 className="promo-benefits-headline">{benefitsHeadline}</h2>
          <ul className="promo-benefits-list">
            <li>A free trade-in evaluation* from one of our home centers</li>
            <li>
              You may not have to worry about what to do with your old home
            </li>
            <li>
              The trade-in value may be able to go toward your deposit on your
              new home
            </li>
            <li>
              Potential to get year round savings and a lifetime of comfort in a
              new Energy Smart&reg; home
            </li>
          </ul>
          <p className="promo-benefits-disclaimer">
            *Value of current manufactured home trade in toward purchase of a
            new manufactured home is determined by resale values in your area
            and final evaluation determination by home center manager. Deposit
            funds in addition to trade in may be required.
          </p>
        </div>
        <div className="promo-benefits-img-container"></div>
      </div>
    </StyledPromoBenefits>
  );
};

export default PromoBenefits;
