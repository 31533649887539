import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import patternLeft from '../../../images/svg/xl-cta-pattern-left.svg';
import patternRight from '../../../images/svg/xl-cta-pattern-right.svg';
import patternLeftDark from '../../../images/svg/xl-cta-pattern-left-dark.svg';
import patternRightDark from '../../../images/svg/xl-cta-pattern-right-dark.svg';
import { fireSegmentEvent } from '../../../utils';
import RequestInfoModalForm from '../../Shared/RequestInfoModalForm/RequestInfoModalForm';
import ScheduleVisitModalForm from '../../Shared/ScheduleVistModalForm/ScheduleVisitModalForm';
import Modal from '../../Shared/SmokeyTransitional/components/Modal/Modal';
import { formatPhoneNumberFromNumber } from '../SmokeyTransitional/utils/formatters';
import XLCTAStyles from './XLCTA.styled';

export const XLCTA = ({
  largeText,
  smallText,
  homeTitle,
  dealerInfo,
  buttons,
  phoneNumber,
  gaPhoneClass,
  disclaimer,
  dealer,
  homes,
  home,
  customCallText,
  icon,
  logo,
  portalForms,
  updatedUser,
  retailEventLabel,
  retailEventLabelSchedule,
  retailEventLabelInfo,
  retailEventLabelPhone,
  hasDarkBackground,
}) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  return (
    <XLCTAStyles>
      <div className={hasDarkBackground ? 'xl-cta dark-background' : 'xl-cta'}>
        <div className={disclaimer ? 'content has-disclaimer' : 'content'}>
          {phoneNumber && (
            <a
              href={'tel:' + phoneNumber}
              className={
                'large-text phone-text ' + (gaPhoneClass ? gaPhoneClass : '')
              }
            >
              <span className="call-text">
                {customCallText ? customCallText : 'Call Us Today'}
              </span>{' '}
              <span className="number">
                {formatPhoneNumberFromNumber(phoneNumber)}
              </span>
            </a>
          )}
          {!phoneNumber && customCallText && (
            <span className="call-text">
              {customCallText ? customCallText : 'Call Us Today'}
            </span>
          )}
          {icon && !phoneNumber && (
            <img src={icon} alt="icon phone" className="icon" loading="lazy" />
          )}
          {logo && !phoneNumber && !icon && (
            <img src={logo} alt="phone logo" className="logo" loading="lazy" />
          )}
          {largeText && !phoneNumber && (
            <p className="large-text">{largeText}</p>
          )}
          {smallText && !phoneNumber && (
            <p className="small-text">{smallText}</p>
          )}
          {buttons && (
            <div className="buttons">
              {buttons.map((button) => {
                return (
                  <React.Fragment key={button.buttonText}>
                    {button.buttonURL && (
                      <Link
                        to={{
                          pathname: button.buttonURL,
                          state: {
                            dealer,
                            homes,
                            contactForm: button.openForm
                              ? button.openForm
                              : null,
                          },
                        }}
                        className={
                          'cta-button ' +
                          (button.gaClass ? button.gaClass : '') +
                          (button.textStyle ? button.textStyle : '')
                        }
                        onClick={() => {
                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: button.buttonText ? button.buttonText : '',
                            eventContext: button.retailEventLabel
                              ? button.retailEventLabel
                              : '',
                          });
                        }}
                      >
                        {button.buttonText}
                      </Link>
                    )}
                    {button.buttonModal && button.buttonModal == 'information' && (
                      <button
                        onClick={() => {
                          setShowRequestModal(!showRequestModal);

                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: 'Get More Information',
                            eventContext: portalForms
                              ? 'get_more_information_portal'
                              : button.retailEventLabel
                              ? button.retailEventLabel
                              : '',
                          });
                        }}
                        className={
                          'cta-button ' +
                          (button.gaClass ? button.gaClass : '') +
                          (hasDarkBackground ? 'dark-text' : '')
                        }
                      >
                        Get More Information
                      </button>
                    )}
                    {button.buttonModal && button.buttonModal == 'schedule' && (
                      <button
                        onClick={() => {
                          setShowScheduleModal(!showScheduleModal);

                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: button.buttonText
                              ? button.buttonText
                              : 'Schedule a Tour',
                            eventContext: portalForms
                              ? 'schedule_a_visit_portal'
                              : button.retailEventLabel
                              ? button.retailEventLabel
                              : '',
                          });
                        }}
                        className={
                          'cta-button ' + (button.gaClass ? button.gaClass : '')
                        }
                      >
                        {button.buttonText
                          ? button.buttonText
                          : 'Schedule a Tour'}
                      </button>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {disclaimer && <p className="xlcta-disclaimer">{disclaimer}</p>}
        </div>
        <img
          className="pattern-left"
          src={hasDarkBackground ? patternLeftDark : patternLeft}
          alt="cta pattern graphic"
          loading="lazy"
        />
        <img
          className="pattern-right"
          src={hasDarkBackground ? patternRightDark : patternRight}
          alt="cta pattern graphic"
          loading="lazy"
        />
      </div>

      {showRequestModal && (
        <Modal
          onClose={() => {
            setShowRequestModal(false);
          }}
        >
          <RequestInfoModalForm
            title={homeTitle || 'Get More Information'}
            closeFunction={() => {
              setShowRequestModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            dealer={dealerInfo}
            home={home || null}
            isPortalForm={portalForms}
            updatedUser={updatedUser}
            retailEventLabel={retailEventLabelInfo}
          />
        </Modal>
      )}

      {showScheduleModal && (
        <Modal
          onClose={() => {
            setShowScheduleModal(false);
          }}
        >
          <ScheduleVisitModalForm
            title={homeTitle || 'Schedule a Visit'}
            closeFunction={() => {
              setShowScheduleModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            dealer={dealerInfo}
            home={home || null}
            isPortalForm={portalForms}
            updatedUser={updatedUser}
            retailEventLabelPhone={retailEventLabelPhone}
            retailEventLabel={retailEventLabelSchedule}
          />
        </Modal>
      )}
    </XLCTAStyles>
  );
};

XLCTA.props = {
  largeText: PropTypes.string,
  homeTitle: PropTypes.string,
  dealerInfo: PropTypes.object,
  phoneNumber: PropTypes.string,
  disclaimer: PropTypes.string,
  gaPhoneClass: PropTypes.string,
  disclaimer: PropTypes.string,
  customCallText: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonURL: PropTypes.string,
      buttonModal: PropTypes.string,
      gaClass: PropTypes.string,
      retailEventLabel: PropTypes.string,
    })
  ),
  portalForms: PropTypes.bool,
};

export default XLCTA;
