import React from 'react';
import PropTypes from 'prop-types';
import { faqStructuredData } from '../../utils/structuredData';

const FAQStructuredData = ({ dealer }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(faqStructuredData(dealer)),
      }}
    ></script>
  );
};

export default FAQStructuredData;

FAQStructuredData.propTypes = {
  dealer: PropTypes.shape({
    firtVanityUrl: PropTypes.string,
  }).isRequired,
};
