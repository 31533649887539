import styled from 'styled-components';
import colors from 'pubweb-smokey/dist/colors';
import ClaytonBuiltImg from '../../images/webp/ripley-crossmod-kitchendetails-stainless-steel-sink.webp';

export const StyledPromoBenefits = styled.div`
  background: ${colors.secondary.grey6.standard};
  padding: 80px 25px;
  box-sizing: border-box;
  @media only screen and (min-width: 768px) {
    padding-right: 128px;
    padding-left: 128px;
  }

  @media only screen and (min-width: 1025px) {
    padding-right: 108px;
    padding-left: 108px;
  }

  .promo-benefits-container {
    max-width: 512px;
    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
      max-width: 1224px;
      margin: 0 auto;
    }
  }

  .promo-benefits-content {
    margin-bottom: 72px;
    @media only screen and (min-width: 768px) {
    }

    @media only screen and (min-width: 1025px) {
      width: 42%;
      margin-bottom: 0;
      margin-top: 140px;
    }

    .promo-benefits-headline {
      color: ${colors.primary.black.standard};
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      font-size: 26px;
      line-height: 31px;
      letter-spacing: -0.35px;
      margin-bottom: 56px;

      @media only screen and (min-width: 768px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .promo-benefits-list {
      list-style: none;
      li {
        color: ${colors.accent.grey1.standard};
        font-family: 'source-sans-pro', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 20px;
        padding-left: 12px;
      }
      li::marker {
        color: #00b0ac;
        content: '•';
      }
    }

    .promo-benefits-disclaimer {
      color: ${colors.accent.grey1.standard};
      font-family: 'source-sans-pro', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      margin-top: 7px;
    }
  }

  .promo-benefits-img-container {
    background-image: url(${ClaytonBuiltImg});
    background-size: cover;
    max-width: 512px;
    height: 434px;

    @media only screen and (min-width: 1025px) {
      height: 683px;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 496px;
      height: 661px;
      width: 34%;
    }
  }
`;
