import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import LearnStyles from './Learn.styled';

const Learn = () => {
  return (
    <App>
      <LearnStyles>
        <Helmet>
          <title>TODO: New title here</title>
          <meta name='description' content={`TODO: New Description here.`} />
        </Helmet>

        <h1>Learn</h1>
      </LearnStyles>
    </App>
  );
};

export default Learn;
