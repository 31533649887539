import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';

export const Wrapper = styled.div`
  box-sizing: border-box;
  font-family: 'acumin-pro', sans-serif;
  left: 0;
  margin: 0 auto;
  max-width: 643px;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  position: absolute;
  right: 0;
  text-align: center;
  transition: ${(props) =>
    props.playing ? 'all 500ms 3200ms ease-in-out' : 'all 500ms ease-in-out'};
  top: 10%;
  width: 100%;
  z-index: 3;

  @media (max-width: 760px) {
    background: #fff;
    color: #000;
    bottom: 0;
    height: 60%;
    max-width: none;
    padding: 20px;
    top: auto;
  }

  @media (max-width: 345px) {
    height: 65%;
  }
`;

export const Header = styled.h3`
  &.outro-header {
    box-sizing: border-box;
    color: #fff;
    font-size: 61px;
    font-weight: 700 !important;
    letter-spacing: -1px;
    margin: 0;
    white-space: pre-wrap;
    width: 100%;

    @media (max-width: 760px) {
      color: #1277c6;
      font-size: 40px;
      line-height: 42px;
      text-align: center;
    }

    @media (max-width: 345px) {
      font-size: 30px;
      line-height: 30px;
    }
  }
`;

export const ButtonWrapper = styled.div`
   display: flex;
   justify-content: center;
   padding-top: 50px;
   width: 100%;

    .cta-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 17px 30px;
      background-color: ${Colors.primary.claytonBlue.standard};
      color: white;
      text-decoration: none;
      border: 0px solid transparent;
      border-radius: 2px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font: 700 14px/100% 'source-sans-pro', sans-serif;
      margin-top: 0px;
      transition: 0.2s;
      &:hover {
        background: #179eff;
      }
    }

   .button-browse {
      margin-right: 20px;
   }
   .button-visit {
      a.clayton-blue.inverse {
         background-color: #fff !important;
         border: none;

         &:hover {
            background-color: #e5f5ff !important;
         }
      }

   }

   @media (max-width: 760px) {
      align-items: center;
      flex-direction: column;
      padding-top: 15px;

      .button-browse {
         margin-right: 0;
      }
      .button-visit {
         a.clayton-blue.inverse {
            border: solid 1px #1277C6 !important;
         }
      }
      .button-browse,
      .button-visit {
         width: 80%;

         a {
            display: block;
            padding: 10px 15px;
            width: 100%:
         }
      }
   }
`;

export const ResetButton = styled.button`
  appearance: none;
  background: none;
  bottom: 40px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-family: 'acumin-pro', sans-serif;
  font-size: 14px;
  left: 50%;
  letter-spacing: 1px;
  margin-left: -75px;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  position: absolute;
  text-decoration: none !important;
  text-transform: uppercase;
  transition: ${(props) =>
    props.playing ? 'all 800ms 3200ms ease-in-out' : 'all 800ms ease-in-out'};
  width: 150px;
  z-index: 4;

  :hover {
    text-decoration: underline;
  }

  @media (max-height: 650px) and (min-width: 760px) {
    text-shadow: 1px 1px 1px #333;
  }

  @media (max-width: 760px) {
    bottom: 40px;
    color: #1277c6;
    text-decoration: underline;
  }
  @media (max-width: 340px) {
    bottom: 60px;
  }
`;

export const LegalFooter = styled.div`
  bottom: 0px;
  color: #ffffff;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  padding-bottom: 12px;
  position: absolute;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  text-shadow: 1px 1px #000000;
  transition: opacity 800ms 3200ms ease-in-out;
  width: 100%;
  z-index: 3;

  span:first-child {
    margin-right: 10px;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #ffffff;
    text-decoration: none !important;
  }

  @media (max-width: 760px) {
    align-items: center;
    color: #9b9b9b;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    text-shadow: none;

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #9b9b9b;
    }
  }
  @media (max-width: 340px) {
    span {
      font-size: 13px;
      text-align: center;

      .bar2 {
        display: none;
      }
      .ccpa {
        display: block;
      }
    }
  }
`;
