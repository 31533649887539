import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';

export const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: ${(props) =>
    props.pristine ? 'all 500ms ease-in-out 3390ms' : 'all 500ms ease-in-out'};
  .cta-button {
    border: 1.5px solid white;
    border-radius: 2px;
    padding: 10px 15px;
    font: 500 12px/100% 'source-sans-pro', sans-serif;
    margin-top: 9px;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.2s;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    margin: 10px 5px 0px;
    background: ${Colors.primary.white.standard};
    color: ${Colors.primary.claytonBlue.standard};
    &:hover {
      opacity: 0.9;
    }
  }
`;
