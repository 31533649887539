import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const LegalPrivacyStyles = styled.section`
  position: relative;
  overflow: hidden;
  .image-box {
    height: 340px;
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-bottom: 20px;
    span,
    img {
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
    }
  }
  .container {
    position: relative;
    max-width: 1050px;
    margin: 0 auto;
    padding: 40px 22px;
    .underline {
      text-decoration: underline;
    }
    address {
      font-family: 'source-sans-pro', sans-serif;
      font-style: normal;
      color: #4e5255;
    }
    a {
      color: ${Colors.primary.claytonBlue.standard};
      font-family: 'source-sans-pro', sans-serif;
      word-break: break-word;
    }
    ul {
      list-style-position: inside;
      position: relative;
    }
    h1 {
      color: ${Colors.primary.claytonBlue.standard};
      margin-bottom: 50px;
    }
    h3 {
      color: #3b3b3b;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .italic-underline {
      text-decoration: underline;
      font-style: italic;
      font-weight: normal;
      color: #3b3b3b;
    }
    p,
    ul {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: ${variables.desktop_breakpoint}px) {
    .image-box {
      height: 220px !important;
    }
  }
`;

export default LegalPrivacyStyles;
