import React from 'react';
import { fireSegmentEvent } from '../../utils';
import StyledWelcomeHomeHeader from './WelcomeHomeHeader.styled';

export const WelcomeHomeHeader = ({
  welcomeHomeHeadline,
  smallHeadline,
  welcomeHomeContent,
  buttonText,
  buttonURL,
  gaClass,
}) => {
  return (
    <StyledWelcomeHomeHeader>
      <h1 className="weclome-home-headline">{welcomeHomeHeadline}</h1>
      <p className="welcome-home-content">{welcomeHomeContent}</p>
      <a
        href={buttonURL}
        className={'welcome-home-cta' + (gaClass ? ' ' + gaClass : '')}
        target="_blank"
        onClick={() => {
          fireSegmentEvent('CTA Clicked', {
            eventContext: 'homeowners_hero_review',
            text: buttonText ? buttonText : '',
            type: 'button',
            isNav: false,
          });
        }}
      >
        {buttonText}
      </a>
    </StyledWelcomeHomeHeader>
  );
};

export default WelcomeHomeHeader;
