import styled from 'styled-components';

import caretRight from '../images/right-caret-blue.svg';
import caretLeft from '../images/left-caret-blue.svg';

export const ContentContainerWrapper = styled.div`
  bottom: 0;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 938px;
  height: 155px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 2;

  @media (max-width: 760px) {
    background: #fff;
    bottom: 0;
    height: 60%;
    left: 0;
  }

  @media (max-width: 345px) {
    height: 65%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  p {
    line-height: 1.4;
  }
`;

export const ContentContainerItem = styled.div`
  box-sizing: border-box;
  font-family: 'acumin-pro', sans-serif;
  height: 100%;
  left: 0;
  opacity: ${(props) => (props.active ? '1' : '0')};
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  padding: 20px;
  position: absolute;
  top: 0;
  transition: ${(props) =>
    props.active && props.showVideo
      ? 'all 500ms ease-in-out var(--delay)'
      : 'all 500ms ease-in-out'};
  width: 100%;

  @media (max-width: 760px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Header = styled.div`
  box-sizing: border-box;
  float: left;
  width: 26%;

  .blurb-header {
    color: #fff;
  }

  @media (max-width: 760px) {
    display: none;
  }
`;

export const Copy = styled.div`
  box-sizing: border-box;
  float: right;
  padding: 0 0 0 70px;
  width: 74%;

  .blurb-paragraph {
    color: #fff;
    font-weight: 600;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  }

  @media (max-width: 760px) {
    float: none;
    padding: 10px 20px 0 20px;
    width: 100%;

    .blurb-paragraph {
      color: #4e5255;
      font-weight: 400;
      text-shadow: none;
    }
  }
`;

export const Label = styled.h4`
  color: ${(props) => (props.textColor ? props.textColor : '#fff')};
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 760px) {
    color: #1277c6;
    display: ${(props) => (props.desktopOnly ? 'none' : 'block')};
    font-size: 13px;
  }
`;

export const TouchNav = styled.div`
  align-items: center;
  display: none;
  height: 40px;
  margin-bottom: 15px;

  @media (max-width: 760px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Prev = styled.button`
  appearance: none;
  background-image: url(${caretLeft});
  background-position: center center;
  background-repeat: no-repeat;
  background-position: auto 15px;
  border: 0;
  height: 40px;
  outline: none;
  overflow: hidden;
  text-indent: -1000em;
  transition: none;
  width: 40px;
`;

export const Next = styled(Prev)`
  transform: rotate(180deg);
`;
