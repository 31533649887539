import styled from 'styled-components';
import WelcomeHomeBanner from '../../images/welcome-home-gallery/welcome-home-top-banner.jpg';
import WelcomeHomeBannerTablet from '../../images/welcome-home-gallery/welcome-home-top-banner-tablet.jpg';
import WelcomeHomeBannerMobile from '../../images/welcome-home-gallery/welcome-home-top-banner-mobile.jpg';
import Colors from 'pubweb-smokey/dist/colors.js';

const StyledWelcomeHomeHeader = styled.div`
  background: url(${WelcomeHomeBannerMobile}) no-repeat top center;
  background-size: cover !important;
  height: 449px;
  box-sizing: border-box;
  padding: 99px 0 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 94px;

  @media screen and (min-width: 768px) {
    background: url(${WelcomeHomeBannerTablet}) no-repeat top center;
    height: 368px;
  }

  @media screen and (min-width: 1025px) {
    background: url(${WelcomeHomeBanner}) no-repeat top center;
    height: 449px;
    padding: 99px 0 71px;
  }

  .small-headline {
    text-align: center;
    font-family: source-sans-pro;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.5px;
    color: ${Colors.primary.white.standard};
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .weclome-home-headline {
    text-align: center;
    font-family: source-sans-pro;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.36;
    letter-spacing: -0.35px;
    color: ${Colors.primary.white.standard};

    @media screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 1.19;
    }
  }

  .welcome-home-content {
    text-align: center;
    font-family: source-sans-pro;
    max-width: 67%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.69;
    color: ${Colors.primary.white.standard};
    margin: 13px 0 25px;

    @media screen and (min-width: 768px) {
      max-width: 496px;
    }

    @media screen and (min-width: 1025px) {
      max-width: 546px;
    }
  }

  .welcome-home-cta {
    text-align: center;
    font-family: source-sans-pro;
    width: 202px;
    font-size: 14px;
    font-weight: 600;
    padding: 18px 0;
    box-sizing: border-box;
    display: block;
    margin: 0;
    line-height: 1;
    letter-spacing: 1;
    color: ${Colors.primary.white.standard};
    text-transform: uppercase;
    border-radius: 2px;
    background-color: ${Colors.primary.claytonBlue.standard};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export default StyledWelcomeHomeHeader;
