import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BrochureStyles from './Brochure.styled';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import {
  formatNumberWithCommas,
  formatPhoneNumberFromNumber,
} from '../Shared/SmokeyTransitional/utils/formatters';
import { parseInitialPropsContext } from '../../utils';

const Brochure = ({ dealer, home, url }) => {
  const [printClass, setPrintClass] = useState('');
  useEffect(() => {
    if (document) {
      document.body.classList.add('brouchure-template');
    }
  });

  useEffect(() => {
    setPrintClass(whichBrowser());
  }, [printClass]);

  const print = () => {
    if (document) {
      document.title = `${home.name.toLowerCase()}-${
        home.isAvailableFloorPlan ? home.modelNumber : home.serialNumber
      }`;
    }
    typeof window !== 'undefined' && window.print();
  };

  const whichBrowser = () => {
    if (isFirefox()) {
      return 'firefox';
    } else if (isEdge()) {
      return 'edge';
    } else if (isChrome()) {
      return 'chrome';
    } else if (isSafari()) {
      return 'safari';
    } else {
      return '';
    }
  };

  const agentHas = (keyword) => {
    return (
      typeof navigator !== 'undefined' &&
      navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1
    );
  };

  const isSafari = () => {
    return (
      ((typeof window !== 'undefined' && !!window.ApplePaySetupFeature) ||
        (typeof window !== 'undefined' && !!window.safari)) &&
      agentHas('Safari') &&
      !agentHas('Chrome') &&
      !agentHas('CriOS')
    );
  };

  const isChrome = () => {
    return (
      agentHas('CriOS') ||
      agentHas('Chrome') ||
      (typeof window !== 'undefined' && !!window?.chrome)
    );
  };

  const isFirefox = () => {
    return agentHas('Firefox') || agentHas('FxiOS') || agentHas('Focus');
  };

  const isEdge = () => {
    return agentHas('Edg');
  };

  return (
    <App dealer={dealer}>
      <BrochureStyles className={printClass}>
        <button className="print-button" onClick={print}>
          Print Brochure
        </button>
        <div className="brochure-header">
          {dealer && dealer.logoReference && (
            <img
              className="logo"
              src={dealer.logoReference}
              alt={dealer.dealerName}
            />
          )}
          <div className="header-info">
            {home && home.name && <h1 className="h2">{home.name}</h1>}
            <ul>
              <li>
                {home && (
                  <>
                    {home.serialNumber ? home.serialNumber : home.modelNumber}
                  </>
                )}
              </li>
              <li>
                {home && home.beds && (
                  <>
                    {home.beds > 1 ? home.beds + ' beds' : home.beds + ' bed'}
                  </>
                )}
                {home && home.baths && (
                  <>
                    &nbsp;&bull;&nbsp;
                    {home.baths > 1
                      ? home.baths + ' baths'
                      : home.baths + ' bath'}
                  </>
                )}
              </li>
              <li>
                {home && home.squareFeet && (
                  <>
                    {home.squareFeet
                      ? formatNumberWithCommas(home.squareFeet) + ' sq. ft.'
                      : null}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="brochure-main">
          {home && home.images && (
            <>
              <div className="home-images">
                {home.images.slice(0, 3).map((image, i) => {
                  return (
                    <>
                      <div className="image-item">
                        <div
                          className="image-box"
                          role="img"
                          aria-label={image.caption}
                          style={
                            image && {
                              backgroundImage: `url(${image.reference})`,
                            }
                          }
                        ></div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
          {home && home.floorPlanImage && (
            <div className="floorplan-wrap">
              <img
                className="floorplan-image"
                src={home.floorPlanImage.reference}
                alt={
                  home.floorPlanImage.caption
                    ? home.floorPlanImage.caption
                    : home.name + 'floorplan image'
                }
              />
            </div>
          )}
        </div>
        <footer className="brochure-footer">
          <div className="footer-left">
            {dealer && dealer.phoneNumber && (
              <a className="phone" href={'tel:' + dealer.phoneNumber}>
                {formatPhoneNumberFromNumber(dealer.phoneNumber)}
              </a>
            )}
            {dealer &&
              dealer.weekdayHours &&
              dealer.weekdayHours == dealer.fridayHours && (
                <p>{'Monday - Friday: ' + dealer.weekdayHours}</p>
              )}
            {dealer &&
              dealer.fridayHours &&
              dealer.weekdayHours != dealer.fridayHours && (
                <>
                  <p>{'Monday - Thursday: ' + dealer.weekdayHours}</p>
                  <p>{'Friday: ' + dealer.fridayHours}</p>
                </>
              )}
            <div className="weekend-hours">
              {dealer && dealer.saturdayHours && (
                <p>{'Saturday: ' + dealer.saturdayHours}</p>
              )}
              {dealer && dealer.sundayHours && (
                <p>{'Sunday: ' + dealer.sundayHours}</p>
              )}
            </div>

            {dealer && dealer.firstVanityUrl && (
              <a className="email" href={url}>
                {url}
              </a>
            )}
          </div>
          <div className="footer-right">
            {dealer && dealer.dealerName && <h4>{dealer.dealerName}</h4>}
            {dealer && (
              <address>
                {dealer.address1 ? dealer.address1 : ''}
                {dealer.address2 ? dealer.address2 : ''}
                <br />
                {dealer.city
                  ? dealer.city +
                    (dealer.stateProvince ? ', ' + dealer.stateProvince : '')
                  : ''}
                {dealer.postalCode ? ' ' + dealer.postalCode : ''}
              </address>
            )}
          </div>
        </footer>
        <p className="footer-info">
          Our home building faciliites invest in continuous product and process
          improvements. Plans, dimensions, features, materials, specifications,
          and availability are subject to change without notice or obligation.
          Renderings and floor plans are representative likenesses of our homes
          and many differ from actual homes. We invite you to tour a Home Center
          near you and inspect the highest value in quality housing available or
          call {formatPhoneNumberFromNumber(dealer.phoneNumber)} to speak with a
          Home Consultant. ©{new Date().getFullYear()}, CMH. All rights
          reserved.
        </p>
      </BrochureStyles>
    </App>
  );
};

Brochure.propTypes = {
  dealer: PropTypes.shape({
    /* required dealer properties */
  }).isRequired,
  home: PropTypes.shape({
    /* required home properties */
  }).isRequired,
};

Brochure.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  let home;
  let homeSerialNumber = match.params.serialNumber;
  let homeModelNumber = match.params.modelNumber;

  const { dealer, homes, hostname } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  );

  home = homes.filter(
    (home) =>
      home.modelNumber === homeModelNumber ||
      home.serialNumber === homeSerialNumber
  )[0];
  if (!home) {
    return { redirectTo: '/404' };
  }
  return { dealer, home, hostname };
};

export default Brochure;
