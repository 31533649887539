import React from 'react';
import { MediaContainer, Video } from './VideoControllerFull.styled';

let video = null;

class VideoControllerFull extends React.Component {
   constructor() {
      super();

      this.state = {

      }

      this.timeUpdate = this.timeUpdate.bind(this);
   }

   timeUpdate() {
      const currentTime = video.currentTime;

      if (currentTime >= this.props.data[this.props.activeIndex].time) {
         video.pause();
         this.props.videoEnded();
      }
   }

   componentDidMount() {
      video = document.getElementById('unbuilt-video');
      video.addEventListener("timeupdate", this.timeUpdate);
   }

   shouldComponentUpdate(nextProps, nextState) {
      if (this.props.activeIndex !== nextProps.activeIndex) {
         return true;
      }

      return false;
   }

   componentDidUpdate(prevProps, prevState, snapshot) {

      // changed this as it played the video every time you navigated forward - which caused problems when you're skipping sections
      // skipping a section caused the video to play all the way to the section you skipped to rather than just "jumping" there
      // this basically only plays the video if you moved forward one slide
      if (this.props.activeIndex - prevProps.activeIndex === 1) {
         video.play();
      } else {
         video.currentTime = this.props.data[this.props.activeIndex].time;
      }
   }

   render() {
      return (
         <MediaContainer>
            <Video
               id="unbuilt-video"
               src={'https://www.claytonhomes.com/videos/dreamtoreality/unbuilt/animation-cutdown_03_1.mp4'}
               loop={false}
               autoPlay={false}
               controls={false}
               muted={true}
               playsinline={true}
            />
         </MediaContainer>
      )
   }
}

export default VideoControllerFull;
