import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PostDetailsStyles from './PostDetails.styled';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Share from '../../../images/svg/icons/Share';
import Modal from '../../Shared/SmokeyTransitional/components/Modal/Modal';
import { removeTags } from '../../../utils';

export const PostDetails = ({
  label,
  title,
  subtitle,
  summary,
  content,
  image,
  disclaimer,
  socialLinks,
  linkText,
  linkUrl,
}) => {
  const [ModalShowing, setModalShowing] = useState(false);

  return (
    <PostDetailsStyles>
      <div className="container">
        <div className="article-header">
          {label && <span className="post-label">{label}</span>}
          <div className="title-wrap">
            {title && <h1 className="title">{title}</h1>}
            <div
              className="show-modal"
              tabIndex="1"
              onClick={() => setModalShowing(true)}
              onKeyPress={() => setModalShowing(true)}
            >
              <Share />
            </div>
          </div>
        </div>

        {image && (
          <div className="image-box">
            {linkUrl && (
              <a className="link" href={linkUrl} target="_blank">
                <img
                  alt={`${title} image`}
                  src={image + `?fm=webp&width=750`}
                />
              </a>
            )}
            {!linkUrl && (
              <img alt={`${title} image`} src={image + `?fm=webp&width=750`} />
            )}
          </div>
        )}
        {summary && (
          <p className="subtitle">
            {summary} {subtitle ? ' ' + subtitle : ''}
          </p>
        )}
        {content && (
          <p
            className="content"
            dangerouslySetInnerHTML={{
              __html: removeTags(content),
            }}
          />
        )}
        {linkText && linkUrl && (
          <a className="link" href={linkUrl} target="_blank">
            {linkText}
          </a>
        )}
        {disclaimer && <p className="disclaimer">{disclaimer}</p>}
      </div>
      {ModalShowing && (
        <Modal tabIndex="2" onClose={() => setModalShowing(false)}>
          <h5>Share</h5>
          {socialLinks && (
            <ul>
              {socialLinks.map((item, i) => (
                <li key={i}>
                  {item.title == 'Pinterest' ? (
                    <a
                      tabIndex={i + 1}
                      onClick={(ev) => {
                        ev.preventDefault();
                        window.open(
                          item.link,
                          'popUpWindow',
                          'height=700,width=800,left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
                        );
                      }}
                      data-pin-do="buttonPin"
                      data-pin-count="above"
                      data-pin-custom="true"
                      data-pin-tall="true"
                    >
                      <img
                        src={item.icon}
                        alt={item.title + ' icon'}
                        loading="lazy"
                      />
                    </a>
                  ) : (
                    <a
                      tabIndex={i + 1}
                      href={item.link}
                      rel="noopener"
                      target="_blank"
                    >
                      <img
                        src={item.icon}
                        alt={item.title + ' icon'}
                        loading="lazy"
                      />
                    </a>
                  )}
                </li>
              ))}
            </ul>
          )}
          <p
            tabIndex="6"
            onClick={() => setModalShowing(false)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setModalShowing(false);
              }
            }}
            className="close-btn"
          >
            Cancel
          </p>

          <script
            type="text/javascript"
            async
            defer
            src="//assets.pinterest.com/js/pinit.js"
          ></script>
        </Modal>
      )}
    </PostDetailsStyles>
  );
};

PostDetails.props = {
  label: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  summary: PropTypes.string,
  content: PropTypes.string,
  disclaimer: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  socialLinks: PropTypes.array,
  image: PropTypes.string,
};

export default PostDetails;
