import React from 'react';
import { MediaContainer, Video } from './VideoControllerSplit.styled';

class VideoControllerSplit extends React.Component {
   shouldComponentUpdate(nextProps) {
      if (this.props.activeIndex !== nextProps.activeIndex) {
         return true;
      }

      if (this.props.showVideo !== nextProps.showVideo) {
         return true;
      }

      return false;
   }

   render() {
      return (
         <div>
            {this.props.data.map((item, index) => {
               return (
                  <MediaContainer
                     key={index}
                     active={this.props.activeIndex === index ? true : false}
                     bgImg={require(`../images/${item.imgName}`)}
                  >
                     {item.vidName &&
                        <Video
                           className="unbuilt-video"
                           onEnded={this.props.videoEnded}
                           showVideo={this.props.showVideo}
                           src={`https://www.claytonhomes.com/videos/dreamtoreality/unbuilt/${item.vidName}`}
                           autoPlay
                           controls={false}
                           muted
                           playsInline
                        />
                     }
                  </MediaContainer>
               )
            })}
         </div>
      )
   }
}

export default VideoControllerSplit;
