import React, { useState } from 'react';
import StyledImageGallery from './ImageGallery.styled';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import useWindowSize from '../../hooks/useWindowSize';

const ImageGallery = ({ dealer }) => {
  const [initialImages, setInitialImages] = useState(12);

  const { welcomeHomeImages } = dealer;

  const galleryImages = welcomeHomeImages?.filter(
    (image) => image.isShownInGallery
  );

  const [width, height] = useWindowSize();
  const isTabletOnly = width <= 1024 && width >= 500;

  let reviewPlacementNumber;
  let visibleImagesCount = galleryImages && galleryImages.length;
  if (visibleImagesCount === 8) {
    reviewPlacementNumber = 7;
  } else if (isTabletOnly) {
    reviewPlacementNumber = 9;
  } else {
    reviewPlacementNumber = 8;
  }

  const loadMorePhotos = () =>
    setInitialImages(isTabletOnly ? initialImages + 8 : initialImages + 9);

  return (
    <StyledImageGallery>
      {galleryImages &&
        galleryImages.slice(0, initialImages).map((item, i) => (
          <React.Fragment
            key={
              item.imageReference
                ? item.imageReference.trim()
                : 'gallery-image' + i
            }
          >
            <div className="img-container" key={i}>
              <img
                src={item.imageReference}
                alt={`${item.firstName} ${
                  item.lastName.substr(0, 1) + '.'
                } welcome home image`}
              />
              <div className="customer-name">
                {`${item.firstName} ${item.lastName.substr(0, 1) + '.'}`}
              </div>
            </div>
          </React.Fragment>
        ))}
      <div className="button-container">
        <Button
          className="center-horizontal button"
          text="SHOW MORE PHOTOS"
          onClick={() => loadMorePhotos()}
          disabled={galleryImages.length <= initialImages}
          automationId="galleryBtn"
        />
      </div>
    </StyledImageGallery>
  );
};

export default ImageGallery;
