import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Shared/SmokeyTransitional/components/TextField/TextField';
import TextArea from '../../Shared/SmokeyTransitional/components/TextArea/TextArea';
import CheckBox from '../../Shared/SmokeyTransitional/components/CheckBox/CheckBox';
import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import SiteFeedbackFormStyles from './SiteFeedbackForm.styled';
import SiteFeedbackThankYou from '../SiteFeedbackThankYou/SiteFeedbackThankYou';
import { siteFeedbackFormSubmit } from '../../../Api';
import { formatPhoneNumber } from '../../../utils';
import { fireMNTNConversionPixel } from '../../../utils/utils';
import Loader from '../../../images/svg/loader';
import { scrollIntoViewHelper } from '../../../utils/utils';

export const SiteFeedbackForm = ({ ty, dealer, showThankYouCallBack }) => {
  const [topics, setTopics] = useState([]);
  const [topicError, setTopicError] = useState(false);
  const [fullName, setFullName] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [comments, setComments] = useState({ value: '', error: null });
  const [phone, setPhone] = useState({ value: '', error: null });
  const [showThankYou, setShowThankYou] = useState(ty || false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const topicsList = [
    {
      id: 'siteDesign',
      value: 'Site design',
      name: 'siteDesign',
      label: 'Site design',
    },
    {
      id: 'siteUsability',
      value: 'Site usability',
      name: 'siteUsability',
      label: 'Site usability',
    },
    {
      id: 'content',
      value: 'Content',
      name: 'content',
      label: 'Content',
    },
    {
      id: 'siteFunctionality',
      value: 'Site functionality',
      name: 'siteFunctionality',
      label: 'Site functionality',
    },
  ];

  const clearThankYouPage = () => {
    setShowThankYou(false);
    setTopics([]);
    setFullName({ value: '', error: null });
    setEmail({ value: '', error: null });
    setComments({ value: '', error: null });
    setPhone({ value: '', error: null });
    setTopicError(false);
  };

  const updateTopics = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setTopics([...topics, e.target.value]);
    } else {
      let index = topics.indexOf(e.target.value);
      topics.splice(index, 1);
      setTopics([...topics]);
    }
  };

  useEffect(() => {
    if (Array.isArray(topics) && topics.length) {
      setTopicError(false);
    }
  }, [topics]);

  const topicsIsValid = () => {
    let isValid = true;

    if (!topics || topics.length == 0) {
      isValid = false;
      setTopicError(true);
    }

    return isValid;
  };

  const commentIsValid = () => {
    let isValid = true;

    if (comments.value == '' || comments.value.length < 2) {
      isValid = false;
      setComments({ error: '*Please leave your comment.' });
    }

    return isValid;
  };

  const fullNameIsValid = () => {
    let isValid = true;

    if (!fullName.value || fullName.value.length < 2) {
      setFullName({ ...fullName, error: '*Please enter your full name.' });
      isValid = false;
    } else if (!fullName.value.match(/^[a-zA-Z ,.'-]+$/i)) {
      setFullName({ ...fullName, error: '*Please enter your full name.' });
      isValid = false;
    } else {
      setFullName({ ...fullName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorFields = {};

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= fullNameIsValid() ? true : !(errorFields.fullName = true);
    formIsValid &= emailIsValid() ? true : !(errorFields.email = true);
    formIsValid &= topicsIsValid() ? true : !(errorFields.topics = true);
    formIsValid &= commentIsValid() ? true : !(errorFields.comment = true);

    return [formIsValid, errorFields];
  };

  const formSubmitHandler = async () => {
    const [formIsValid, errorFields] = validateForm();

    if (!formIsValid) {
      scrollIntoViewHelper(errorFields);
      return;
    }

    const formData = {
      topics: topics,
      comments: comments.value,
      name: fullName.value,
      email: email.value,
      phone: phone.value,
      dealerName: dealer.dealerName,
      userAgent: window?.navigator.userAgent,
    };

    setIsSubmitting(true);

    const formSubmitted = await siteFeedbackFormSubmit(
      formData,
      dealer.lotNumber
    );

    if (formSubmitted.status && formSubmitted.status === 200) {
      fireMNTNConversionPixel();
      setShowThankYou(true);
      setIsSubmitting(false);
      // showThankYouCallBack(showThankYou);
    }
  };

  if (showThankYou) {
    return (
      <SiteFeedbackThankYou
        label="Requesting Information"
        closeFunction={clearThankYouPage}
        dealer={dealer}
      />
    );
  }

  return (
    <SiteFeedbackFormStyles>
      <div className="form-container">
        <form>
          <div className="form-intro">
            <h3>How can we help?</h3>
            <p>
              Please select the topic you're contacting us about. Check all that
              apply:
            </p>
          </div>

          {topicsList.map((topic, i) => (
            <CheckBox
              key={i}
              checked={topics.includes(topic.value)}
              label={topic.label}
              name={topic.name}
              id={topic.id}
              value={topic.value || ''}
              onChange={updateTopics}
            />
          ))}
          {topicError && (
            <p className="topic-error">*Please select at least one topic.</p>
          )}
          <span className="formspacer"></span>
          <TextArea
            id="comments"
            label="Comments"
            inputName="comments"
            error={comments.error}
            errorMessage={comments.error}
            value={comments.value || ''}
            inputMaxLength="20000"
            onChange={(event) => {
              setComments({ value: event.target.value });
            }}
          />
          <TextField
            id="fullName"
            textFieldStyle="primary"
            label="Name"
            value={fullName.value || ''}
            errorMessage={fullName.error}
            onChange={(event) => {
              setFullName({ value: event.target.value, error: null });
            }}
            onBlur={fullNameIsValid}
          />
          <TextField
            id="email"
            textFieldStyle="primary"
            className="half"
            label="Email"
            value={email.value || ''}
            errorMessage={email.error}
            onChange={(event) => {
              setEmail({ value: event.target.value, error: null });
            }}
            onBlur={emailIsValid}
          />
          <TextField
            id="phone"
            className="half"
            textFieldStyle="primary"
            label="Phone (optional)"
            value={
              phone.value && phone.value.length > 0
                ? formatPhoneNumber(phone.value)
                : ''
            }
            errorMessage={phone.error}
            onChange={(event) => {
              setPhone({ value: event.target.value, error: null });
            }}
            onBlur={phoneIsValid}
            type="tel"
            minLength="10"
            maxLength="10"
          />
        </form>
        <Button
          text={isSubmitting ? <Loader /> : 'Submit'}
          onClick={isSubmitting ? () => {} : formSubmitHandler}
          disabled={isSubmitting}
          automationId="siteFeedbackBtn"
        />
      </div>
    </SiteFeedbackFormStyles>
  );
};

export default SiteFeedbackForm;
