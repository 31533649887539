import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import App from '../../App';
import { StyledThankYou } from './PromoThankYou.style';
import FormSuccessLogo from '../../images/svg/form-success-illustration.svg';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';

const PromoThankyou = ({ dealer, homes }) => {
  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }

  return (
    <App dealer={dealer} homes={homes}>
      <StyledThankYou>
        <div className="offer-text">
          <h1 className="promo-headline">Out with old and in with the bold!</h1>
          <div className="divider"></div>
          <h2 className="sub-headline">
            Trade in and trade up to a new Energy Smart&reg; home.
          </h2>
        </div>
        <div className="message-container">
          <h3>Thank You!</h3>
          <img src={FormSuccessLogo} />
          <p>
            Please check your inbox for an email with more information. In the
            <br /> meantime...
          </p>
          <Link to="/homes" id="viewHomes" className="button">
            View Homes
          </Link>
        </div>
      </StyledThankYou>
    </App>
  );
};

PromoThankyou.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  const { dealer, homes } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  );

  return { dealer, homes };
};

export default PromoThankyou;
