import styled from 'styled-components';

import caretWhite from '../images/down-caret-white.svg';
import caretBlue from '../images/down-caret-blue.svg';

export const Wrapper = styled.div`
   box-sizing: border-box;
   bottom: 0;
   color: #fff;
   font-family: "acumin-pro", sans-serif;
   left: 0;
   margin: 0 auto;
   max-width: 643px;
   opacity: ${props => props.visible ? '1' : '0'};
   padding: 0 20px;
   pointer-events: ${props => props.visible ? 'auto' : 'none'};
   position: absolute;
   right: 0;
   text-align: center;
   transition: all 500ms ease-in-out;
   top: 0;
   width: 100%;
   z-index: 3;

   h1, h2, h3, h4, h5, h6 {
      margin: 0;
   }
   .intro-blurb {
      color: #fff;
      font-weight: 600;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.8);
   }

   @media (max-width:  760px) {
      background: #fff;
      color: #000;
      bottom: 0;
      height: 60%;
      max-width: none;
      padding: 20px;
      top: auto;
      .intro-blurb {
         color: #4e5255;
         font-weight: 400;
         text-shadow: none;
      }
   }

   @media (max-width: 345px) {
      height: 65%;
   }
`;

export const Header = styled.h2`
   &.intro-header {
      color: #fff;
      font-size: 61px;
      font-weight: 700 !important;
      letter-spacing: -1px;
      margin: 0;
      padding-top: 30px;
      white-space: pre-wrap;

      @media (max-width: 760px) {
         color: #1277C6;
         font-size: 40px;
         padding-top: 0;
      }

      @media (max-width: 345px) {
         font-size: 30px;
      }
   }
`;

export const ScrollIndicator = styled.span`
   background: url(${caretWhite}) center bottom no-repeat;
   background-size: auto 10px;
   bottom: 20px;
   color: #fff;
   display: block;
   font-family: "acumin-pro", sans-serif;
   font-size: 14px;
   height: 41px;
   opacity: ${props => props.visible ? '1' : '0'};
   pointer-events: ${props => props.visible ? 'auto' : 'none'};
   position: absolute;
   left: 0;
   letter-spacing: 1px;
   right: 0;
   text-align: center;
   text-transform: uppercase;
   transition: all 800ms ease-in-out;
   z-index: 2;

   @media (max-height: 650px) and (min-width: 760px) {
      text-shadow: 1px 1px 1px #333;
   }

   @media (max-width: 760px) {
      background: url(${caretBlue}) center bottom no-repeat / auto 10px;
      color: #1277C6;
   }
`;