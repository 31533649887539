import styled from 'styled-components';
import colors from 'pubweb-smokey/dist/colors';

export const FormContainer = styled.div`
  background-color: ${colors.primary.white.standard};

  @media only screen and (min-width: 320px) {
    width: 100%;
    margin: auto;
    padding: 70px 0;
  }

  @media screen and (min-width: 1025px) {
    width: 50%;
    margin: unset;
    padding: 123px 0;
  }

  @media screen and (min-width: 1800px) {
    padding: 273px 0;
  }

  .form-headline {
    box-sizing: border-box;

    @media screen and (min-width: 320px) {
      padding: 0 21px 70px;
    }

    @media screen and (min-width: 768px) {
      padding: 0 78px 70px;
    }

    h3 {
      font-weight: 700;
      font-family: 'acumin-pro', sans-serif;
      letter-spacing: -0.35px;
      color: ${colors.primary.black.standard};
      text-align: center;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto 20px;
      font-size: 26px;
      line-height: 31px;

      @media only screen and (min-width: 768px) {
        font-size: 25px;
        line-height: 30px;
        width: 67%;
        max-width: 512px;
      }

      @media screen and (min-width: 1025px) {
        width: 95%;
        max-width: 391px;
      }

      @media screen and (min-width: 1610px) {
        width: 54%;
      }
    }

    p {
      font: 400 14px/22px 'source-sans-pro', sans-serif;
      color: ${colors.accent.grey1.standard};
      text-align: center;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 0 auto;
    }

    .red {
      color: #f60032;
    }
  }

  .bold {
    font-weight: bold;
  }

  form {
    margin: 0 auto;
    max-width: 325px;
    width: 87%;

    @media only screen and (min-width: 768px) {
      max-width: 512px;
      width: 67%;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 465px;
    }
  }

  .group {
    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
    }

    .half-width-input {
      width: 100%;
      @media only screen and (min-width: 1025px) {
        width: 47%;
      }
    }

    .full-width-input {
      width: 100%;
    }

    .error-message {
      color: red;
    }
  }

  .submit-btn {
    width: 100%;
    margin-top: 22px;
  }

  .agree-terms {
    font: 400 12px/17px 'source-sans-pro', sans-serif;
    color: ${colors.accent.grey1.standard};
    width: 100%;
    margin: 30px 0 0;
    span {
      font: 400 12px/17px 'Source Sans Pro', sans-serif;
    }
    a {
      text-decoration: underline;
      font-family: 'source-sans-pro', sans-serif;
      color: ${colors.accent.grey1.standard};
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
