import React from 'react';

import { Wrapper, Header, Blurb, ScrollIndicator } from './Intro.styled';

class Intro extends React.Component {
   shouldComponentUpdate(nextProps) {
      if (this.props.visible !== nextProps.visible) {
         return true;
      }
      return false;
   }

   render() {
      const data = this.props.data;
      if (!data) return null;

      return (
         <Wrapper id="unbuilt-intro" visible={this.props.visible}>
            <Header className="intro-header">{data.header}</Header>
            <p className="intro-blurb">{data.blurb}</p>
            <ScrollIndicator visible={this.props.visible}>Scroll to begin</ScrollIndicator>
         </Wrapper>
      )
   }
}

export default Intro;
