import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import NotFoundStyles from './NotFound.styled';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import { Link } from 'react-router-dom';
import HomeIcon from '../../images/svg/icons/Home';
import Land from '../../images/svg/icons/Land';
import Phone from '../../images/svg/icons/Phone';
import NotFoundGraphic from '../../images/404.png';
import { useHistory } from 'react-router-dom';

const NotFound = ({ dealer }) => {
  return (
    <App dealer={dealer}>
      <NotFoundStyles>
        <Helmet>
          <title>404 | {formatDealerName(dealer?.dealerName)}</title>
          <meta name="robots" content="noindex" />
        </Helmet>

        <div className="container">
          <div className="content">
            <div className="intro">
              <span>404 error</span>
              <h1>Oops, nobody's home!</h1>
              <p>Try these helpful links instead.</p>
            </div>
            <ul className="links">
              <li>
                <Link to={{ pathname: '/homes', state: { dealer } }}>
                  <HomeIcon />
                  <span>Find a Home</span>
                </Link>
              </li>
              <li>
                <Link to={{ pathname: '/contact-us', state: { dealer } }}>
                  <Phone />
                  <span>Contact Us</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="graphic">
            <img src={NotFoundGraphic} alt="404 graphic" />
          </div>
        </div>
      </NotFoundStyles>
    </App>
  );
};

NotFound.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  let lotNumber;
  let url;
  let dealer;

  if (req) {
    lotNumber = req.query.lotNumber;
    url = req.headers['vanity-url'] || req.hostname;

    //lowercase and remove trailing slash
    let sanitizedPath = req.url.toLowerCase().replace(/\/$/, '');

    if (sanitizedPath.startsWith('/homes')) {
      return { redirectTo: '/homes' };
    } else if (sanitizedPath.startsWith('/available-floorplans')) {
      return { redirectTo: '/homes' };
    } else if (sanitizedPath == '/virtual-tours') {
      return { redirectTo: '/homes' };
    } else if (sanitizedPath == '/our-customers') {
      return { redirectTo: '/' };
    } else if (sanitizedPath == '/about') {
      return { redirectTo: '/about-us' };
    } else if (sanitizedPath == '/our-team') {
      return { redirectTo: '/about-us' };
    } else if (sanitizedPath == '/contact') {
      return { redirectTo: '/contact-us' };
    } else if (sanitizedPath == '/contact-webmaster') {
      return { redirectTo: '/site-feedback' };
    } else if (sanitizedPath == '/our-building-process') {
      return { redirectTo: '/facility-tour' };
    } else if (sanitizedPath == '/energy-smart-home') {
      return { redirectTo: '/energysmart' };
    } else if (sanitizedPath == '/factory-tour') {
      return { redirectTo: '/facility-tour' };
    } else if (sanitizedPath == '/special-offers/welcome-home') {
      return { redirectTo: '/special-offers' };
    } else if (
      sanitizedPath.startsWith('/special-offers/smart-savings-event')
    ) {
      return { redirectTo: '/special-offers/the-red-tag-sale' };
    } else if (
      sanitizedPath.startsWith('/special-offers/smart-savings-event/thank-you')
    ) {
      return { redirectTo: '/special-offers/the-red-tag-sale' };
    } else if (sanitizedPath.startsWith('/sales-agents')) {
      return { redirectTo: '/our-team' };
    } else if (
      sanitizedPath.startsWith('/our-team') &&
      sanitizedPath !== '/our-team'
    ) {
      return { redirectTo: '/about-us' };
    } else if (sanitizedPath == '/admin') {
      return { redirectTo: 'https://www.cmhwebadmin.com/' };
    } else if (sanitizedPath == '/testimonials') {
      return { redirectTo: '/give-a-testimonial' };
    } else if (sanitizedPath == '/special-offers/garage-sale') {
      return { redirectTo: '/special-offers' };
    } else if (sanitizedPath == '/legal-and-privacy') {
      return { redirectTo: '/legal' };
    } else if (sanitizedPath != '/404') {
      return { redirectTo: '/404' };
    }
  }

  if (!req) {
    dealer = location?.state?.dealer;

    if (dealer) {
      return { dealer: dealer };
    }
  }

  dealer = await dealerService.getDealer(lotNumber, url);

  return { dealer };
};

export default NotFound;
