import React from 'react';

const PlannerGraphicMobile = () => {
  return (
    <svg
      width="375"
      height="645"
      viewBox="0 0 375 645"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <path
        d="M116.69 214.739C116.314 213.422 115.938 211.916 115.938 210.41C116.314 207.774 118.194 205.703 120.262 204.197C122.33 202.691 124.587 201.373 126.279 199.491C130.98 194.22 129.664 185.937 132.108 179.348C133.236 183.866 134.176 188.384 134.176 192.902C134.176 197.42 133.236 202.126 130.604 206.08C127.219 211.163 123.083 214.928 120.638 220.575C118.194 219.634 117.442 216.999 116.69 214.739Z"
        fill="#113555"
      />
      <path
        d="M101.083 219.822C97.3226 217.751 92.998 217.563 88.8614 218.316C84.7248 219.069 80.7762 220.763 77.0156 222.457C80.9642 222.646 84.9128 223.022 88.2973 224.905C91.3057 226.599 93.374 229.235 96.0064 231.305C99.3909 233.753 113.117 237.706 115.749 232.058C117.442 228.67 104.092 221.328 101.083 219.822Z"
        fill="#113555"
      />
      <path
        d="M126.844 218.127C128.536 213.986 132.108 210.786 135.305 207.773C134.365 214.551 134.177 221.704 134.741 228.481C134.929 231.117 135.305 233.753 134.553 236.2C132.108 244.671 127.972 238.082 126.656 233.564C125.339 228.481 124.775 223.022 126.844 218.127Z"
        fill="#113555"
      />
      <path
        d="M114.995 239.024C112.739 239.212 110.67 239.965 108.414 240.906C103.713 242.789 99.0127 244.671 94.5 246.742C100.329 247.119 106.158 246.742 111.799 245.424C113.115 245.048 114.619 244.86 115.935 245.048C118.568 245.424 120.448 247.683 122.328 249.566C126.465 253.143 136.994 252.578 131.729 245.989C128.721 242.036 119.696 238.647 114.995 239.024Z"
        fill="#113555"
      />
      <path
        d="M93.751 206.645C91.3066 204.197 89.0503 201.562 87.9221 198.362C86.6059 195.161 86.4179 191.584 87.7341 188.572C88.2982 191.584 88.8623 194.785 91.1186 196.855C92.6228 198.362 94.6911 199.115 96.7594 200.056C102.024 202.691 107.853 208.339 108.605 214.551C103.34 214.74 97.3235 210.033 93.751 206.645Z"
        fill="#113555"
      />
      <path
        d="M105.973 183.49C106.537 180.478 108.041 177.842 109.545 175.018C110.486 173.324 111.614 171.441 113.494 170.688C113.118 174.83 112.742 178.972 112.178 183.302C111.99 185.937 111.614 188.573 111.99 191.396C112.366 194.597 112.742 204.386 107.289 198.55C104.845 195.538 105.409 186.878 105.973 183.49Z"
        fill="#113555"
      />
      <path
        d="M92.0591 180.477C91.307 176.712 91.307 172.758 89.6147 169.182C87.1704 163.722 81.3415 160.522 75.7007 158.451C74.0084 157.698 71.7521 156.945 71 155.062C75.5127 155.816 80.2134 156.569 84.538 158.263C88.8626 159.957 92.9992 162.593 95.4436 166.546C98.6401 171.441 100.144 179.724 99.0161 185.56C97.3239 194.031 92.9992 185.183 92.0591 180.477Z"
        fill="#113555"
      />
      <path
        d="M93.9375 174.076C95.0657 177.653 96.0058 181.23 96.7579 184.807C97.322 187.442 97.8861 190.266 99.0142 192.713C100.518 196.29 102.963 199.302 105.407 202.503C110.484 209.468 114.997 216.998 118.193 225.093C119.509 227.917 120.449 230.929 122.33 233.565C126.09 239.4 132.295 242.977 136.244 248.625C139.44 253.331 141.132 258.979 141.508 264.627"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.793 183.301C106.537 190.266 108.605 197.985 107.289 205.327"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.842 204.197C125.714 205.327 124.21 205.892 123.269 207.021C122.705 207.774 122.517 208.527 122.141 209.28C121.389 211.539 120.449 213.987 120.449 216.434C120.449 218.128 120.637 220.011 120.637 221.705C120.637 223.399 120.261 225.47 119.133 226.6"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.6 242.6C131.164 239.588 131.164 236.388 130.6 233.188C129.848 229.423 128.344 225.658 128.532 221.892C128.532 220.763 128.908 219.445 129.66 218.504"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.4453 223.775C99.2059 227.164 104.095 228.67 108.983 229.8C110.676 230.176 112.18 230.553 113.872 230.553C115 230.553 115.94 230.176 117.068 230.364C118.385 230.364 119.701 230.929 120.641 231.682"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.078 242.977C123.087 243.353 126.095 244.294 128.539 246.177C129.856 247.118 131.172 248.436 132.676 249.189C133.24 249.565 133.804 249.754 134.368 249.754C134.932 249.754 135.684 249.565 135.872 249.001"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.0625 203.443C98.635 205.891 102.208 208.714 105.404 211.538C106.344 212.48 107.284 213.421 108.412 213.986C109.541 214.55 111.045 214.739 112.173 213.986"
        stroke="#55A8E1"
        strokeWidth="0.745072"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.852 65.0345C310.262 63.5984 310.672 61.9572 310.672 60.3159C310.262 57.4437 308.213 55.187 305.959 53.5457C303.705 51.9044 301.246 50.4683 299.402 48.4167C294.279 42.6723 295.713 33.6454 293.05 26.4648C291.82 31.3886 290.796 36.3124 290.796 41.2362C290.796 46.16 291.82 51.289 294.689 55.5973C298.377 61.1365 302.885 65.2397 305.549 71.3944C308.213 70.3687 309.033 67.4964 309.852 65.0345Z"
        fill="#113555"
      />
      <path
        d="M298.778 53.5469C300.008 54.7778 301.647 55.3933 302.672 56.6242C303.286 57.4449 303.491 58.2655 303.901 59.0862C304.721 61.548 305.745 64.2151 305.745 66.8821C305.745 68.7286 305.54 70.7802 305.54 72.6266C305.54 74.473 305.95 76.7297 307.18 77.9607"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M326.857 70.5744C330.956 68.3177 335.669 68.1125 340.177 68.9332C344.685 69.7538 348.988 71.6002 353.086 73.4467C348.783 73.6518 344.48 74.0621 340.791 76.1137C337.513 77.9601 335.259 80.8323 332.39 83.0891C328.702 85.7561 313.743 90.0644 310.874 83.9097C309.03 80.2169 323.579 72.2157 326.857 70.5744Z"
        fill="#113555"
      />
      <path
        d="M298.784 68.727C296.939 64.2136 293.046 60.7259 289.563 57.4434C290.587 64.829 290.792 72.6251 290.177 80.0107C289.972 82.8829 289.563 85.7552 290.382 88.4222C293.046 97.6543 297.554 90.4738 298.989 85.55C300.423 80.0107 301.038 74.0612 298.784 68.727Z"
        fill="#113555"
      />
      <path
        d="M311.696 91.5C314.155 91.7051 316.409 92.5258 318.868 93.5516C323.991 95.6031 329.113 97.6547 334.031 99.9115C327.679 100.322 321.327 99.9115 315.179 98.4754C313.745 98.065 312.106 97.8599 310.671 98.065C307.803 98.4754 305.754 100.937 303.704 102.989C299.196 106.887 287.721 106.271 293.459 99.0908C296.737 94.7825 306.573 91.0897 311.696 91.5Z"
        fill="#113555"
      />
      <path
        d="M334.847 56.2128C337.511 53.5457 339.97 50.6735 341.199 47.1858C342.633 43.6981 342.838 39.8001 341.404 36.5176C340.789 39.8001 340.175 43.2878 337.716 45.5445C336.076 47.1858 333.822 48.0064 331.568 49.0322C325.831 51.9044 319.479 58.0592 318.659 64.8294C324.396 65.0346 330.954 59.9056 334.847 56.2128Z"
        fill="#113555"
      />
      <path
        d="M321.53 30.98C320.915 27.6975 319.276 24.8253 317.637 21.7479C316.612 19.9015 315.383 17.8499 313.334 17.0293C313.744 21.5428 314.153 26.0563 314.768 30.7749C314.973 33.6471 315.383 36.5193 314.973 39.5967C314.563 43.0844 314.153 53.7526 320.096 47.3927C322.76 44.1102 322.145 34.6729 321.53 30.98Z"
        fill="#113555"
      />
      <path
        d="M336.691 27.6963C337.51 23.5932 337.51 19.2849 339.354 15.3869C342.018 9.43727 348.371 5.94958 354.518 3.69284C356.362 2.87221 358.821 2.05158 359.641 0C354.723 0.820632 349.6 1.64127 344.887 3.48769C340.174 5.33411 335.666 8.20632 333.002 12.5146C329.519 17.8487 327.879 26.8757 329.109 33.2356C330.953 42.4677 335.666 32.8253 336.691 27.6963Z"
        fill="#113555"
      />
      <path
        d="M334.641 20.7217C333.411 24.6197 332.387 28.5177 331.567 32.4157C330.952 35.2879 330.337 38.3653 329.108 41.0323C327.469 44.9303 324.805 48.2129 322.141 51.7005C316.608 59.2914 311.691 67.4977 308.207 76.3195C306.773 79.3969 305.748 82.6794 303.699 85.5516C299.601 91.9115 292.839 95.8095 288.536 101.964C285.052 107.093 283.208 113.248 282.798 119.403"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M318.454 30.7734C320.913 38.3643 318.659 46.7758 320.094 54.7769"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M294.682 95.3989C294.067 92.1164 294.067 88.6287 294.682 85.141C295.501 81.0378 297.14 76.9347 296.936 72.8315C296.936 71.6006 296.526 70.1645 295.706 69.1387"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M333 74.8828C328.902 78.5757 323.574 80.2169 318.246 81.4479C316.402 81.8582 314.763 82.2685 312.919 82.2685C311.689 82.2685 310.665 81.8582 309.435 82.0633C308.001 82.0633 306.567 82.6788 305.542 83.4994"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M306.156 95.8086C302.878 96.2189 299.599 97.2447 296.935 99.2963C295.501 100.322 294.067 101.758 292.427 102.579C291.812 102.989 291.198 103.194 290.583 103.194C289.968 103.194 289.149 102.989 288.944 102.374"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M333.406 52.7256C329.513 55.3926 325.62 58.47 322.136 61.5474C321.112 62.5732 320.087 63.599 318.858 64.2144C317.628 64.8299 315.989 65.0351 314.759 64.2144"
        stroke="#55A8E1"
        strokeWidth="0.907659"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="127.379"
        y="45.3788"
        width="177.242"
        height="236.242"
        fill="url(#pattern0)"
      />
      <rect
        x="127.379"
        y="45.3788"
        width="177.242"
        height="236.242"
        fill="black"
        fill-opacity="0.1"
      />
      <rect
        x="127.379"
        y="45.3788"
        width="177.242"
        height="236.242"
        stroke="#FFC50A"
        strokeWidth="4.75754"
      />
      <g clipPath="url(#clip0_8646_203268)">
        <path
          d="M283.796 104.689C280.034 104.371 274.359 124.104 272.828 129.388C272.828 129.388 276.654 133.971 283.095 133.461C289.535 132.952 285.581 104.817 283.796 104.689Z"
          fill="#FFA70B"
        />
        <path
          d="M285.457 76.1084C281.312 76.2357 280.356 82.5376 280.356 87.821C280.356 93.1043 278.634 100.807 279.399 103.035C279.399 103.035 281.695 103.098 284.564 102.589C284.501 101.698 284.437 100.679 284.692 100.17C284.692 100.17 285.011 101.825 285.776 102.398C289.857 101.57 294.448 99.6608 294.958 95.3323C295.979 87.3117 285.457 76.1084 285.457 76.1084Z"
          fill="#012A66"
        />
        <path
          d="M281.373 131.361C276.718 131.297 274.869 129.324 273.402 127.414C273.211 128.178 273.019 128.814 272.828 129.324C272.828 129.324 275.698 132.697 280.544 133.334C282.521 132.252 283.413 131.424 281.373 131.361Z"
          fill="#FCF6E6"
        />
        <path
          d="M277.998 130.088L271.812 185.277C271.812 185.277 274.81 186.55 275.575 185.086C276.595 182.985 286.734 148.421 286.606 143.201C286.351 138.045 277.998 130.088 277.998 130.088Z"
          fill="#C97777"
        />
        <path
          d="M289.602 94.1233C288.454 91.5134 285.138 87.5031 283.289 85.3389C283.289 87.7578 283.289 90.7496 283.289 93.5504C283.289 95.2054 282.97 97.306 282.651 98.8974C282.588 99.343 282.524 99.7886 282.524 100.234C282.46 100.998 282.524 101.826 282.715 102.589C282.715 102.717 282.779 102.78 282.779 102.908C283.353 102.844 283.991 102.78 284.692 102.653C284.628 101.762 284.564 100.743 284.82 100.234C284.82 100.234 285.138 101.889 285.904 102.462C287.689 102.08 289.538 101.507 291.132 100.68C291.005 98.3245 290.431 96.1602 289.602 94.1233Z"
          fill="#0C1E34"
        />
        <path
          d="M290.625 143.965L308.48 283.943H315.749C315.749 283.943 311.349 184.64 310.967 173.883C310.584 163.061 311.349 153.131 305.61 144.028L290.625 143.965Z"
          fill="#C97777"
        />
        <path
          d="M305.61 143.965L276.086 284.261H269.391L278.19 143.965H305.61Z"
          fill="#C97777"
        />
        <path
          d="M305.608 143.965L279.782 274.394H269.898L278.188 143.965H305.608Z"
          fill="#012A66"
        />
        <path
          d="M299.929 172.482L279.779 274.394H279.523L292.404 157.523L299.929 172.482Z"
          fill="#061F36"
        />
        <path
          d="M286.539 143.965L306.307 275.795L316.063 275.922C316.063 275.922 314.341 184.641 313.959 173.819C313.576 162.998 311.281 153.068 305.542 143.965H286.539Z"
          fill="#012A66"
        />
        <path
          d="M300.953 152.812C301.782 154.022 303.249 157.332 307.075 159.114"
          stroke="#061F36"
          strokeWidth="0.517666"
          strokeMiterlimit="10"
        />
        <path
          d="M316.06 152.623L273.719 153.196C273.719 153.196 276.333 124.042 277.098 122.196L280.287 110.547C281.179 106.791 282.455 104.436 285.834 103.99C286.153 103.927 286.408 103.927 286.727 103.927H293.359C293.359 103.927 303.179 102.717 305.985 104.818C308.79 106.918 311.788 108.637 311.469 111.374L316.06 152.623Z"
          fill="#FFC50A"
        />
        <path
          d="M300.319 105.326C301.021 104.626 300.319 103.735 300 102.844C299.363 101.125 298.406 97.4969 299.554 91.6406C300.957 83.9383 299.108 77.1272 292.348 75.3449C283.421 72.9897 280.679 85.1478 282.975 92.3408C283.421 93.6776 284.441 94.7597 285.653 95.3326L286.609 95.7782C287.63 96.2874 288.267 97.115 288.331 98.1334C288.459 99.9158 288.522 102.653 287.566 103.99C286.163 106.09 283.038 107.809 285.334 111.31C287.566 114.684 292.986 112.774 300.319 105.326Z"
          fill="#C97777"
        />
        <path
          d="M283.096 86.4207C283.378 86.4207 283.606 86.0502 283.606 85.5931C283.606 85.1361 283.378 84.7656 283.096 84.7656C282.814 84.7656 282.586 85.1361 282.586 85.5931C282.586 86.0502 282.814 86.4207 283.096 86.4207Z"
          fill="#0C1E34"
        />
        <path
          d="M288.448 86.9939C288.729 86.9939 288.958 86.6234 288.958 86.1664C288.958 85.7094 288.729 85.3389 288.448 85.3389C288.166 85.3389 287.938 85.7094 287.938 86.1664C287.938 86.6234 288.166 86.9939 288.448 86.9939Z"
          fill="#0C1E34"
        />
        <path
          d="M284.943 86.166C284.943 86.6753 284.943 87.1845 284.688 87.6937C284.624 87.8847 284.433 88.0757 284.369 88.2666C284.177 88.5849 284.177 88.9668 284.241 89.3488"
          stroke="#774D4D"
          strokeWidth="0.258833"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M316.065 275.923L315.81 270.385H303.758L306.308 275.795L316.065 275.923Z"
          fill="#024391"
        />
        <path
          d="M279.782 274.394L282.269 268.538H269.898V274.394H279.782Z"
          fill="#024391"
        />
        <path
          d="M303.242 110.991L311.468 147.275C311.468 147.275 321.862 149.503 321.607 147.148C321.352 144.792 314.528 117.994 312.36 111.883C309.491 103.989 301.584 101.061 303.242 110.991Z"
          fill="#C97777"
        />
        <path
          d="M311.339 146.701C311.339 146.701 307.45 186.295 306.876 188.332C306.302 190.496 309.809 190.433 310.574 188.968C311.594 186.868 321.733 152.303 321.606 147.083C321.478 141.864 311.339 146.701 311.339 146.701Z"
          fill="#C97777"
        />
        <path
          d="M306.945 188.332C306.945 188.332 301.843 195.589 303.119 195.398C304.394 195.207 305.351 192.788 305.924 194.188C307.519 198.135 308.029 201.445 308.73 200.936C308.73 200.936 308.794 200.554 308.73 199.726C308.73 199.726 308.922 200.49 309.559 200.936C310.197 201.381 309.623 198.453 309.623 198.453C309.623 198.453 309.942 200.172 310.452 200.045C310.579 199.663 310.452 198.644 310.452 198.644C310.452 198.644 310.579 199.09 310.898 199.026C311.153 199.026 309.942 195.27 309.942 194.125C309.942 192.979 310.324 189.478 310.643 188.905C310.898 188.396 306.945 188.332 306.945 188.332Z"
          fill="#C97777"
        />
        <path
          d="M315.742 283.879L317.273 289.417H299.418C299.418 289.417 298.207 288.844 302.288 287.253C306.369 285.661 308.856 285.025 308.473 283.879H315.742Z"
          fill="#C97777"
        />
        <path
          d="M276.15 283.879L275.894 289.417H258.996C258.996 289.417 257.785 288.844 261.866 287.253C265.947 285.661 269.327 284.961 269.39 283.879"
          fill="#C97777"
        />
        <path
          d="M309.685 287.443L314.913 285.088H316.125L317.337 289.48H299.418C299.418 289.48 298.207 288.907 302.288 287.316C304.838 286.361 306.751 285.724 307.772 285.088H309.685V287.443Z"
          fill="#0075C9"
        />
        <path
          d="M269.833 287.444L275.062 285.089H276.273L276.783 289.481H258.801C258.801 289.481 257.589 288.908 261.67 287.317C264.221 286.362 266.963 285.725 267.983 285.089H269.896V287.444H269.833Z"
          fill="#0075C9"
        />
        <path
          d="M275.189 183.686C275.189 183.686 279.015 192.215 277.739 191.833C276.528 191.451 275.252 188.651 275.189 190.178C275.061 192.215 275.061 197.435 274.487 196.799C274.487 196.799 272.957 193.934 272.893 192.788C272.638 190.306 271.681 187.314 271.745 185.277C271.809 184.64 275.189 183.686 275.189 183.686Z"
          fill="#C97777"
        />
        <path
          d="M284.883 94.8869C285.138 95.0779 285.393 95.2052 285.648 95.3325L286.605 95.7781C287.625 96.2873 288.262 97.1148 288.326 98.1333C288.454 99.9157 288.518 102.653 287.561 103.99C287.497 104.117 287.37 104.244 287.306 104.372C288.836 103.417 294.958 94.123 294.958 94.123C290.367 96.0964 286.732 96.0327 284.883 94.8869Z"
          fill="#A55D5D"
        />
        <path
          d="M286.726 87.63C286.535 87.7573 286.344 87.8846 286.344 88.0756C286.344 88.3302 286.599 88.5211 286.854 88.5848C287.683 88.9031 288.639 88.9667 289.532 88.8394C289.66 88.8394 289.787 88.7758 289.915 88.7121C290.042 88.6485 290.106 88.5211 290.106 88.3302C290.106 88.1392 289.978 88.0119 289.787 87.8846C289.596 87.7573 289.405 87.63 289.213 87.5663C289.022 87.5027 288.831 87.3754 288.576 87.3754C288.448 87.3754 288.384 87.3117 288.257 87.3117C288.193 87.3117 288.129 87.248 288.002 87.248C287.747 87.248 287.555 87.3117 287.3 87.3117C287.109 87.3754 286.854 87.439 286.663 87.5663C286.79 87.63 286.726 87.63 286.726 87.63Z"
          fill="#D68B8B"
        />
        <path
          d="M285.966 76.6816C284.818 78.8459 285.01 81.1375 287.943 82.156C290.876 83.1745 293.299 83.811 293.363 84.7658C293.427 85.7207 295.467 82.2833 295.467 82.2833L293.235 78.4003L285.966 76.6816Z"
          fill="#012A66"
        />
        <path
          d="M283.797 91.6402C284.243 91.6402 284.626 91.7039 285.072 91.5765C285.455 91.4492 285.837 91.1946 285.965 90.749"
          stroke="#774D4D"
          strokeWidth="0.258833"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M303.82 87.7574C300.504 84.129 300.695 80.819 295.02 76.0448C295.02 76.0448 287.942 71.0797 283.797 78.2728L293.553 81.1372L293.617 86.0387C293.489 88.2666 290.365 100.043 292.278 104.244C292.278 104.244 294.127 104.18 296.614 103.735C296.295 102.844 296.104 101.507 296.104 101.507C296.104 101.507 296.486 102.844 297.507 103.544C300.823 102.78 304.904 101.252 307.582 98.0059C307.582 98.0059 309.559 93.9956 303.82 87.7574Z"
          fill="#012A66"
        />
        <path
          d="M292.536 85.4018C293.556 84.1923 295.15 84.7652 295.023 86.2293C294.895 87.6934 291.962 89.3484 291.962 89.3484C291.962 89.3484 291.133 87.0568 292.536 85.4018Z"
          fill="#C97777"
        />
        <path
          d="M293.234 85.4025C293.936 85.2752 294.382 85.5935 294.382 85.9754C294.255 86.8029 293.553 87.3758 293.553 87.3758"
          stroke="#774D4D"
          strokeWidth="0.258833"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.833 78.3359C285.259 79.6727 284.111 82.0279 282.453 82.5372C282.453 82.5372 282.517 80.0546 283.856 78.2086C285.195 76.3626 285.833 78.3359 285.833 78.3359Z"
          fill="#012A66"
        />
        <path
          d="M293.489 89.3493C293.841 89.3493 294.127 89.0643 294.127 88.7127C294.127 88.3612 293.841 88.0762 293.489 88.0762C293.137 88.0762 292.852 88.3612 292.852 88.7127C292.852 89.0643 293.137 89.3493 293.489 89.3493Z"
          fill="white"
        />
        <path
          d="M282.268 88.5853C282.396 88.5853 282.587 88.5853 282.715 88.5217C282.97 88.458 283.225 88.3944 283.352 88.1398C283.48 87.8851 283.416 87.5669 283.225 87.3759C283.033 87.1849 282.778 87.0576 282.459 87.0576C282.332 87.0576 282.268 87.0576 282.141 87.0576C282.204 87.5669 282.204 88.0761 282.268 88.5853Z"
          fill="#D68B8B"
        />
        <path
          d="M304.071 100.997C305.347 100.233 306.558 99.2148 307.579 98.0054C307.579 98.0054 309.555 93.9951 303.816 87.7569C300.5 84.1286 300.692 80.8185 295.016 76.0444C295.016 76.0444 292.402 74.262 289.469 74.3893C289.596 74.3893 289.66 74.3893 289.788 74.453C291.509 74.8349 293.167 75.5988 294.251 76.9355C296.866 79.991 296.037 84.7651 298.013 88.2662C298.906 89.7939 300.245 91.0033 301.329 92.4037C301.393 92.4674 301.457 92.5947 301.521 92.6584C301.84 93.0403 302.095 93.4859 302.286 93.8678C302.541 94.3134 302.732 94.759 302.924 95.2046C303.115 95.6502 303.306 96.0957 303.434 96.605C303.561 97.0506 303.689 97.5598 303.753 98.0054C303.816 98.5146 303.88 98.9602 303.944 99.4695C304.071 99.9787 304.071 100.488 304.071 100.997Z"
          fill="#093B85"
        />
        <path
          d="M287.431 83.4287C286.985 83.556 286.793 84.5108 287.495 84.5108C287.75 84.5108 288.962 84.5108 290.109 84.9564C291.257 85.402 291.257 84.8291 290.237 84.3198C289.535 83.9379 287.941 83.3014 287.431 83.4287Z"
          fill="#012A66"
        />
        <path
          d="M284.24 83.2377C284.686 83.4286 284.303 84.1925 283.921 84.1925C283.602 84.1925 282.518 84.1289 282.199 84.3835C281.816 84.6381 281.88 84.0015 282.39 83.7469C282.837 83.4923 283.729 83.0467 284.24 83.2377Z"
          fill="#012A66"
        />
        <path
          d="M304.463 104.117C309.31 105.008 311.031 107.682 314.22 114.557C317.472 121.431 321.617 130.407 321.617 130.407C321.617 130.407 314.857 137.409 306.887 135.69C306.887 135.69 298.916 103.099 304.463 104.117Z"
          fill="#FFC50A"
        />
        <path
          d="M306.438 133.844C306.693 134.99 306.884 135.69 306.884 135.69C314.855 137.409 321.614 130.407 321.614 130.407C321.614 130.407 321.295 129.643 320.721 128.434C315.046 134.672 309.562 134.417 306.438 133.844Z"
          fill="white"
        />
        <path
          d="M306.884 135.69C305.226 127.924 304.078 122.704 304.078 122.704"
          stroke="white"
          strokeWidth="0.517666"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M315.881 248.902C315.881 248.114 315.679 245.353 316.822 245.287C318.166 245.222 318.569 247.719 319.309 248.377"
        fill="#FFAE0B"
      />
      <path
        d="M313.391 265.466C313.189 266.123 313.122 266.846 313.189 267.503C313.996 273.485 315.475 279.4 316.953 285.25C317.357 286.959 317.961 288.668 318.768 290.18C319.037 290.64 319.709 290.706 319.978 290.245C320.381 289.654 320.448 288.734 320.516 288.011C321.053 281.701 321.658 275.325 321.188 269.015C321.053 266.977 320.516 264.743 318.499 263.823C316.752 262.968 314.063 263.494 313.391 265.466Z"
        fill="#FFAE0B"
      />
      <path
        d="M329.117 265.466C328.916 266.123 328.849 266.846 328.916 267.503C329.722 273.485 331.201 279.4 332.68 285.25C333.083 286.959 333.688 288.668 334.495 290.18C334.763 290.64 335.436 290.706 335.704 290.245C336.108 289.654 336.175 288.734 336.242 288.011C336.78 281.701 337.385 275.325 336.914 269.015C336.78 266.977 336.242 264.743 334.226 263.823C332.411 262.968 329.722 263.494 329.117 265.466Z"
        fill="#FFAE0B"
      />
      <path
        d="M334.36 265.466C334.158 266.123 334.091 266.846 334.158 267.503C334.965 273.485 336.443 279.4 337.922 285.25C338.325 286.959 338.93 288.668 339.737 290.18C340.006 290.64 340.678 290.706 340.947 290.245C341.35 289.654 341.417 288.734 341.484 288.011C342.022 281.701 342.627 275.325 342.157 269.015C342.022 266.977 341.484 264.743 339.468 263.823C337.72 262.968 335.032 263.494 334.36 265.466Z"
        fill="#FFC50A"
      />
      <path
        d="M340.142 259.748C338.596 258.104 335.303 258.367 333.219 258.63C330.732 258.959 328.514 260.142 326.162 260.668C324.347 261.062 322.532 260.076 321.793 258.433C320.986 256.527 320.516 254.095 321.322 251.992C321.725 250.808 315.138 253.766 315.071 255.738C315.273 258.236 313.727 260.931 313.122 263.297C312.315 266.452 312.719 270.133 314.13 273.09C315.475 275.851 318.432 278.94 321.793 279.006C323.742 279.269 325.758 279.072 327.371 278.612C329.926 277.889 331.875 276.18 334.026 274.668C335.639 273.485 337.454 272.828 338.865 271.447C340.882 269.475 342.427 268.029 342.293 265.203C342.226 263.428 341.419 261.062 340.142 259.748Z"
        fill="#FFC50A"
      />
      <path
        d="M312.719 266.845C313.727 263.428 314.937 259.878 318.163 258.235C319.171 257.709 320.247 257.183 321.255 256.526C320.919 255.08 320.852 253.437 321.389 251.991C321.389 251.925 321.389 251.859 321.389 251.859C319.911 252.057 318.634 252.517 317.491 253.24C317.222 253.371 316.953 253.568 316.752 253.766C315.878 254.423 315.206 255.146 315.138 255.803C315.34 258.301 313.794 260.996 313.189 263.362C312.853 264.414 312.719 265.597 312.719 266.845Z"
        fill="#FFAE0B"
      />
      <path
        d="M319.508 248.639C319.373 250.02 321.054 251.268 322.196 250.348C323.003 249.757 323.07 248.311 323.204 247.391C323.339 246.01 323.272 245.484 321.927 246.076C320.516 246.733 319.575 247.522 319.508 248.639Z"
        fill="#FFC50A"
      />
      <path
        d="M329.922 277.559C329.317 276.573 328.241 276.902 327.099 277.165C323.805 278.019 320.377 278.216 318.226 274.996C315.538 270.92 317.285 266.582 316.076 262.31C315.672 260.798 314.866 260.207 314.059 260.338C313.656 261.324 313.32 262.31 313.051 263.23C312.312 266.451 312.715 270.132 314.126 273.089C315.471 275.85 318.428 278.939 321.789 279.005C323.738 279.268 325.754 279.071 327.368 278.611C328.309 278.413 329.182 278.019 329.922 277.559Z"
        fill="white"
      />
      <path
        d="M323.542 251.794C323.341 250.479 322.131 247.39 319.913 246.864C319.039 246.799 318.098 246.93 317.224 247.193C315.947 247.522 315.275 247.982 314.804 248.836C314.401 249.559 313.863 250.217 313.326 250.808C313.057 251.071 312.788 251.4 312.721 251.794C312.452 252.977 313.057 254.818 313.931 255.672C314.401 256.132 315.14 256.395 315.813 256.461C318.165 256.789 320.585 256.527 322.4 254.883C323.408 253.963 323.744 253.109 323.542 251.794Z"
        fill="#FFC50A"
      />
      <path
        d="M318.696 265.466C318.494 266.123 318.427 266.846 318.494 267.503C319.301 273.485 320.779 279.4 322.258 285.25C322.661 286.959 323.266 288.668 324.073 290.18C324.342 290.64 325.014 290.706 325.283 290.245C325.686 289.654 325.753 288.734 325.82 288.011C326.358 281.701 326.963 275.325 326.492 269.015C326.358 266.977 325.82 264.743 323.804 263.823C322.056 262.968 319.368 263.494 318.696 265.466Z"
        fill="#FFC50A"
      />
      <path
        d="M336.242 262.639C343.703 261.785 339.67 242.329 340.611 237.925C341.149 235.625 342.359 231.155 345.451 231.747C346.324 231.878 347.4 232.207 347.467 233.259C347.601 234.507 346.526 234.507 345.854 235.362C344.51 237.202 344.375 240.686 344.241 242.855C344.039 246.47 344.442 250.085 344.442 253.7C344.442 255.869 344.711 258.235 344.039 260.404C343.367 262.573 341.821 263.691 340.544 265.466"
        fill="#FFC50A"
      />
      <path
        d="M322.663 246.667C322.529 246.996 320.579 248.573 321.924 248.902C322.865 249.165 322.865 247.39 322.798 246.799C322.529 246.799 322.73 246.733 322.73 246.602"
        fill="#FFAE0B"
      />
      <path
        d="M318.499 286.17C318.163 286.17 317.692 286.302 317.289 286.433C317.692 287.748 318.163 288.997 318.835 290.18C319.104 290.64 319.776 290.706 320.045 290.246C320.448 289.654 320.515 288.734 320.583 288.011C320.583 287.682 320.65 287.419 320.65 287.091C320.045 286.565 319.238 286.105 318.499 286.17Z"
        fill="#FFF2CC"
      />
      <path
        d="M338.256 284.198C338.055 284.33 337.853 284.461 337.719 284.658C337.786 284.856 337.853 285.118 337.853 285.316C338.256 287.025 338.861 288.734 339.668 290.245C339.937 290.705 340.609 290.771 340.878 290.311C341.281 289.719 341.348 288.799 341.416 288.076C341.55 286.959 341.617 285.776 341.684 284.658C340.743 283.804 339.534 283.475 338.256 284.198Z"
        fill="#FFF2CC"
      />
      <path
        d="M335.703 290.245C336.106 289.653 336.173 288.733 336.24 288.01C336.308 287.287 336.375 286.63 336.442 285.907C336.173 285.644 335.837 285.512 335.434 285.381C334.291 284.987 333.417 285.249 332.812 285.841C333.216 287.353 333.753 288.864 334.493 290.245C334.694 290.639 335.434 290.705 335.703 290.245Z"
        fill="#FFF2CC"
      />
      <path
        d="M325.964 286.17C324.821 285.579 323.477 285.447 322.469 286.104C322.872 287.485 323.343 288.931 324.082 290.18C324.351 290.64 325.023 290.705 325.292 290.245C325.695 289.654 325.762 288.734 325.829 288.01C325.829 287.353 325.897 286.762 325.964 286.17Z"
        fill="#FFF2CC"
      />
      <path
        d="M347.466 233.259C347.399 232.207 346.256 231.878 345.45 231.747C344.173 231.484 343.232 232.141 342.492 233.061C343.03 234.245 344.441 235.296 345.651 235.691C345.718 235.625 345.786 235.493 345.853 235.362C346.525 234.507 347.6 234.507 347.466 233.259Z"
        fill="white"
      />
      <path
        d="M315.876 248.902C315.137 250.282 317.086 251.334 317.49 252.583C318.027 254.226 316.481 254.883 315.07 254.817C313.86 254.752 312.65 254.16 312.381 252.977C312.113 251.794 312.65 251.071 313.658 250.545C314.331 250.085 314.532 249.362 315.07 248.836C315.339 248.639 315.876 248.31 315.876 248.902Z"
        fill="white"
      />
      <path
        d="M313.727 252.122C313.592 251.991 312.988 251.531 313.189 251.268C313.324 251.005 314.063 251.202 314.197 251.268C314.87 251.596 314.265 252.056 313.727 252.122Z"
        fill="#FFAE0B"
      />
      <path
        d="M312.853 264.742C312.853 264.939 312.786 265.202 312.786 265.399C312.786 265.597 312.786 265.728 312.719 265.925C312.719 266.254 312.719 266.517 312.719 266.846C313.324 264.676 314.063 262.507 315.273 260.798C314.937 260.47 314.534 260.338 314.13 260.404C313.996 260.733 313.861 261.061 313.727 261.39C313.727 261.456 313.727 261.456 313.66 261.522C313.66 261.719 313.593 261.916 313.458 262.179C313.458 262.245 313.391 262.31 313.391 262.442C313.324 262.705 313.189 263.033 313.122 263.296C313.055 263.625 312.988 264.019 312.92 264.348C312.92 264.479 312.92 264.611 312.853 264.742Z"
        fill="#FFF2CC"
      />
      <g clipPath="url(#clip1_8646_203268)">
        <path
          d="M309.852 65.0345C310.262 63.5984 310.672 61.9572 310.672 60.3159C310.262 57.4437 308.213 55.187 305.959 53.5457C303.705 51.9044 301.246 50.4683 299.402 48.4167C294.279 42.6723 295.713 33.6454 293.05 26.4648C291.82 31.3886 290.796 36.3124 290.796 41.2362C290.796 46.16 291.82 51.289 294.689 55.5973C298.377 61.1365 302.885 65.2397 305.549 71.3944C308.213 70.3687 309.033 67.4964 309.852 65.0345Z"
          fill="#113555"
        />
        <path
          d="M298.778 53.5469C300.008 54.7778 301.647 55.3933 302.672 56.6242C303.286 57.4449 303.491 58.2655 303.901 59.0862C304.721 61.548 305.745 64.2151 305.745 66.8821C305.745 68.7286 305.54 70.7802 305.54 72.6266C305.54 74.473 305.95 76.7297 307.18 77.9607"
          stroke="#55A8E1"
          strokeWidth="0.907659"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M326.857 70.5744C330.956 68.3177 335.669 68.1125 340.177 68.9332C344.685 69.7538 348.988 71.6002 353.086 73.4467C348.783 73.6518 344.48 74.0621 340.791 76.1137C337.513 77.9601 335.259 80.8323 332.39 83.0891C328.702 85.7561 313.743 90.0644 310.874 83.9097C309.03 80.2169 323.579 72.2157 326.857 70.5744Z"
          fill="#113555"
        />
        <path
          d="M334.847 56.2128C337.511 53.5457 339.97 50.6735 341.199 47.1858C342.633 43.6981 342.838 39.8001 341.404 36.5176C340.789 39.8001 340.175 43.2878 337.716 45.5445C336.076 47.1858 333.822 48.0064 331.568 49.0322C325.831 51.9044 319.479 58.0592 318.659 64.8294C324.396 65.0346 330.954 59.9056 334.847 56.2128Z"
          fill="#113555"
        />
        <path
          d="M321.53 30.98C320.915 27.6975 319.276 24.8253 317.637 21.7479C316.612 19.9015 315.383 17.8499 313.334 17.0293C313.744 21.5428 314.153 26.0563 314.768 30.7749C314.973 33.6471 315.383 36.5193 314.973 39.5967C314.563 43.0844 314.153 53.7526 320.096 47.3927C322.76 44.1102 322.145 34.6729 321.53 30.98Z"
          fill="#113555"
        />
        <path
          d="M336.691 27.6963C337.51 23.5932 337.51 19.2849 339.354 15.3869C342.018 9.43727 348.371 5.94958 354.518 3.69284C356.362 2.87221 358.821 2.05158 359.641 0C354.723 0.820632 349.6 1.64127 344.887 3.48769C340.174 5.33411 335.666 8.20632 333.002 12.5146C329.519 17.8487 327.879 26.8757 329.109 33.2356C330.953 42.4677 335.666 32.8253 336.691 27.6963Z"
          fill="#113555"
        />
        <path
          d="M334.641 20.7217C333.411 24.6197 332.387 28.5177 331.567 32.4157C330.952 35.2879 330.337 38.3653 329.108 41.0323C327.469 44.9303 324.805 48.2129 322.141 51.7005C316.608 59.2914 311.691 67.4977 308.207 76.3195C306.773 79.3969 305.748 82.6794 303.699 85.5516C299.601 91.9115 292.839 95.8095 288.536 101.964C285.052 107.093 283.208 113.248 282.798 119.403"
          stroke="#55A8E1"
          strokeWidth="0.907659"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M318.454 30.7734C320.913 38.3643 318.659 46.7758 320.094 54.7769"
          stroke="#55A8E1"
          strokeWidth="0.907659"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M333 74.8828C328.902 78.5757 323.574 80.2169 318.246 81.4479C316.402 81.8582 314.763 82.2685 312.919 82.2685C311.689 82.2685 310.665 81.8582 309.435 82.0633C308.001 82.0633 306.567 82.6788 305.542 83.4994"
          stroke="#55A8E1"
          strokeWidth="0.907659"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M333.406 52.7256C329.513 55.3926 325.62 58.47 322.136 61.5474C321.112 62.5732 320.087 63.599 318.858 64.2144C317.628 64.8299 315.989 65.0351 314.759 64.2144"
          stroke="#55A8E1"
          strokeWidth="0.907659"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M125.431 122.562C133.953 122.562 140.861 115.653 140.861 107.131C140.861 98.6087 133.953 91.7002 125.431 91.7002C116.909 91.7002 110 98.6087 110 107.131C110 115.653 116.909 122.562 125.431 122.562Z"
        fill="#EF4B5D"
      />
      <path
        d="M131.314 109.337L125.433 114.69L119.551 109.337C117.871 107.81 117.704 105.163 119.172 103.419C120.635 101.676 123.183 101.5 124.859 103.026L125.433 103.546L126.002 103.026C127.678 101.5 130.225 101.676 131.693 103.419C133.161 105.163 132.994 107.81 131.314 109.337Z"
        fill="white"
      />
      <circle
        cx="55.7457"
        cy="333.189"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="55.7493" cy="333.189" r="22.0229" fill="#E2F7FF" />
      <g clipPath="url(#clip2_8646_203268)">
        <path
          d="M41.9131 345.123C41.7911 344.389 41.8777 343.636 42.1632 342.949C42.4519 342.232 42.9314 341.606 43.5506 341.137C44.1699 340.668 44.9059 340.374 45.6804 340.286C46.6918 340.184 47.7068 340.444 48.5413 341.019C49.3759 341.594 49.9747 342.446 50.2292 343.421C50.5514 343.3 50.8929 343.237 51.2374 343.235C51.7848 343.236 52.3194 343.399 52.7731 343.702C53.2268 344.005 53.5792 344.435 53.7854 344.937C53.8043 344.987 53.8106 345.041 53.804 345.094C53.7973 345.148 53.7778 345.198 53.7471 345.242C53.7164 345.286 53.6755 345.322 53.6277 345.347C53.58 345.372 53.5268 345.386 53.4728 345.386H42.2336C42.1577 345.386 42.0843 345.359 42.0258 345.311C41.9674 345.263 41.9276 345.197 41.9131 345.123Z"
          fill="#007E86"
        />
        <path
          d="M57.1892 327.596H53.1875V345.139H57.1892V327.596Z"
          fill="#352269"
        />
        <path
          d="M60.9455 334.858L59.5859 333.512L55.1867 337.867L56.5463 339.213L60.9455 334.858Z"
          fill="#352269"
        />
        <path
          d="M69.9139 344.999C70.078 343.954 69.951 342.883 69.5465 341.904C69.1389 340.873 68.4574 339.971 67.574 339.292C66.6907 338.614 65.6384 338.185 64.5287 338.05C63.0735 337.902 61.613 338.277 60.4126 339.104C59.2122 339.932 58.3515 341.159 57.9869 342.562C57.5427 342.393 57.0712 342.307 56.5956 342.306C55.8099 342.3 55.0405 342.529 54.388 342.962C53.7355 343.396 53.2301 344.014 52.9378 344.736C52.9102 344.808 52.9005 344.886 52.9095 344.962C52.9186 345.039 52.9461 345.112 52.9899 345.176C53.0336 345.24 53.0921 345.292 53.1606 345.329C53.2291 345.365 53.3055 345.385 53.3833 345.386H69.4449C69.5565 345.386 69.6645 345.347 69.7502 345.277C69.8358 345.206 69.8938 345.108 69.9139 344.999Z"
          fill="#00B0AC"
        />
        <path
          d="M65.5884 332.277C66.3406 332.287 67.0672 332.006 67.6122 331.492C68.1571 330.979 68.4772 330.274 68.5037 329.53C68.5037 328.358 68.0335 327.234 67.1965 326.405C66.3596 325.576 65.2245 325.111 64.0408 325.111H63.9861C63.7443 322.967 62.713 320.987 61.0894 319.549C59.4658 318.111 57.3639 317.316 55.1854 317.316C53.007 317.316 50.905 318.111 49.2814 319.549C47.6578 320.987 46.6265 322.967 46.3848 325.111H46.3301C45.1524 325.111 44.0224 325.571 43.1853 326.391C42.3482 327.211 41.8717 328.325 41.8594 329.491V329.491C41.8758 330.244 42.1925 330.959 42.7403 331.481C43.2881 332.003 44.0223 332.289 44.7825 332.277H65.5884Z"
          fill="#007E86"
        />
        <path
          d="M60.3676 335.079C60.9115 335.079 61.3524 334.642 61.3524 334.104C61.3524 333.565 60.9115 333.129 60.3676 333.129C59.8237 333.129 59.3828 333.565 59.3828 334.104C59.3828 334.642 59.8237 335.079 60.3676 335.079Z"
          fill="#8D83BE"
        />
        <path
          d="M68.501 329.53C68.501 328.359 68.0308 327.235 67.1939 326.406C66.3569 325.577 65.2218 325.112 64.0382 325.112H63.9835C63.8075 323.491 63.1801 321.95 62.1712 320.662C61.1624 319.373 59.8117 318.387 58.2701 317.814C56.7314 318.382 55.3819 319.36 54.3718 320.642C53.3617 321.924 52.7306 323.458 52.5488 325.073H52.4863C51.302 325.073 50.166 325.538 49.3278 326.367C48.4896 327.195 48.0177 328.319 48.0156 329.492C48.0441 330.237 48.3659 330.941 48.9121 331.455C49.4583 331.968 50.1857 332.25 50.9388 332.239H65.5857C66.3315 332.25 67.0524 331.973 67.5963 331.468C68.1402 330.963 68.4646 330.268 68.501 329.53Z"
          fill="#00B0AC"
        />
      </g>
      <circle cx="55.7541" cy="308.455" r="5.0822" fill="#FFC50A" />
      <path
        d="M55.1015 307.648L55.5767 306.686C55.6389 306.56 55.8184 306.56 55.8806 306.686L56.3558 307.648L57.4183 307.803C57.5572 307.823 57.6127 307.994 57.5122 308.092L56.7435 308.841L56.9249 309.9C56.9487 310.038 56.8034 310.144 56.6791 310.078L55.7287 309.578L54.7782 310.078C54.6539 310.144 54.5086 310.038 54.5324 309.9L54.7138 308.841L53.9451 308.092C53.8446 307.994 53.9001 307.823 54.0391 307.803L55.1015 307.648Z"
        fill="white"
      />
      <circle
        cx="107.246"
        cy="249.504"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="107.249" cy="249.503" r="22.0229" fill="#E2F7FF" />
      <circle cx="107.254" cy="224.769" r="5.0822" fill="#FFC50A" />
      <path
        d="M106.602 223.963L107.077 223C107.139 222.874 107.318 222.874 107.381 223L107.856 223.963L108.918 224.117C109.057 224.138 109.113 224.308 109.012 224.406L108.243 225.156L108.425 226.214C108.449 226.353 108.303 226.458 108.179 226.393L107.229 225.893L106.278 226.393C106.154 226.458 106.009 226.353 106.032 226.214L106.214 225.156L105.445 224.406C105.345 224.308 105.4 224.138 105.539 224.117L106.602 223.963Z"
        fill="white"
      />
      <g clipPath="url(#clip3_8646_203268)">
        <path
          d="M124.002 259.985C124.097 259.406 124.053 258.783 123.79 258.131C123.291 256.9 122.148 255.998 120.829 255.866C118.997 255.683 117.421 256.878 116.996 258.541C116.739 258.446 116.468 258.387 116.175 258.387C115.208 258.387 114.372 258.981 114.028 259.831C113.954 260.014 114.094 260.212 114.284 260.212H123.724C123.855 260.212 123.973 260.117 123.995 259.985H124.002Z"
          fill="#007E86"
        />
        <path
          d="M111.173 236.965H102.203V249.526H111.173V236.965Z"
          fill="#969696"
        />
        <path
          d="M111.175 260.204H93.2344V243.363L102.205 236.965L111.175 243.363V260.204Z"
          fill="#003381"
        />
        <path
          d="M120.136 260.204H102.203V243.363L111.173 236.965L120.136 243.363V260.204Z"
          fill="#0075C9"
        />
        <path
          d="M113.749 251.477H109.125V260.205H113.749V251.477Z"
          fill="#003381"
        />
        <path
          d="M114.243 243.774C113.539 243.269 112.557 243.415 111.941 244.031L111.436 244.537L110.93 244.031C110.322 243.423 109.332 243.269 108.629 243.774C107.735 244.419 107.661 245.673 108.409 246.42L109.626 247.637L111.113 249.124C111.289 249.3 111.575 249.3 111.751 249.124L113.239 247.637L114.455 246.42C115.203 245.673 115.129 244.419 114.235 243.774H114.243Z"
          fill="#FFC50A"
        />
        <path
          d="M99.6428 249.072H96.125V254.869H99.6428V249.072Z"
          fill="#0075C9"
        />
        <path
          d="M107.237 249.072H103.719V254.869H107.237V249.072Z"
          fill="#003381"
        />
        <path
          d="M119.026 249.072H115.508V254.869H119.026V249.072Z"
          fill="#003381"
        />
        <path
          d="M100.643 259.985C100.738 259.406 100.694 258.783 100.43 258.131C99.9318 256.9 98.7885 255.998 97.4693 255.866C95.6372 255.683 94.0615 256.878 93.6364 258.541C93.3799 258.446 93.1088 258.387 92.8156 258.387C91.8482 258.387 91.0128 258.981 90.6683 259.831C90.595 260.014 90.7343 260.212 90.9248 260.212H100.364C100.496 260.212 100.613 260.117 100.635 259.985H100.643Z"
          fill="#00B0AC"
        />
        <path
          d="M102.198 243.363H92.1875V244.551H102.198V243.363Z"
          fill="#0075C9"
        />
        <path
          d="M92.1875 243.365L101.224 236.967H111.169L102.198 243.365H92.1875Z"
          fill="#003381"
        />
      </g>
      <circle
        cx="107.246"
        cy="416.878"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="107.249" cy="416.877" r="22.0229" fill="#E2F7FF" />
      <circle cx="107.254" cy="392.143" r="5.0822" fill="#FFC50A" />
      <path
        d="M106.602 391.336L107.077 390.373C107.139 390.247 107.318 390.247 107.381 390.373L107.856 391.336L108.918 391.49C109.057 391.51 109.113 391.681 109.012 391.779L108.243 392.529L108.425 393.587C108.449 393.726 108.303 393.831 108.179 393.766L107.229 393.266L106.278 393.766C106.154 393.831 106.009 393.726 106.032 393.587L106.214 392.529L105.445 391.779C105.345 391.681 105.4 391.51 105.539 391.49L106.602 391.336Z"
        fill="white"
      />
      <g clipPath="url(#clip4_8646_203268)">
        <path
          d="M106.529 417.585V406.468C106.529 406.274 106.37 406.109 106.17 406.115C99.3744 406.316 94.0203 412.268 94.7931 419.277C95.3933 424.697 99.7676 429.075 105.184 429.676C112.187 430.449 118.134 425.091 118.334 418.289C118.334 418.096 118.176 417.93 117.982 417.93H106.874C106.681 417.93 106.529 417.778 106.529 417.585Z"
          fill="#352269"
        />
        <path
          d="M120.125 415.796C119.938 409.553 114.915 404.526 108.671 404.34C108.478 404.34 108.312 404.499 108.312 404.692V415.809C108.312 416.003 108.464 416.155 108.657 416.155H119.766C119.959 416.155 120.125 415.996 120.118 415.796H120.125Z"
          fill="#00B0AC"
        />
      </g>
      <circle
        cx="55.7457"
        cy="500.564"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="55.7493" cy="500.564" r="22.0229" fill="#E2F7FF" />
      <circle cx="55.7541" cy="475.828" r="5.0822" fill="#FFC50A" />
      <path
        d="M55.1015 475.022L55.5767 474.059C55.6389 473.933 55.8184 473.933 55.8806 474.059L56.3558 475.022L57.4183 475.176C57.5572 475.196 57.6127 475.367 57.5122 475.465L56.7435 476.214L56.9249 477.273C56.9487 477.411 56.8034 477.517 56.6791 477.451L55.7287 476.951L54.7782 477.451C54.6539 477.517 54.5086 477.411 54.5324 477.273L54.7138 476.214L53.9451 475.465C53.8446 475.367 53.9001 475.196 54.0391 475.176L55.1015 475.022Z"
        fill="white"
      />
      <g clipPath="url(#clip5_8646_203268)">
        <path
          d="M55.3599 514.792C62.8161 514.792 68.8605 508.748 68.8605 501.292C68.8605 493.835 62.8161 487.791 55.3599 487.791C47.9038 487.791 41.8594 493.835 41.8594 501.292C41.8594 508.748 47.9038 514.792 55.3599 514.792Z"
          fill="#352269"
        />
        <path
          d="M56.4849 513.673C63.9411 513.673 69.9855 507.629 69.9855 500.172C69.9855 492.716 63.9411 486.672 56.4849 486.672C49.0288 486.672 42.9844 492.716 42.9844 500.172C42.9844 507.629 49.0288 513.673 56.4849 513.673Z"
          fill="#0075C9"
        />
        <path
          d="M56.4854 510.555C62.2202 510.555 66.8692 505.906 66.8692 500.171C66.8692 494.436 62.2202 489.787 56.4854 489.787C50.7506 489.787 46.1016 494.436 46.1016 500.171C46.1016 505.906 50.7506 510.555 56.4854 510.555Z"
          fill="#FFC50A"
        />
        <path
          d="M60.8843 494.924L54.5325 497.914C54.3983 497.977 54.2879 498.088 54.2247 498.222L51.2343 504.574C50.9818 505.118 51.542 505.678 52.0864 505.426L58.4382 502.435C58.5724 502.372 58.6828 502.262 58.746 502.128L61.7364 495.776C61.9889 495.231 61.4287 494.671 60.8843 494.924Z"
          fill="#352269"
        />
        <path
          d="M56.4798 501.292C57.0986 501.292 57.6003 500.79 57.6003 500.171C57.6003 499.552 57.0986 499.051 56.4798 499.051C55.861 499.051 55.3594 499.552 55.3594 500.171C55.3594 500.79 55.861 501.292 56.4798 501.292Z"
          fill="#8D83BE"
        />
        <path
          d="M56.709 491.192H56.7012C56.3743 491.192 56.1094 491.457 56.1094 491.784V493.646C56.1094 493.973 56.3743 494.238 56.7012 494.238H56.709C57.0359 494.238 57.3008 493.973 57.3008 493.646V491.784C57.3008 491.457 57.0359 491.192 56.709 491.192Z"
          fill="#352269"
        />
        <path
          d="M65.4688 500.4V500.393C65.4688 500.066 65.2038 499.801 64.877 499.801H63.0148C62.688 499.801 62.423 500.066 62.423 500.393V500.4C62.423 500.727 62.688 500.992 63.0148 500.992H64.877C65.2038 500.992 65.4688 500.727 65.4688 500.4Z"
          fill="#352269"
        />
        <path
          d="M56.2519 509.15H56.2598C56.5866 509.15 56.8516 508.885 56.8516 508.559V506.696C56.8516 506.37 56.5866 506.105 56.2598 506.105H56.2519C55.9251 506.105 55.6601 506.37 55.6601 506.696V508.559C55.6601 508.885 55.9251 509.15 56.2519 509.15Z"
          fill="#352269"
        />
        <path
          d="M47.5 499.943V499.951C47.5 500.278 47.765 500.543 48.0918 500.543H49.9539C50.2808 500.543 50.5457 500.278 50.5457 499.951V499.943C50.5457 499.616 50.2808 499.352 49.9539 499.352H48.0918C47.765 499.352 47.5 499.616 47.5 499.943Z"
          fill="#352269"
        />
      </g>
      <circle
        cx="107.246"
        cy="584.252"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="107.249" cy="584.251" r="22.0229" fill="#E2F7FF" />
      <circle cx="107.254" cy="559.516" r="5.0822" fill="#FFC50A" />
      <path
        d="M106.602 558.71L107.077 557.747C107.139 557.621 107.318 557.621 107.381 557.747L107.856 558.71L108.918 558.864C109.057 558.884 109.113 559.055 109.012 559.153L108.243 559.903L108.425 560.961C108.449 561.1 108.303 561.205 108.179 561.14L107.229 560.64L106.278 561.14C106.154 561.205 106.009 561.1 106.032 560.961L106.214 559.903L105.445 559.153C105.345 559.055 105.4 558.884 105.539 558.864L106.602 558.71Z"
        fill="white"
      />
      <g clipPath="url(#clip6_8646_203268)">
        <path
          d="M93.3593 592.072H100.196V597.464H95.6663C94.3897 597.464 93.3593 596.426 93.3593 595.14V592.072Z"
          fill="#003381"
        />
        <path
          d="M100.203 577.444H93.3665V574.376C93.3665 573.09 94.3969 572.052 95.6735 572.052H97.896C99.1725 572.052 100.203 573.09 100.203 574.376V577.444Z"
          fill="#003381"
        />
        <path
          d="M115.413 572.053H95.7264C96.0956 572.084 98.0335 573.091 98.0335 574.377V595.14C98.0335 596.426 97.003 597.464 95.7264 597.464H115.413C116.69 597.464 117.72 596.426 117.72 595.14V574.377C117.72 573.091 116.69 572.053 115.413 572.053Z"
          fill="#0075C9"
        />
        <path
          d="M111.598 592.366L108.937 592.583L109.153 589.895L117.904 581.078L120.35 583.55L111.598 592.366Z"
          fill="#003381"
        />
        <path
          d="M120.887 579.157L122.252 580.532C122.551 580.833 122.551 581.327 122.252 581.628L120.344 583.551L117.891 581.08L119.8 579.157C120.099 578.856 120.588 578.856 120.887 579.157Z"
          fill="#0075C9"
        />
        <path
          d="M111.397 578.25L111.766 578.397C111.874 578.444 111.874 578.599 111.766 578.638L111.397 578.785C110.982 578.948 110.659 579.281 110.497 579.691L110.351 580.063C110.305 580.172 110.151 580.172 110.113 580.063L109.967 579.691C109.805 579.273 109.475 578.948 109.067 578.785L108.698 578.638C108.59 578.591 108.59 578.436 108.698 578.397L109.067 578.25C109.482 578.088 109.805 577.754 109.967 577.344L110.113 576.972C110.159 576.864 110.313 576.864 110.351 576.972L110.497 577.344C110.659 577.762 110.989 578.088 111.397 578.25Z"
          fill="#FFC50A"
        />
        <path
          d="M109.196 582.318L110.042 582.651C110.288 582.751 110.288 583.108 110.042 583.201L109.196 583.534C108.258 583.906 107.512 584.657 107.143 585.602L106.812 586.455C106.712 586.703 106.358 586.703 106.266 586.455L105.935 585.602C105.566 584.657 104.82 583.906 103.882 583.534L103.036 583.201C102.79 583.1 102.79 582.744 103.036 582.651L103.882 582.318C104.82 581.946 105.566 581.194 105.935 580.249L106.266 579.397C106.366 579.149 106.72 579.149 106.812 579.397L107.143 580.249C107.512 581.194 108.258 581.946 109.196 582.318Z"
          fill="#FFC50A"
        />
      </g>
      <circle
        cx="55.7457"
        cy="165.816"
        r="26.0886"
        fill="white"
        stroke="#FFC50A"
        strokeWidth="2.03288"
      />
      <circle cx="55.7493" cy="165.816" r="22.0229" fill="#E2F7FF" />
      <circle cx="55.7541" cy="141.082" r="5.0822" fill="#FFC50A" />
      <path
        d="M55.1015 140.276L55.5767 139.313C55.6389 139.187 55.8184 139.187 55.8806 139.313L56.3558 140.276L57.4183 140.43C57.5572 140.45 57.6127 140.621 57.5122 140.719L56.7435 141.468L56.9249 142.527C56.9487 142.665 56.8034 142.771 56.6791 142.705L55.7287 142.205L54.7782 142.705C54.6539 142.771 54.5086 142.665 54.5324 142.527L54.7138 141.468L53.9451 140.719C53.8446 140.621 53.9001 140.45 54.0391 140.43L55.1015 140.276Z"
        fill="white"
      />
      <path
        d="M67.0863 157.527C64.2956 155.542 60.3712 156.126 57.9381 158.524L55.9323 160.501L53.9265 158.524C51.4934 156.126 47.569 155.542 44.7784 157.527C41.2203 160.054 40.9238 164.978 43.8888 167.9L48.7289 172.67L54.6591 178.514C55.3567 179.201 56.4904 179.201 57.1968 178.514L63.127 172.67L67.9671 167.9C70.9321 164.978 70.6356 160.054 67.0775 157.527H67.0863Z"
        fill="#0075C9"
      />
      <path
        d="M71.1281 155.455L71.6396 155.651C71.7878 155.71 71.7878 155.921 71.6396 155.979L71.1281 156.176C70.5574 156.395 70.1053 156.839 69.8829 157.4L69.6828 157.903C69.6235 158.049 69.4085 158.049 69.3492 157.903L69.1491 157.4C68.9267 156.839 68.4746 156.395 67.9039 156.176L67.3924 155.979C67.2442 155.921 67.2442 155.71 67.3924 155.651L67.9039 155.455C68.4746 155.236 68.9267 154.791 69.1491 154.23L69.3492 153.727C69.4085 153.582 69.6235 153.582 69.6828 153.727L69.8829 154.23C70.1053 154.791 70.5574 155.236 71.1281 155.455Z"
        fill="#FFC50A"
      />
      <path
        d="M49.96 169.324L51.1237 169.776C51.4646 169.907 51.4646 170.388 51.1237 170.52L49.96 170.971C48.6628 171.474 47.6326 172.487 47.1211 173.763L46.6616 174.907C46.5282 175.242 46.039 175.242 45.9055 174.907L45.446 173.763C44.9346 172.487 43.9043 171.474 42.6072 170.971L41.4435 170.52C41.1025 170.388 41.1025 169.907 41.4435 169.776L42.6072 169.324C43.9043 168.822 44.9346 167.809 45.446 166.533L45.9055 165.389C46.039 165.054 46.5282 165.054 46.6616 165.389L47.1211 166.533C47.6326 167.809 48.6628 168.822 49.96 169.324Z"
        fill="#FFC50A"
      />
      <path
        d="M53.1826 192.649C51.898 218.077 105.324 200.696 106.297 220.652"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <path
        d="M53.8232 358.736C52.5386 384.164 105.964 366.783 106.938 386.739"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <path
        d="M54.1357 526.753C52.8511 552.181 106.277 534.8 107.25 554.756"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <path
        d="M109.161 443.066C110.446 468.494 57.02 451.113 56.0469 471.069"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <path
        d="M109.161 276.337C110.446 301.765 57.02 284.384 56.0469 304.34"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <path
        d="M114 117.5C114 117.5 104.501 128.199 83.0001 122.5C61.4994 116.801 54.4869 129.792 56.0439 139.003"
        stroke="#FFC50A"
        strokeWidth="1.60293"
        strokeDasharray="3.21 3.21"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_8646_203268"
            transform="matrix(0.000407656 0 0 0.000309139 0.00437649 -0.172554)"
          />
        </pattern>
        <clipPath id="clip0_8646_203268">
          <rect
            width="63.0016"
            height="215.027"
            fill="white"
            transform="translate(258.609 74.3896)"
          />
        </clipPath>
        <clipPath id="clip1_8646_203268">
          <rect
            width="76.8826"
            height="77.9655"
            fill="white"
            transform="translate(282.797)"
          />
        </clipPath>
        <clipPath id="clip2_8646_203268">
          <rect
            width="28.1215"
            height="28.1215"
            fill="white"
            transform="translate(41.8594 317.265)"
          />
        </clipPath>
        <clipPath id="clip3_8646_203268">
          <rect
            width="33.3968"
            height="23.2393"
            fill="white"
            transform="translate(90.6484 236.967)"
          />
        </clipPath>
        <clipPath id="clip4_8646_203268">
          <rect
            width="25.411"
            height="25.411"
            fill="white"
            transform="translate(94.7188 404.34)"
          />
        </clipPath>
        <clipPath id="clip5_8646_203268">
          <rect
            width="28.1215"
            height="28.1215"
            fill="white"
            transform="translate(41.8594 486.672)"
          />
        </clipPath>
        <clipPath id="clip6_8646_203268">
          <rect
            width="29.138"
            height="25.411"
            fill="white"
            transform="translate(93.3593 572.053)"
          />
        </clipPath>
        <image
          id="image0_8646_203268"
          width="2731"
          height="4096"
          xlinkHref={require('./PlannerImageBoxMobile.jpg')}
        />
      </defs>
    </svg>
  );
};

export default PlannerGraphicMobile;
