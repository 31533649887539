import React from 'react';
import { CurtainEl } from './Curtain.styled';

class Curtain extends React.Component {
   shouldComponentUpdate(nextProps) {
      if (this.props.show !== nextProps.show) {
         return true;
      }

      return false;
   }

   render() {
      return (
         <CurtainEl id="unbuilt-curtain" show={this.props.show} />
      )
   }
}

export default Curtain;
