import React from 'react';

import { MediaContainer } from './VideoControllerStatic.styled';

class VideoControllerStatic extends React.Component {
   shouldComponentUpdate(nextProps, nextState) {
      if (this.props.activeIndex !== nextProps.activeIndex) {
         return true;
      }

      return false;
   }

   componentDidUpdate() {
      // brief delay in order to mimic video playback and completion of video versions
      // -- prevents nav from progressing multiple steps in single scroll
      setTimeout(() => {
         this.props.videoEnded();
      }, 500);
   }

   render() {
      return (
         <div>
            {this.props.data.map((item, index) => {
               return (
                  <MediaContainer
                     key={index}
                     active={this.props.activeIndex === index ? true : false}
                     bgImg={require(`../images/${item.imgName}`)}
                  />
               )
            })}
         </div>
      )
   }
}

export default VideoControllerStatic;
