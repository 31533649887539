import { PencilAndPaper } from 'dx-sdk/build/icons';
import { bool, func } from 'prop-types';
import MenuBurger from 'pubweb-smokey/dist/images/svg/iconography-16x16/hamburger.svg';
import locationIcon from 'pubweb-smokey/dist/images/svg/iconography-16x16/location-line.svg';
import MenuClose from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { DXAccountContext } from '../../../contexts/DXAccountContext';
import useDetectOutsideClick from '../../../hooks/useDetectOutsideClick';
import navLinkBoxImg from '../../../images/portal/go-big-go-home-mobile-nav.jpg';
import Arrow from '../../../images/svg/accountArrow.svg';
import phoneIcon from '../../../images/svg/iconography-16x16/phone.svg';
import Loading from '../../../images/svg/icons/Loading';
import { fireSegmentEvent } from '../../../utils';
import { formatPhoneNumberFromNumber } from '../SmokeyTransitional/utils/formatters';
import { HeaderStyles } from './Header.styled';
import { shouldFeatureWelcomeHomeGallery } from '../../../utils/utils';
import HeartOutline from '../../../images/svg/icons/HeartOutline';
import Colors from '../../../colors';

if (
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  !document.getElementById('ccpa_script') &&
  window?.location?.href?.toLowerCase().indexOf('/brochure') < 0
) {
  let source = `https://privacy.claytonhomes.com/ccpa/v5/index.min.js`;
  let script = document.createElement('script');
  script.src = source;
  script.defer = true;
  script.id = 'ccpa_script';
  script.setAttribute('data-id', 'ccpa-banner');
  script.setAttribute('data-final-y-perc', '5%');
  script.setAttribute('data-wait-for-window-load-event', 'true');

  document.getElementsByTagName('head')[0].appendChild(script);
}

const Header = ({ data, query, dealer, homes }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [navAccountWidth, setNavAccountWidth] = useState(null);
  const handleLinkClick = () => {
    setNavOpen(false);
  };

  const dxAccountContext = useContext(DXAccountContext);

  const navAccount = useRef();

  useEffect(() => {
    setNavAccountWidth(navAccount?.current?.offsetWidth);
  }, [dxAccountContext?.state?.account]);

  const dropDownMenu = useRef(null);
  const [showDropdownMenu, setShowDropdownMenu] = useDetectOutsideClick(
    dropDownMenu,
    false
  );
  let history = useHistory();
  const loc = useLocation();
  const [inPortal, setInPortal] = useState(false);

  useEffect(() => {
    if (loc.pathname.startsWith('/portal')) {
      setInPortal(true);
    } else {
      setInPortal(false);
    }
  }, [loc]);

  return (
    <HeaderStyles>
      <div className="nav-container">
        <a className="sr-only" href="#maincontent">
          Skip to main content
        </a>

        <div className="nav-main">
          <div className="nav-top">
            <ul className="nav-top-ul">
              <li>
                <a
                  tabIndex="0"
                  rel="noopener"
                  target="_blank"
                  className="header-address"
                  href={
                    'http://maps.google.com/?q=' +
                    dealer?.address1 +
                    '+' +
                    dealer?.city +
                    '+' +
                    dealer?.stateProvince +
                    '+' +
                    dealer?.postalCode
                  }
                  onClick={() => {
                    fireSegmentEvent('External Link Clicked', {
                      eventContext: 'header_address',
                      type: 'button',
                      isNav: false,
                    });
                  }}
                >
                  <img src={locationIcon} alt="location icon" />
                  {dealer?.dealerName?.replace('-', ' of ')}
                </a>
              </li>

              <li>
                <NavLink
                  tabIndex="0"
                  onClick={() => setNavOpen(false)}
                  to={{ pathname: '/faq', state: { dealer, homes } }}
                >
                  FAQ
                </NavLink>
              </li>
              <li>
                <a
                  tabIndex="0"
                  className="header-phone"
                  href={'tel:' + dealer?.phoneNumber}
                  onClick={() => {
                    fireSegmentEvent('Phone Number Link Clicked', {
                      link: dealer?.phoneNumber
                        ? formatPhoneNumberFromNumber(dealer.phoneNumber)
                        : '',
                      eventContext: 'header_phone',
                    }) + setNavOpen(false);
                  }}
                >
                  <img
                    className="phone-icon"
                    src={phoneIcon}
                    alt="phone icon"
                  />
                  {formatPhoneNumberFromNumber(dealer?.phoneNumber)}
                </a>
              </li>
            </ul>
          </div>

          <div className="nav-logo-burger">
            <div className="logo" data-test-id="logo-click">
              <NavLink to={{ pathname: '/', state: { dealer, homes } }}>
                <div className="logo-mobile">
                  <img
                    src={dealer?.vectorLogoReference}
                    alt="clayton homes logo"
                  />
                </div>
                <div className="logo-desktop">
                  <img
                    src={dealer?.vectorLogoReference}
                    alt="clayton homes logo"
                  />
                </div>
              </NavLink>
            </div>

            <div className="nav-right">
              <div className="nav-location show-for-mobile">
                <a
                  tabIndex="0"
                  target="_blank"
                  rel="noopener"
                  href={
                    'http://maps.google.com/?q=' +
                    dealer?.address1 +
                    '+' +
                    dealer?.city +
                    '+' +
                    dealer?.stateProvince +
                    '+' +
                    dealer?.postalCode
                  }
                >
                  <img src={locationIcon} alt="location icon" />
                  {dealer?.city}
                </a>
              </div>
              <div className="nav-phone hide-for-mobile">
                <NavLink
                  tabIndex="0"
                  onClick={() =>
                    setNavOpen(false) +
                    fireSegmentEvent('CTA Clicked', {
                      isNav: false,
                      type: 'button',
                      text: 'Schedule a Visit',
                      eventContext: 'nav_schedule',
                    })
                  }
                  to={{
                    pathname: '/contact-us',
                    state: { dealer, homes, contactForm: 'scheduleVisit' },
                  }}
                  className="ga-main-nav-schedule-appointment"
                >
                  Schedule a Visit
                </NavLink>
              </div>

              <div
                className={
                  'hide-for-mobile nav-account ' +
                  (dxAccountContext?.state.isAuthenticated
                    ? 'logged-in'
                    : 'logged-out')
                }
                ref={navAccount}
                onClick={() =>
                  dxAccountContext?.state.isAuthenticated
                    ? setShowDropdownMenu(!showDropdownMenu)
                    : dxAccountContext.actions.showLogin('/portal') +
                      fireSegmentEvent('CTA Clicked', {
                        isNav: true,
                        type: 'text',
                        text: 'MyHome Account',
                        eventContext: 'nav_account_nav',
                      })
                }
              >
                {dxAccountContext?.state.isAuthenticated ? (
                  <span
                    className={
                      showDropdownMenu
                        ? 'menu-account-name activated'
                        : 'menu-account-name'
                    }
                  >
                    {!dxAccountContext.state.account?.userId ? (
                      <Loading width="22px" color="#0075c9" />
                    ) : dxAccountContext.state.account?.email &&
                      !dxAccountContext.state.account?.firstName &&
                      !dxAccountContext.state.account?.lastName ? (
                      <>
                        <p>{dxAccountContext.state.account?.email}</p>
                        <img src={Arrow} alt="arrow" height="6px" />
                      </>
                    ) : (
                      <>
                        <p>
                          {dxAccountContext.state.account?.firstName
                            ? dxAccountContext.state.account?.firstName + ' '
                            : null}
                          {dxAccountContext.state.account?.lastName}
                        </p>
                        <img src={Arrow} />
                      </>
                    )}
                  </span>
                ) : (
                  <span>MyHome Account</span>
                )}
              </div>

              <button
                data-test-id="menu-toggle"
                className="nav-burger"
                onClick={() => setNavOpen(!navOpen)}
              >
                <img
                  src={navOpen ? MenuClose : MenuBurger}
                  alt="navigation button"
                />
              </button>
            </div>
          </div>

          <div className={`nav-menu-items ${navOpen ? 'open' : 'closed'}`}>
            <div className="nav-menu-items-inside">
              {dxAccountContext?.state.isAuthenticated && inPortal && (
                <div className="mobile-account-menu">
                  <NavLink
                    className="mobile-menu-account-name"
                    exact
                    to={{
                      pathname: '/portal/account-details',
                      state: { dealer, homes },
                    }}
                  >
                    {dxAccountContext.state.account?.email &&
                    !dxAccountContext.state.account?.firstName &&
                    !dxAccountContext.state.account?.lastName ? (
                      <>{dxAccountContext.state.account?.email}</>
                    ) : (
                      <>
                        {dxAccountContext.state.account?.firstName
                          ? dxAccountContext.state.account?.firstName + ' '
                          : null}
                        {dxAccountContext.state.account?.lastName}
                        <PencilAndPaper />
                      </>
                    )}
                  </NavLink>
                  <div className="divider"></div>
                  <ul className="account-menu-links">
                    <li>
                      <NavLink
                        exact
                        to={{ pathname: '/portal', state: { dealer, homes } }}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to={{
                          pathname: '/portal/planner',
                          state: { dealer, homes },
                        }}
                      >
                        Your Home Planner
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink
                            exact
                            to={{
                              pathname: '/portal/home-buying-journey',
                              state: { dealer, homes },
                            }}
                          >
                            Home Buying Journey
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            to={{
                              pathname: '/portal/budget',
                              state: { dealer, homes },
                            }}
                          >
                            Budget
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            to={{
                              pathname: '/portal/land',
                              state: { dealer, homes },
                            }}
                          >
                            Land
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            to={{
                              pathname: '/portal/my-home-aspirations',
                              state: { dealer, homes },
                            }}
                          >
                            Home Aspirations
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        className="my-favorites"
                        exact
                        to={{
                          pathname: '/portal/favorites',
                          state: { dealer, homes },
                        }}
                      >
                        <HeartOutline />
                        My Favorites
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to={{
                          pathname: '/portal/homes-for-you',
                          state: { dealer, homes },
                        }}
                      >
                        Homes For You
                      </NavLink>
                    </li>
                  </ul>
                  <div className="divider"></div>
                </div>
              )}
              <ul
                className={
                  dxAccountContext?.state.isAuthenticated
                    ? 'main-menu-links logged-in'
                    : 'main-menu-links'
                }
              >
                <li>
                  {dealer?.websiteTemplateDescription === 'BuilderModel' ? (
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{ pathname: '/homes', state: { dealer, homes } }}
                    >
                      Available Properties
                    </NavLink>
                  ) : (
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{ pathname: '/homes', state: { dealer, homes } }}
                    >
                      Available Homes
                    </NavLink>
                  )}
                </li>
                {dealer?.websiteTemplateDescription !== 'NoSpecialOffers' &&
                  dealer?.websiteTemplateDescription !== 'BuilderModel' &&
                  dealer?.websiteTemplateDescription !==
                    'NoSpecialOffersNoEnergySmart' && (
                    <li>
                      <NavLink
                        tabIndex="0"
                        onClick={() => setNavOpen(false)}
                        to={{
                          pathname: '/special-offers/go-big-go-home',
                          state: { dealer, homes },
                        }}
                      >
                        Special Offers
                      </NavLink>
                    </li>
                  )}
                {dealer?.websiteTemplateDescription !== 'BuilderModel' &&
                  dealer?.websiteTemplateDescription !==
                    'NoSpecialOffersNoEnergySmart' && (
                    <li>
                      <NavLink
                        tabIndex="0"
                        onClick={() => setNavOpen(false)}
                        to={{
                          pathname: '/housesmart',
                          state: { dealer, homes },
                        }}
                      >
                        Our Construction
                      </NavLink>
                    </li>
                  )}

                {!shouldFeatureWelcomeHomeGallery(dealer) &&
                  !['NoSpecialOffersNoEnergySmart', 'BuilderModel'].includes(
                    dealer?.websiteTemplateDescription
                  ) && (
                    <li>
                      <NavLink
                        tabIndex="0"
                        onClick={() => setNavOpen(false)}
                        to={{
                          pathname: '/energysmart',
                          state: { dealer, homes },
                        }}
                      >
                        EnergySmart Zero&trade;
                      </NavLink>
                    </li>
                  )}
                {shouldFeatureWelcomeHomeGallery(dealer) && (
                  <li>
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{
                        pathname: '/our-homeowners',
                        state: { dealer, homes },
                      }}
                    >
                      Our Homeowners
                    </NavLink>
                  </li>
                )}
                {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
                  <li>
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{ pathname: '/about-us', state: { dealer, homes } }}
                    >
                      About Us
                    </NavLink>
                  </li>
                )}
                {dealer?.websiteTemplateDescription === 'BuilderModel' && (
                  <li>
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{
                        pathname: '/contact-us',
                        state: { dealer, homes, contactForm: 'requestInfo' },
                      }}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                )}
                {!inPortal && (
                  <li className="mobile-login-logout">
                    <span
                      onClick={
                        dxAccountContext?.state.isAuthenticated
                          ? () => history.push('/portal')
                          : () =>
                              setNavOpen(false) +
                              dxAccountContext?.actions.showLogin('/portal')
                      }
                    >
                      MyHome Account
                    </span>
                  </li>
                )}
                {dxAccountContext?.state.isAuthenticated && (
                  <li
                    className={
                      inPortal
                        ? 'mobile-login-logout'
                        : 'mobile-login-logout outside-portal'
                    }
                  >
                    <span
                      onClick={() => {
                        dxAccountContext?.actions.accountLogout();
                        setNavOpen(false);
                      }}
                    >
                      Log Out
                    </span>
                  </li>
                )}
                {!inPortal && (
                  <li className="mobile-login-logout my-favorites">
                    <HeartOutline />
                    <NavLink
                      tabIndex="0"
                      onClick={() => setNavOpen(false)}
                      to={{
                        pathname: dxAccountContext?.state.isAuthenticated
                          ? '/portal/favorites'
                          : '/myhome-account-login',
                        state: {
                          redirectPage: '/portal/favorites',
                        },
                      }}
                    >
                      My Favorites
                    </NavLink>
                  </li>
                )}
              </ul>
              <div className="nav-bottom-mobile show-for-mobile">
                <div className="mobile-phone-wrap">
                  <a
                    tabIndex="0"
                    onClick={() => setNavOpen(false)}
                    href={'tel:' + dealer?.phoneNumber}
                    className="mobile-phone"
                  >
                    <img
                      className="phone-icon"
                      src={phoneIcon}
                      alt="phone icon"
                    />
                    {formatPhoneNumberFromNumber(dealer?.phoneNumber)}
                  </a>
                </div>

                <NavLink
                  tabIndex="0"
                  onClick={() => {
                    setNavOpen(false) +
                      fireSegmentEvent('CTA Clicked', {
                        isNav: false,
                        type: 'text',
                        text: 'Schedule a Visit',
                        eventContext: 'nav_schedule',
                      });
                  }}
                  to={{
                    pathname: '/contact-us',
                    state: { dealer, homes, contactForm: 'scheduleVisit' },
                  }}
                  className="ga-main-nav-schedule-appointment mobile-contact-btn"
                >
                  Schedule a Visit
                </NavLink>
                <NavLink
                  className="mobile-nav-link-box"
                  style={{
                    backgroundImage: `url(${navLinkBoxImg})`,
                  }}
                  onClick={() => {
                    setNavOpen(false) +
                      fireSegmentEvent('CTA Clicked', {
                        eventContext: 'mobile_nav_image',
                        text: 'View Offer',
                        type: 'button',
                        isNav: true,
                      });
                  }}
                  to={{
                    pathname: '/special-offers/go-big-go-home',
                  }}
                >
                  <div className="container">
                    <span>View Offer</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className={
          showDropdownMenu
            ? 'dropdown-account-menu activated'
            : 'dropdown-account-menu'
        }
        style={{ width: navAccountWidth }}
        ref={dropDownMenu}
      >
        <ul>
          <li>
            <NavLink
              exact
              to={{
                pathname: '/portal',
                state: { dealer, homes },
              }}
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={{
                pathname: '/portal/favorites',
                state: { dealer, homes },
              }}
            >
              My Favorites
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={{
                pathname: '/portal/account-details',
                state: { dealer, homes },
              }}
            >
              Account Details
            </NavLink>
          </li>
          <li>
            <span
              onClick={() => {
                dxAccountContext?.actions.accountLogout();
                setShowDropdownMenu(false);
              }}
            >
              Log Out
            </span>
          </li>
        </ul>
      </nav>

      <div
        onClick={() => setNavOpen(false)}
        className={
          navOpen ? 'menu-dark-overlay activated' : 'menu-dark-overlay'
        }
      ></div>
    </HeaderStyles>
  );
};

Header.propTypes = {
  navOpen: bool,
  setNavOpen: func,
};

export default Header;
