import styled from 'styled-components';

const WrapperStyles = styled.div`
   .header, .footer {
      color: #eee;
      font-family: "acumin-pro", sans-serif;
      font-weight: 800;
      text-align: center;
      width: 100%;
   }

   .header {
      background: #fff;
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      height: 90px;
      line-height: 90px;
      left: 0;
      position: fixed;
      text-align: center;
      top: 0;
      z-index: 10;
   }

   .footer {
      background: #fafafa;
      height: 300px;
      line-height: 300px;
   }

   a {
      text-decoration: none !important;
   }

   @media screen and (max-width: 1050px) {
      .header {
         height: 76px;
         line-height: 76px;
      }

   }
`;

export default WrapperStyles;