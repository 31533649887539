import React from 'react';
import PropTypes from 'prop-types';
import { welcomeHomePageStructuredData } from '../../utils/structuredData';

const WelcomeHomeStructuredData = ({ dealer }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(welcomeHomePageStructuredData(dealer)),
      }}
    ></script>
  );
};

export default WelcomeHomeStructuredData;

WelcomeHomeStructuredData.propTypes = {
  dealer: PropTypes.shape({
    welcomeHomeImages: PropTypes.array,
    firstVanityUrl: PropTypes.string,
  }).isRequired,
};
