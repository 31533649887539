import styled from 'styled-components';
import Colors from '../../colors';

const StyledImageGallery = styled.section`
  display: flex;
  justify-content: center;
  max-width: 1290px;
  flex-wrap: wrap;
  margin: 131px auto 0;
  row-gap: 30px;
  width: 90%;

  @media screen and (min-width: 500px) {
    margin: -71px auto 0;
    column-gap: 27px;
  }

  .img-container {
    width: 280px;
    height: 280px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));
    position: relative;

    @media screen and (min-width: 500px) and (max-width: 767px) {
      width: 225px;
      height: 225px;
    }

    @media screen and (min-width: 768px) {
      width: 280px;
      height: 280px;
    }

    @media screen and (min-width: 1025px) {
      width: 289px;
      height: 289px;
    }

    @media screen and (min-width: 1350px) {
      width: 412px;
      height: 412px;
    }

    &:hover {
      .customer-name {
        display: block;
        opacity: 1;
      }
    }

    img {
      object-fit: cover;
      aspect-ratio: 1/1;
      width: 100%;
      border-radius: 4px;
    }

    .customer-name {
      position: absolute;
      bottom: 0;
      transition: 0.3s;
      color: ${Colors.primary.white.standard};
      background: rgba(0, 117, 201, 0.79);
      font-family: source-sans-pro;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: -0.3px;
      box-sizing: border-box;
      padding: 7px 20px;
      width: 100%;
      border-radius: 0 0 4px 4px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        padding: 20px 30px;
      }

      @media screen and (min-width: 1025px) {
        opacity: 0;
      }
    }
  }

  .button-container {
    margin-bottom: 109px;
    margin-top: 44px;
    flex: 0 0 100%;
  }
`;

export default StyledImageGallery;
