import React from 'react';
import { StyledHomeOptions } from './HomeOptions.style';
import HomeOption1 from '../../images/webp/home-options-1.webp';
import HomeOption2 from '../../images/webp/home-options-2.webp';
import HomeOption3 from '../../images/webp/home-options-3.webp';

const upgradeOptions = [
  {
    img: `${HomeOption1}`,
    title: 'Strength and Durability',
    text: 'Clayton Built® homes are carefully constructed in our indoor building facilities using quality materials and streamlined processes. It all comes together on a foundation of strength and durability, for a home you can enjoy for years to come.',
  },
  {
    img: `${HomeOption2}`,
    title: 'Energy Smart®',
    text: 'All of our new homes have an ecobee smart thermostat®, Low-E windows, Rheem® dual element water heater and quality insulation. These Energy Smart features help keep your home comfortable and potentially reduce your energy bills.',
  },
  {
    img: `${HomeOption3}`,
    title: 'Beautiful Styles',
    text: 'The beauty of Clayton Built® homes is in the details. From stainless steel appliances and colorful kitchen islands to open floor plans, soaker tubs and fireplaces, you’re sure to find the features and customization options you’ve been dreaming of.',
  },
];

const HomeOptions = ({ headline, disclaimer }) => {
  return (
    <StyledHomeOptions>
      <h2 className="upgrades-headline">{headline}</h2>
      <div className="upgrades-container">
        {upgradeOptions &&
          upgradeOptions.map((upgrade) => {
            return (
              <div className="upgrade">
                <img
                  src={upgrade.img}
                  width="784"
                  height="576"
                  alt="Clayton Built"
                  loading="lazy"
                />
                <div className="upgrade-content-container">
                  <h3>{upgrade.title}</h3>
                  <p>{upgrade.text}</p>
                </div>
              </div>
            );
          })}
      </div>
      <p className="trademark-disclaimer">{disclaimer}</p>
    </StyledHomeOptions>
  );
};

export default HomeOptions;
