import React from 'react';
import PropTypes from 'prop-types';

const Share = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6215 7.68701C15.0542 7.68701 16.2153 6.52594 16.2153 5.09326C16.2153 3.66158 15.0539 2.50076 13.6215 2.50076C12.1901 2.50076 11.029 3.66183 11.029 5.09326C11.029 5.33503 11.0621 5.56907 11.1239 5.79111L7.11692 8.40047C6.64156 7.80808 5.91132 7.42876 5.0925 7.42876C3.66107 7.42876 2.5 8.58983 2.5 10.0213C2.5 11.4537 3.66082 12.615 5.0925 12.615C5.91396 12.615 6.64613 12.2333 7.12134 11.6375L11.1258 14.2458C11.0627 14.4698 11.029 14.7062 11.029 14.9504C11.029 16.3818 12.1901 17.5429 13.6215 17.5429C15.0539 17.5429 16.2153 16.3821 16.2153 14.9504C16.2153 13.5177 15.0542 12.3566 13.6215 12.3566C12.8844 12.3566 12.2191 12.6645 11.747 13.1586L7.64464 10.4866C7.67197 10.3356 7.68625 10.1801 7.68625 10.0213C7.68625 9.86009 7.67153 9.70236 7.64337 9.54932L11.7423 6.88007C12.2147 7.37713 12.8819 7.68701 13.6215 7.68701ZM13.6215 3.75076C14.3637 3.75076 14.9653 4.35206 14.9653 5.09326C14.9653 5.83559 14.3638 6.43701 13.6215 6.43701C12.8803 6.43701 12.279 5.83545 12.279 5.09326C12.279 4.35219 12.8804 3.75076 13.6215 3.75076ZM5.0925 8.67876C5.83469 8.67876 6.43625 9.28006 6.43625 10.0213C6.43625 10.7636 5.83482 11.365 5.0925 11.365C4.3513 11.365 3.75 10.7635 3.75 10.0213C3.75 9.28019 4.35143 8.67876 5.0925 8.67876ZM13.6215 13.6066C14.3638 13.6066 14.9653 14.2081 14.9653 14.9504C14.9653 15.6916 14.3637 16.2929 13.6215 16.2929C12.8804 16.2929 12.279 15.6915 12.279 14.9504C12.279 14.2082 12.8803 13.6066 13.6215 13.6066Z"
      fill={color}
    />
  </svg>
);

Share.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Share.defaultProps = {
  color: '#25292D',
  width: '20',
  height: '20',
};

export default Share;
