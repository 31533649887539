import React from 'react';
import { Helmet } from 'react-helmet';
import LearnPostStyles from './LearnPost.styled';
import App from '../../App';

const LearnPost = () => {
  return (
    <App>
      <LearnPostStyles>
        <Helmet>
          <title>TODO: New title here</title>
          <meta name='description' content={`TODO: New Description here.`} />
        </Helmet>

        <h1>LearnPost</h1>
      </LearnPostStyles>
    </App>
  );
};

export default LearnPost;
