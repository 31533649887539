import React from 'react';
import styled from 'styled-components';
import colors from 'pubweb-smokey/dist/colors';

export const StyledHomeOptions = styled.div`
  padding: 104px 0 0;

  @media only screen and (min-width: 768px) {
    padding: 100px 0 0;
  }

  .upgrades-headline {
    color: ${colors.primary.black.standard};
    margin: 0 auto 45px;
    text-align: center;
    font-weight: 700;
    font-family: 'acumin-pro', sans-serif;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.35px;
    max-width: 512px;
    width: 87%;

    @media only screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 38px;
      width: 67%;
      margin-bottom: 64px;
    }

    @media only screen and (min-width: 1025px) {
      max-width: none;
      width: auto;
    }
  }

  .upgrades-container {
    width: 87%;
    max-width: 512px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      width: 67%;
    }

    @media only screen and (min-width: 1025px) {
      width: 85%;
      max-width: 1224px;
      display: flex;
      justify-content: space-between;
    }

    .upgrade {
      box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.17);
      margin-bottom: 24px;

      @media only screen and (min-width: 1025px) {
        width: 32%;
        max-width: 392px;
        margin-bottom: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      .upgrade-content-container {
        padding: 40px 25px;
        box-sizing: border-box;

        @media only screen and (min-width: 768px) {
          padding: 40px;
        }

        h3 {
          color: ${colors.primary.black.standard};
          font-weight: 700;
          font-family: 'acumin-pro', sans-serif;
          font-size: 22px;
          line-height: 26px;
          letter-spacing: -0.35px;
          margin-bottom: 16px;

          @media only screen and (min-width: 768px) {
            font-size: 25px;
            line-height: 30px;
          }
        }

        p {
          color: ${colors.accent.grey1.standard};
          font-weight: 400;
          font-family: 'source-sans-pro', sans-serif;
          font-size: 16px;
          line-height: 27px;
          letter-spacing: -0.35px;
        }
      }
    }
  }

  .trademark-disclaimer,
  .price-disclaimer {
    font: 400 14px/22px 'source-sans-pro', sans-serif;
    color: #000;
    text-align: center;
    width: 87%;
    max-width: 512px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      width: 67%;
    }

    @media only screen and (min-width: 1025px) {
      width: 85%;
      max-width: 1224px;
    }
  }

  .trademark-disclaimer {
    margin: 64px auto;
  }
`;
