import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import PortalLandingStyles from './PortalLanding.styled';
import { formatDealerName } from '../Shared/SmokeyTransitional/utils/formatters';
import { DXAccountContext } from '../../contexts/DXAccountContext';
import { fireSegmentEvent, parseInitialPropsContext } from '../../utils';
import PlannerGraphic from '../../images/svg/PlannerGraphic';
import PlannerGraphicMobile from '../../images/svg/PlannerGraphicMobile';
import useWindowSize from '../../hooks/useWindowSize';

const PortalLanding = ({ dealer }) => {
  const dxAccountContext = useContext(DXAccountContext);
  const [width, height] = useWindowSize();
  const isMobile = width <= 768;

  const [redirect, setRedirect] = useState('/portal');
  const [queuedFavorite, setQueuedFavorite] = useState(null);
  const location = useLocation();
  const isAuthenticated = dxAccountContext?.state?.isAuthenticated;

  useEffect(() => {
    if (location?.state?.redirectPage) {
      setRedirect(location.state.redirectPage);
    }
    if (location?.state?.queuedFavorite) {
      setQueuedFavorite(location.state.queuedFavorite);
    }
  }, []);

  if (isAuthenticated) {
    return <Redirect to="/portal" />;
  }

  return (
    <App dealer={dealer}>
      <PortalLandingStyles>
        <Helmet>
          <title>
            MyHome Account Login | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta name="robots" content="noindex" />
        </Helmet>

        <div className="container">
          <div className="content">
            <h1>MyHome Account</h1>
            <h2>
              Save your favorites and prepare for homeownership with your MyHome
              Account.
            </h2>
            <span className="divider" />
            <p>
              Explore the steps to homeownership with a MyHome Account. Sign up
              to save your home preferences, select your favorite floor plans
              and features, learn more about the home buying process, and start
              an online credit application.
            </p>
            <p>
              Shop a personalized list of recommended homes, save your favorites
              and track your progress. When you’re ready, connect with a home
              consultant to tour your future dream home.
            </p>
            <div className="button-wrap">
              <span
                className="cta-button"
                onClick={() => {
                  fireSegmentEvent('CTA Clicked', {
                    eventContext: 'myhome_account_login',
                    text: 'Log In',
                    type: 'button',
                    isNav: true,
                  }) + queuedFavorite
                    ? dxAccountContext?.actions?.favoriteLogin(
                        queuedFavorite,
                        redirect
                      )
                    : dxAccountContext?.actions?.showLogin(redirect);
                }}
              >
                Log In
              </span>
              <span
                className="cta-button-outline"
                onClick={() => {
                  fireSegmentEvent('CTA Clicked', {
                    eventContext: 'myhome_account_signup',
                    text: 'Sign Up',
                    type: 'button',
                    isNav: true,
                  }) + queuedFavorite
                    ? dxAccountContext?.actions?.favoriteSignup(
                        queuedFavorite,
                        redirect
                      )
                    : dxAccountContext?.actions?.showSignup(redirect);
                }}
              >
                Sign Up
              </span>
            </div>
          </div>
          <div className="graphic">{!isMobile && <PlannerGraphic />}</div>
        </div>
        <div className="mobile-container">
          <div className="mobile-top">
            <h1>MyHome Account</h1>
            <h2>
              Save your favorites and prepare for homeownership with your MyHome
              Account.
            </h2>
            <div className="button-wrap">
              <span
                className="cta-button"
                onClick={() => {
                  queuedFavorite
                    ? dxAccountContext?.actions?.favoriteLogin(
                        queuedFavorite,
                        redirect
                      )
                    : dxAccountContext?.actions?.showLogin(redirect);
                }}
              >
                Log In
              </span>
              <span
                className="cta-button-outline"
                onClick={() => {
                  queuedFavorite
                    ? dxAccountContext?.actions?.favoriteSignup(
                        queuedFavorite,
                        redirect
                      )
                    : dxAccountContext?.actions?.showSignup(redirect);
                }}
              >
                Sign Up
              </span>
            </div>
          </div>
          <div className="mobile-bottom">
            <div className="mobile-graphic">
              <div className="mobile-graphic-container">
                {isMobile && <PlannerGraphicMobile />}
              </div>
              <span className="bluebar" />
            </div>
            <div className="mobile-content">
              <p>
                Explore the steps to homeownership with a MyHome Account. Sign
                up to save your home preferences, select favorite floor plans
                and features, and learn more about the home buying process.
              </p>
              <p>
                Shop a personalized list of recommended homes, save your
                favorites and track your progress. When you’re ready, connect
                with a home consultant to tour your future dream home.
              </p>
            </div>
          </div>
        </div>
      </PortalLandingStyles>
    </App>
  );
};

PortalLanding.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });
};

export default PortalLanding;
