import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { contactFormSubmit } from '../../../Api';
import Colors from '../../../colors';
import { getUTMParametersFromSessionCache } from '../../../components/Shared/SmokeyTransitional/utils/utils';
import Loader from '../../../images/svg/loader';
import { formatPhoneNumber } from '../../../utils';
import FormStyles from './Form.styled';
import { scrollIntoViewHelper } from '../../../utils/utils';

const Form = ({ firstInputRef, dealer, home, homes }) => {
  let location = useLocation();
  const [firstName, setFirstName] = useState({ value: '', error: null });
  const [lastName, setLastName] = useState({ value: '', error: null });
  const [phone, setPhone] = useState({ value: '', error: null });
  const [email, setEmail] = useState({ value: '', error: null });
  const [canEmail, setCanEmail] = useState({ value: false, error: null });
  const [doNotCall, setDoNotCall] = useState(false);
  const [dealerNumber, setDealerNumber] = useState('');
  const [modelNumber, setModelNumber] = useState('');
  const [lotNumber, setLotNumber] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originWebURL, setOriginWebURL] = useState(
    `https://www.${dealer.firstVanityUrl}${location.pathname}${location.search}`
  );
  const [originWebApp, setOriginWebApp] = useState('clayton-retail-website');
  const [originWebPageType, setOriginWebPageType] = useState(
    'primary-retail-landing-page'
  );
  const [originWebFormType, setOriginWebFormType] = useState('lead-form');
  const [originCampaign, setOriginCampaign] = useState('foundational');
  const [originWebFormCategory, setOriginWebFormCategory] =
    useState('variation-a');
  const [dataExtension, setDataExtension] = useState(
    'FormActivityRetailFoundational'
  );

  let utmParams;
  try {
    utmParams = getUTMParametersFromSessionCache();
  } catch (error) {
    utmParams = {};
  }

  useEffect(() => {
    if (dealer) {
      setDealerNumber(dealer.dealerNumber);
    }

    if (dealer) {
      setLotNumber(dealer.lotNumber);
    }

    if (home) {
      setModelNumber(home.modelNumber);
    }
  });

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const firstNameIsValid = () => {
    let isValid = true;

    if (!firstName.value || firstName.value.length < 2) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else if (
      !firstName.value.match(/^[a-zA-Z ,.'-]+[^\s]*[a-zA-Z ,.'-]+$/i)
    ) {
      setFirstName({ ...firstName, error: '*Please enter your first name.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: null });
    }

    return isValid;
  };

  const lastNameIsValid = () => {
    let isValid = true;

    if (!lastName.value || lastName.value.length < 2) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else if (!lastName.value.match(/^[a-zA-Z ,.'-]+[^\s]*[a-zA-Z ,.'-]+$/i)) {
      setLastName({ ...lastName, error: '*Please enter your last name.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: null });
    }

    return isValid;
  };

  const emailIsValid = () => {
    let isValid = true;
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!email.value) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter your email.' });
    } else if (!pattern.test(email.value)) {
      isValid = false;
      setEmail({ ...email, error: '*Please enter a valid email.' });
    } else {
      setEmail({ ...email, error: '' });
    }

    return isValid;
  };

  const phoneIsValid = () => {
    let isValid = true;

    if (!phone.value) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter your phone number.' });
    } else if (
      !phone.value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i)
    ) {
      isValid = false;
      setPhone({ ...phone, error: '*Please enter a valid phone number.' });
    } else {
      setPhone({ ...phone, error: '' });
    }

    return isValid;
  };

  const canEmailIsValid = () => {
    let isValid = true;

    if (!canEmail.value) {
      isValid = false;
      setCanEmail({
        ...canEmail,
        error: '*You must agree to the terms to receive information.',
      });
    } else {
      setCanEmail({ ...canEmail, error: '' });
    }

    return isValid;
  };

  const validateForm = () => {
    let formIsValid = true;
    let errorFields = {};

    //all must be true to submit, so bitwise 'and' it is
    formIsValid &= firstNameIsValid() ? true : !(errorFields.firstName = true);

    formIsValid &= lastNameIsValid() ? true : !(errorFields.lastName = true);

    formIsValid &= emailIsValid() ? true : !(errorFields.email = true);

    formIsValid &= phoneIsValid() ? true : !(errorFields.phone = true);

    formIsValid &= canEmailIsValid() ? true : !(errorFields.canEmail = true);

    return [formIsValid, errorFields];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [formIsValid, errorFields] = validateForm();

    if (!formIsValid) {
      scrollIntoViewHelper(errorFields);
      return;
    }

    let formData = {
      firstname: firstName.value,
      lastname: lastName.value,
      emailAddress: email.value,
      phoneNumber: phone.value,
      dealerNumber: dealerNumber,
      modelNumber: modelNumber,
      lotNumber: lotNumber,
      utmSource: utmParams.utm_source || '',
      utmCampaign: utmParams.utm_campaign || '',
      utmTerm: utmParams.utm_term || '',
      utmContent: utmParams.utm_content || '',
      utmMedium: utmParams.utm_medium || '',
      doNotCall: doNotCall,
      canEmail: canEmail.value,
      originWebURL: originWebURL,
      originWebApp: originWebApp,
      originWebPageType: originWebPageType,
      originWebFormType: originWebFormType,
      originWebFormCategory: originWebFormCategory,
      originCampaign: originCampaign,
      dataExtension: dataExtension,
      googleClickId: utmParams.gclid || '',
      bingClickId: utmParams.msclkid || '',
      facebookClickId: utmParams.fbclid || '',
    };

    setIsSubmitting(true);

    const formSubmitted = await contactFormSubmit(formData);

    if (formSubmitted.status === 200) {
      setShowThankYou(true);
      setIsSubmitting(false);
    }
  };

  return (
    <FormStyles>
      <div id="heroContent">
        <h1 className="form-headline">
          Affordable, Durable &amp; Beautiful Manufactured Homes
        </h1>
        <p className="form-text">
          Want to view new {isClayton() ? 'Clayton' : ''} manufactured or
          modular homes in your area? Provide your information below for a
          personal tour of our home models.
        </p>
        {showThankYou && (
          <div className="thank-you">
            <h4 className="thank-you-text">
              Thanks! Click below to browse our homes
            </h4>
            <Link
              className="button-link"
              to={{ pathname: '/homes', state: { dealer, homes } }}
            >
              Shop Our Homes
            </Link>
          </div>
        )}
        {!showThankYou && (
          <div id="formWrapper">
            <form onSubmit={handleSubmit} method="post" noValidate>
              <div className="form-group">
                <div className="half-width-input input-container">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    tabIndex="0"
                    name="firstName"
                    id="firstName"
                    value={firstName.value}
                    className={firstName.error && 'has-error'}
                    onChange={(event) => {
                      setFirstName({
                        value: event.target.value.trim(),
                        error: null,
                      });
                    }}
                    onBlur={firstNameIsValid}
                    required={true}
                    maxLength="40"
                    ref={firstInputRef}
                  />
                  {firstName.error ? (
                    <p className="error-message">{firstName.error}</p>
                  ) : null}
                </div>
                <div className="half-width-input input-container">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    tabIndex="0"
                    name="lastName"
                    id="lastName"
                    value={lastName.value}
                    className={lastName.error && 'has-error'}
                    onChange={(event) => {
                      setLastName({
                        value: event.target.value.trim(),
                        error: null,
                      });
                    }}
                    onBlur={lastNameIsValid}
                    required={true}
                    maxLength="40"
                  />
                  {lastName.error ? (
                    <p className="error-message">{lastName.error}</p>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <div className="full-width-input input-container">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="text"
                    tabIndex="0"
                    name="email"
                    id="email"
                    value={email.value}
                    className={email.error && 'has-error'}
                    onChange={(event) => {
                      setEmail({
                        value: event.target.value.trim(),
                        error: null,
                      });
                    }}
                    onBlur={emailIsValid}
                    required={true}
                    maxLength="80"
                  />
                  {email.error ? (
                    <p className="error-message">{email.error}</p>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <div className="full-width-input input-container">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    tabIndex="0"
                    name="phone"
                    id="phone"
                    value={formatPhoneNumber(phone.value)}
                    className={phone.error && 'has-error'}
                    onChange={(event) => {
                      setPhone({
                        value: event.target.value.trim(),
                        error: null,
                      });
                    }}
                    onBlur={phoneIsValid}
                    required={true}
                    maxLength="10"
                    minLength="10"
                  />
                  {phone.error ? (
                    <p className="error-message">{phone.error}</p>
                  ) : null}
                </div>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  tabIndex="0"
                  className="form-check-input"
                  name="canEmail"
                  id="canEmail"
                  onChange={(event) => {
                    setCanEmail({
                      value: event.target.checked,
                      error: null,
                    });
                  }}
                  onBlur={canEmailIsValid}
                  required
                />
                <label htmlFor="canEmail" className="form-check-label terms">
                  By submitting this form, I agree that Clayton Homes may
                  contact me by telephone, provide me with marketing
                  communications about Clayton Homes products and services, and
                  share my contact information as described in our website
                  terms.
                </label>
                {canEmail.error ? (
                  <p className="error-message">{canEmail.error}</p>
                ) : null}
              </div>
              <Button
                text={isSubmitting ? <Loader /> : 'Browse Homes & Floor Plans'}
                onClick={isSubmitting ? () => {} : handleSubmit}
                disabled={isSubmitting ? isSubmitting : ''}
                id="submitBtn"
                tabIndex="0"
                primaryColor={Colors.accent.cheesePuff.standard}
                automationId="formBtn"
              />
            </form>
          </div>
        )}
      </div>
    </FormStyles>
  );
};

export default Form;
