import React from 'react';
import { Helmet } from 'react-helmet';
import ReviewStyles from './Review.styled';
import App from '../../App';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import reviewImage from '../../images/reviewImage.jpg';
import TextWithImage from '../Shared/TextWithImage/TextWithImage';

const Review = ({ dealer, homes, googleReviewUrl }) => {
  return (
    <App dealer={dealer} homes={homes}>
      <ReviewStyles>
        <Helmet>
          <title>Review</title>
        </Helmet>
        <TextWithImage
          smallHeadline={'How Did We Do?'}
          largeHeadline={'Leave a Review and Share Your Experience.'}
          image={{ source: reviewImage, alt: 'review image' }}
          buttonURL={googleReviewUrl}
          buttonText={'Leave a Review'}
          imageOnTop={true}
          aTagLink={true}
        />
      </ReviewStyles>
    </App>
  );
};

Review.getInitialProps = async (ctx) => {
  let googleReviewUrl;

  const { dealer, homes } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  googleReviewUrl = dealer?.uris?.find((x) => x.urlTypeId === 5)?.urlValue;

  if (!googleReviewUrl) {
    return { redirectTo: '/404' };
  }

  return { dealer, homes, googleReviewUrl };
};

export default Review;
