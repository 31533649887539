import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../../App';
import LegalPrivacyStyles from './LegalPrivacy.styled';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import LegalHeaderImage from '../../images/legal-header-image.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LegalPrivacy = ({ dealer, homes, isLoading }) => {
  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <LegalPrivacyStyles>
        <Helmet>
          <title>Legal | Clayton Homes</title>
          <meta name="description" content={`Legal`} />
          <meta name="robots" content="noindex" />
        </Helmet>

        <div className="image-box">
          <LazyLoadImage
            alt="legal header image"
            effect="blur"
            src={LegalHeaderImage}
          />
        </div>

        <div className="container">
          <h1>Legal</h1>
          <h3>
            Web Site Information Collection and Use Practices and Web Site Use
            Terms and Conditions
          </h3>
          <p>(effective January 2021)</p>
          <p>
            We have prepared this policy to help you understand more about how
            we collect, use, and disclose information from web site visitors.
            References to "web sites" includes mobile applications that we may
            publish. This policy does not cover information that we collect
            about you outside of our normal web site services. This policy also
            does not cover information that you voluntarily make public.
          </p>
          <h3>What information do you collect about me?</h3>
          <p>
            The information that we collect about you generally falls into two
            categories, information that you provide and information that is
            automatically collected.
          </p>
          <h4>Information that you provide.</h4>
          <p>
            You may provide information to us through our web sites that you
            visit. This information may consist of, but is not limited to,
            personal contact information (name, address, phone number, email
            address, user ID and password), requests for services, banking and
            financial information, information submitted with a credit
            application, bid information, quote requests and claim submission.
            We do not collect personally identifiable information unless you
            choose to provide such information voluntarily.
          </p>
          <h4>Social media information</h4>
          <p>
            Our site may provide you the convenience of creating an account
            through a social media service. If you create an account, access or
            log-in to our site through a social media service, the information
            we collect about you may include name, email, phone, profile
            picture, social media service, zip code, and any other information
            you have given us or permitted the social media service to share
            with us. By creating an account, accessing or logging into our site
            via a social media service you are authorizing us to collect, store
            and use such gathered information and content in accordance with our
            web site information collection and use practices.
          </p>
          <h4>Automatically collected information</h4>
          <p>
            When you visit our web sites we, and third party vendors that we may
            use, may automatically collect information from you that consists
            of, but is not limited to, IP address, geolocation, device type,
            connection information such as browser type and version, plug-in
            types and versions, operating system, demographic information, which
            of our web sites you visit and which web sites not affiliated with
            us that you visit in addition to other analytic tracking
            information. We may also automatically collect information about
            your visits to and use of our web sites regarding length, timing,
            frequency, products viewed or pages and screens visited.
          </p>
          <p>
            Our web sites are not designed for use by children under the age of
            13. Therefore, we do not knowingly attempt to solicit or receive any
            information from children.
          </p>
          <h3>How do you use this information?</h3>
          <p>
            Information that you provide is used for a variety of purposes
            including but not limited to contacting you, either by us or by an
            affiliated or nonaffiliated third party, regarding services or
            information you have requested or to process payments or claims you
            have submitted. We may also use or share your contact information,
            which information may be enhanced or merged with data collected from
            other sources to the extent permitted by law, to tell you about
            information you might find useful or interesting regarding our
            products and services as well as the products and services of our
            affiliates or nonaffiliated third parties.
          </p>
          <p>
            Information that is automatically collected is used to optimize web
            site performance, retrieve user preferences, and ensure data
            integrity as well as to provide third parties with usage statistics
            in an aggregated form not identifying individual users.
            Automatically collected information is also used by third party
            vendors to help us with online tracking and to provide you with
            targeted display advertisements, also known as behavioral
            advertising, on other web sites that you visit. We and third party
            vendors, including Google, use cookies to provide you with these
            advertisements. The cookies that we and third party vendors such as
            Google use to provide you with ads based on your past visits to our
            web sites include both first party cookies (such as the Google
            Analytics cookie) and third party cookies (such as the DoubleClick
            cookie) used together to report how the ad impressions and
            advertisements that you are provided and interact with are related
            to visits to our web sites. We do not track URLs that you type into
            your computer browser, but we do use cookies to track which web
            sites you visit on the Internet after you leave our web site.
          </p>
          <h3>With whom do you share this information?</h3>
          <p>
            Information that you provide and that is automatically collected is
            shared with our affiliates and partners, and also non-affiliated
            third parties, for the purposes described in this policy.
          </p>
          <p>
            We may also share information with law enforcement agencies,
            governmental officials or other parties as required by law and when
            we believe that disclosure is necessary to protect our rights or
            property and/or comply with a judicial proceeding, court order, or
            legal process served.
          </p>
          <p>
            We give you the opportunity to control the use of your personally
            identifiable information for purposes other than to fulfill services
            you have requested and process payments or claims you have
            submitted. For information about those choices, please{' '}
            <a href="https://www.claytonhomes.com/privacy" target="_blank">
              click here
            </a>{' '}
            for our privacy policy.
          </p>
          <h3>How do you protect the security of the information you store?</h3>
          <p>
            We store information on our servers to carry out the purposes
            described here. While we use reasonable security measures, we cannot
            guarantee that the Internet or your mobile device will be completely
            secure. Our intent is to maintain any data provided as confidential,
            however, information transmitted over the Internet cannot be
            guaranteed to remain confidential and we assume no responsibility
            for maintaining such information as confidential. We encourage you
            to take steps to protect yourself online, including ensuring that
            any online account information you have remains secure. For example,
            do not share your passwords with other people.
          </p>
          <p>See the limitation of Liability and Disclaimer below.</p>
          <h3>What are cookies?</h3>
          <p>
            When you visit our web sites, we and third party vendors that we may
            use may send one or more cookies or online tracking mechanisms to
            your computer or other device that uniquely identifies your browser.
            A cookie is a small file containing a string of characters that are
            stored as text files by your browser on your computer's hard drive.
            We use cookies to track web analytics information and session
            information including user trends and search preferences. The
            purposes for tracking include but are not limited to improving the
            quality of our services, storing and retrieving user preferences and
            providing you with advertisements that may be of interest to you as
            described above in Automatically collected information. Cookies
            collect anonymous information, both individually and in the
            aggregate, but do not collect personally identifiable information.
          </p>
          <h3>
            What choices do I have regarding your use of information about me?
          </h3>
          <p>
            You can set your browser to notify you before you receive cookies so
            you have a chance to accept the cookies, and you can set your
            browser to turn off cookies by updating your privacy settings in
            your browser. Please note that if you choose to reject or disable
            cookies, your access to certain areas of our web sites may be
            limited. You may opt out of the use of cookies used for remarketing
            purposes and behavioral advertising by visiting the Network
            Advertising Initiative opt out page at{' '}
            <a href="https://optout.networkadvertising.org" target="_blank">
              https://optout.networkadvertising.org
            </a>
            . If you prefer not to have your IP address and corresponding
            geographic information collected for web site analytic purposes, you
            may download and install the Google Analytic Opt-out Browser Add-on
            which is available at{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
              https://tools.google.com/dlpage/gaoptout/
            </a>
            . You may also change your ad settings at{' '}
            <a href="http://www.google.com/settings/ads" target="_blank"></a> to
            control certain interest based ads.
          </p>
          <p>
            If you provide your email address to opt in to receiving future
            commercial marketing emails from us and our affiliates, then we may
            send information to you about our products or services and the
            products and services of our affiliates. To opt out from receiving
            commercial marketing email communications from us, please click on
            the opt-out (unsubscribe) link in the message you receive from us.
          </p>
          <h3>Web Browser "Do Not Track" Signals</h3>
          <p>
            Certain web browsers may provide an option by which you may have the
            browser inform web sites or internet services you visit that you do
            not wish to have your personally identifiable information about your
            activities or your usage and browsing history tracked by cookies or
            other persistent identifiers across time and across third-party
            internet web sites. These are called "do not track" signals. Our web
            sites currently do not respond to such signals. If you would like to
            opt out of having your activities tracked by cookies, you will need
            to opt out by installing the add-on at this link:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">
              https://tools.google.com/dlpage/gaoptout/
            </a>
            .
          </p>
          <h3>Third party web site links</h3>
          <p>
            As a convenience to you, this web site may provide links to third
            party web sites on the Internet which may be maintained by
            unaffiliated third parties. Unless we expressly state otherwise, we
            do not control, sponsor, endorse or approve any content,
            information, services or products that may be available on the web
            site links that we provide. We are not responsible for the accuracy
            of any information received through a linked third party web site,
            any services or products that may be obtained through third party
            web site links or viruses that may reside on linked third party web
            sites.
          </p>
          <p>
            Your use of this web site is your acknowledgement and agreement that
            we are not responsible or liable to you or any other person for any
            claim which may result from your use of any third party web site
            content, services or products.
          </p>
          <p>
            Unaffiliated third party web sites are not within our control. When
            you link to those web sites you leave our web site and your
            communications are no longer subject to our web site data collection
            policies. Your interaction and browsing on any other web site that
            we may link to is subject to that web site's own privacy security
            and policies. We recommend you review those rules and policies
            before proceeding on any third party web sites.
          </p>
          <h3>Your Nevada Privacy Rights</h3>
          <p>
            If you are a Nevada resident, under Nevada Revised Statutes §§
            603A.300 et seq., you have the right to submit a Verified Request
            directing us not to sell certain information we may collect from you
            and that you provide through our websites. &nbsp;It is currently our
            practice not to sell such information, but if you would like to
            submit a Verified Request, please write us at: &nbsp;Online Privacy
            Department, 5000 Clayton Rd., Maryville, TN 37804.
          </p>
          <p>
            For all requests, please include your name, street address, city,
            state and zip code. We will not accept requests via the telephone,
            email or by facsimile. &nbsp;We are not responsible for notices that
            are not labeled properly, or do not have complete information.
            &nbsp;
          </p>
          <h3>Copyright Infringement</h3>
          <p>
            We respect and are committed to protecting copyright and
            intellectual property rights of others and we expect users of our
            web sites to do the same. The Digital Millennium Copyright Act of
            1998 ("DMCA"), Title 178 U.S.C.§512, provides recourse for copyright
            owners who believe that material displayed or used on our web sites
            infringes rights under copyright law. If you believe that any
            materials used or displayed on our web sites infringes your
            copyright, you may send us a notice requesting that the material be
            removed. To file a copyright infringement notice with us, you will
            need to send a written communication that includes substantially the
            following information:
          </p>
          <ul>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site. Please describe the work and where
              possible include a copy of the URL location of an authorized
              version of the work.
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material on our web sites
              (providing URLs in your notice where the content is located will
              help us locate the content).
            </li>
            <li>
              Your contact information, name, address, telephone number, or
              electronic mail address at which you can be contacted.
            </li>
            <li>
              A statement that you, as the complaining party, have a good faith
              belief that use of the material in the manner complained of is not
              authorized by you, the copyright owner, your agent or the law.
            </li>
            <li>
              A statement that the information in the notice is accurate, and
              under penalty of perjury, that you are authorized to act on behalf
              of the owner of an exclusive right that is allegedly infringed.
            </li>
          </ul>
          <p>Provide this information to our designated DMCA Agent at:</p>
          <address>
            Online Privacy Department <br />
            5000 Clayton Rd. <br />
            Maryville, TN 37804
          </address>
          <h3>Ownership of Web Site Contents</h3>
          <p>
            Unless otherwise stated herein, we and our licensors are the sole
            owners of this web site and all of its contents, including without
            limitation, the images and all information, services, features,
            functions, copyrights, trademarks, service marks, and other
            intellectual property rights contained within this web site. You
            agree that all right, title and interest in this web site will
            remain ours or our licensors' exclusive property. Nothing in these
            Terms and Conditions for Web Site Use gives you a right to
            reproduce, copy, distribute, sell, broadcast, license, or otherwise
            use the images on the web site, our name or any of our trademarks,
            logos, domain names, or other distinctive brand features. You may
            not modify, rent, lease, sell, distribute, or create derivative
            works based on the web site unless we have given you prior written
            consent to do so. You may only use the web site for personal,
            non-commercial purposes.
          </p>
          <h3>Use of Our Web Site Content</h3>
          <p>
            You may link to our content but only for your personal use and
            provided the link or post does not imply any sponsorship or
            endorsement by us or make use of a logo or trademark without written
            consent of the logo or trademark owner. You may not frame, or
            in-line link to any of the content on our sites or incorporate into
            any other web site any content from our web sites.
          </p>
          <h3>Limitation of Liability and Disclaimer</h3>
          <p>
            THE CONTENT ON OR AVAILABLE THROUGH THIS WEB SITE IS PROVIDED "AS
            IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            AS PERMISSIBLE UNDER APPLICABLE LAW. WE DISCLAIM ALL EXPRESS OR
            IMPLIED WARRANTIES, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO REPRESENTATION OR WARRANTY THAT THE
            OPERATION OF THE WEB SITE WILL MEET YOUR OR ANY OTHER USER'S
            REQUIREMENTS, THAT ACCESS WILL BE UNINTERRUPTED, SECURE OR FREE OF
            ERRORS, VIRUSES OR OTHER HARMFUL ELEMENTS, THAT THE WEB SITE IS FREE
            FROM DEFECTS OR THAT THE WEB SITE MATERIALS ARE CORRECT, ACCURATE OR
            RELIABLE. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
            CONNECTION WITH THIS WEBSITE AND YOUR USE OF THIS WEBSITE AND ASSUME
            NO LIABILITY OR RESPONSIBILITY FOR UNAUTHORIZED ACCESS TO OR USE OF
            OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
            FINANCIAL INFORMATION STORED ON THOSE SERVERS.
          </p>
          <p>
            YOU AGREE THAT YOU WILL BE RESPONSIBLE FOR THE ENTIRE COST OF REPAIR
            OR SERVICE THAT MAY BE NECESSARY FOR YOUR DATA, SOFTWARE PROGRAMS OR
            COMPUTER EQUIPMENT CAUSE BY ANY VIRUSES, MALWARE, ERRORS OR OTHER
            PROBLEMS FROM YOUR USE OR VISITING THIS WEB SITE.
          </p>
          <p>
            WE ARE NOT LIABLE FOR ANY DAMAGES OR INJURY THAT RESULT FROM YOUR
            USE OF OUR WEB SITES. THIS INCLUDES, BUT IS NOT LIMITED TO, DAMAGES
            OR INJURY CAUSED BY: USE OF OR INABILITY TO USE OUR WEB SITES OR ANY
            SITE TO WHICH YOU LINK FROM OUR SITES; ERRORS OR OMISSIONS ON OUR
            SITES; INTERRUPTIONS OF OR DEFECTS IN OUR SITES; OR, ANY COMPUTER
            VIRUS, MALWARE OR HARMFUL COMPONENTS IN OUR SITES.
          </p>
          <p>
            YOU AGREE THAT WE ARE NOT LIABLE TO YOU UNDER ANY CAUSE OF ACTION
            FOR ANY DAMAGES OR INJURY CAUSED BY OR RESULTING FROM THE USE OF, OR
            INABILITY TO USE, THIS WEB SITE OR THE WEB PAGE CONTENT ON OR
            AVAILABLE THROUGH THIS WEB SITE. YOU AGREE THAT WE ARE NOT LIABLE
            EVEN IF WE HAVE BEEN NEGLIGENT OR IF WE OR OUR AUTHORIZED
            REPRESENTATIVE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            IN CERTAIN STATES THE LAW MAY NOT ALLOW US TO LIMIT OR EXCLUDE
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR DISCLAIM
            WARRANTIES, THEREFORE, THE ABOVE LIMITATIONS OF LIABILITY MAY NOT
            APPLY TO YOU AND YOU MAY HAVE THE RIGHT TO RECOVER INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. OUR LIABILITY TO YOU FOR ALL LOSSES, DAMAGES,
            INJURIES, AND CLAIMS (WHETHER ALLEGED UNDER THE TERMS OF A CONTRACT,
            ALLEGEDLY CAUSED BY NEGLIGENCE OR OTHER WRONGFUL CONDUCT, OR ALLEGED
            UNDER ANY OTHER LEGAL THEORY) WILL BE LIMITED TO THE AMOUNT YOU PAID
            TO ACCESS OUR WEB SITES.
          </p>
          <h3>
            What happens when this information collection and use policy
            changes?
          </h3>
          <p>
            From time to time we may change our information collection and use
            policy. The latest version of this policy will be posted here. You
            should periodically check here for the most current version of this
            policy. Any changes to this policy will not be retroactively
            applied. You understand and agree that your website use and access
            is governed by the policy that is effective at the time of your
            access or use of the website.
          </p>
          <h3>
            Who can I contact if I have questions regarding this web site use
            policy?
          </h3>
          <p>
            Questions regarding this web site information collection and use
            policy should be directed to us by email to
            OnlinePrivacy@ClaytonHomes.com or by writing to us at Online Privacy
            Department, 5000 Clayton Rd., Maryville, TN 37804.
          </p>
        </div>
      </LegalPrivacyStyles>
    </App>
  );
};

LegalPrivacy.getInitialProps = async (ctx) => {
  return await parseInitialPropsContext(ctx, dealerService, homesService);
};

export default LegalPrivacy;
