import styled from 'styled-components';

export const List = styled.ul`
   font-family: "acumin-pro", sans-serif;
   margin: 0;
   opacity: ${props => props.visible ? '1' : '0'};
   padding: 0;
   position: absolute;
   right: 30px;
   transition: ${props => props.pristine ? 'all 500ms ease-in-out 3390ms' : 'all 500ms ease-in-out'};
   top: 50%;
   transform: translateY(-50%);
   z-index: 3;

   @media (max-width: 760px) {
      display: none;
   }
`;

export const Dot = styled.span`
   background: ${props => props.active ? '#1277C6' : '#fff'};
   border: ${props => props.active ? '1px solid #1277C6' : '1px solid #B9C1CC'};
   border-radius: 50%;
   cursor: pointer;
   display: block;
   height: 7px;
   margin-top: -3.5px;
   position: absolute;
   right: 0;
   top: 50%;
   transition: all 500ms ease-in-out;
   width: 7px;
`;

export const Label = styled.span`
   color: #fff;
   opacity: 0;
   transition: all 300ms ease-in-out;
`;

export const ListItem = styled.li`
   cursor: pointer;
   font-size: 12px;
   list-style: none;
   margin: 9px 0;
   padding-right: 18px;
   position: relative;
   text-align: right;

   &:hover ${Label} {
      opacity: 1;
   }
`;